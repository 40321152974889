@import './modules/_variables'

.clm-footer
	
	&, p
		font-family: $font-rubik
		font-size: 14px
		line-height: 120%
		color: $color-less-light-gray
	*
		box-sizing: border-box
	img
		vertical-align: top
		max-width: 100%
		max-height: 100%
		object-fit: contain
	// .clm-footer__wrapper
	&__wrapper
		// * после внедрения шаблона с новым дизайном убрать верхний отступ (отступы должны быть у секций)
		border-top: 2px solid $color-gray-bg
		margin-top: 50px

	// .clm-footer__top
	&__top
		padding-top: 20px
		padding-bottom: 20px

	// .clm-footer__top-line
	&__top-line
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: space-between
		@media (max-width: $media-mobile-max)
			margin-bottom: 20px
			

	// .clm-footer__logo
	&__logo
		&:not(:last-child) 
			margin-right: 20px

		.logo
			display: block
			width: 200px
			height: 55px
			@media (max-width: $media-mobile-max)
				width: 150px
				height: 40px
				
			img
				width: 100%
				height: 100%
				object-fit: contain

	// .clm-footer__social
	&__social
		display: flex
		.socil-links__item
			position: relative
			display: flex
			align-items: center
			justify-content: center
			width: 40px
			height: 40px
			// border: 1px solid $color-gray-blue
			border-radius: 50%
			// background-color: $color-gray-bg
			&:not(:last-child) 
				margin-right: 15px
			
			.socil-links__icon
				transition: opacity 0.2s ease 0s
				&.hover
					position: absolute
					top: 0
					left: 0
					opacity: 0
			
			svg
				max-width: 100%
				max-height: 100%
				object-fit: contain
				vertical-align: top

			@media (any-hover: hover)
				&:hover
					.socil-links__icon
						opacity: 0
						&.hover
							opacity: 1
			

	// .clm-footer__btn
	&__btn

	// .clm-footer__bottom
	&__bottom
		padding: 40px 0
		background-color: $color-gray-bg
		@media (max-width: $media-mobile-max)
			padding: 0

	// .clm-footer__bottom-container
	&__bottom-container
		@media (min-width: $media-mobile-min)
			display: flex

	// .clm-footer__left
	&__left
		@media (min-width: $media-mobile-min)
			flex: 0 0 calc(1045 / 1400 * 100%)
			&:not(:last-child) 
				margin-right: 20px
		@media (max-width: $clm-container)
			flex: 0 0 calc(70% - 10px)


	// .clm-footer__rigth
	&__rigth
		@media (min-width: $media-mobile-min)
			display: flex
			flex-direction: column
			flex: 0 0 calc(335 / 1400 * 100%)
			min-width: 0
			overflow-wrap: break-word
		@media (max-width: $clm-container)
			flex: 0 0 calc(30% - 10px)
			

.clm-left-footer
	height: 100%
	display: flex
	flex-direction: column
	padding: 30px 50px
	border-radius: $border-radius
	background-color: $color-white
	@media (max-width: $clm-container)
		padding: 20px
	@media (max-width: $media-mobile-max)
		display: block
		height: auto
		padding: 20px 0
		border-radius: 0
		background-color: transparent
			
		
	// .clm-left-footer__top
	&__top
		flex-grow: 1
		&:not(:last-child) 
			padding-bottom: 30px
			border-bottom: 2px solid $color-gray-bg
			margin-bottom: 30px
			@media (max-width: $media-mobile-max)
				padding-bottom: 20px
				border-bottom: 1px solid $color-gray-blue
				margin-bottom: 20px

		
			
	// .clm-left-footer__top-wrap
	&__top-wrap
		@media (min-width: $media-mobile-min)
			display: flex
			flex-wrap: wrap
			margin-top: -20px
			margin-right: -10px
			margin-left: -10px
		

	// .clm-left-footer__column
	&__column
		@media (min-width: $media-mobile-min)
			flex: 0 0 25%
			padding: 0 10px
			margin-top: 20px
		@media (max-width: 1160px)
			flex: 0 0 50%
		@media (max-width: $media-mobile-max)
			&:not(:last-child) 
				padding-bottom: 20px
				border-bottom: 1px solid $color-gray-blue
				margin-bottom: 20px
		&.active
			.clm-left-footer__title-btn
				svg
					transform: scale(1, -1)
					path
						fill: $color-primary-dark

	// .clm-left-footer__title-block
	&__title-block
		&:not(:last-child) 
			margin-bottom: 20px

		@media (max-width: $media-mobile-max)
			display: flex
			align-items: center
			justify-content: space-between
			&:not(:last-child) 
				margin-bottom: 0
			
	// .clm-left-footer__title
	&__title
		display: block
		font-weight: 500
		font-size: 15px
		line-height: 120%
		color: $color-main
		transition: color 0.2s ease 0s

		@at-root a#{&}
			@media (any-hover: hover)
				&:hover
					color: $color-primary-dark
		
		@media (max-width: $media-mobile-max)
			&:not(:last-child)
				margin-right: 10px

		@media (max-width: $media-mobile-max)
			font-size: 19px

	// .clm-left-footer__title-btn
	&__title-btn
		@media (max-width: $media-mobile-max)
			display: flex
			align-items: center
			justify-content: flex-end
			width: 24px
			height: 24px
			svg
				transition: transform 0.2s ease 0s
				path
					transition: fill 0.2s ease 0s


	// .clm-left-footer__title-icon
	&__title-icon
		@media (max-width: $media-mobile-max)
			position: relative
			display: flex

	// .clm-left-footer__list
	&__list
		@media (max-width: $media-mobile-max)
			display: none
			padding-top: 20px

	// .clm-left-footer__item
	&__item
		display: block
		color: $color-less-light-gray
		transition: color 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				color: $color-primary-dark
		&:not(:last-child)
			margin-bottom: 10px
		@media (max-width: $media-mobile-max)
			font-size: 15px
			&:not(:last-child)
				margin-bottom: 15px

	// .clm-left-footer__middle
	&__middle
		&:not(:last-child) 
			padding-bottom: 30px
			border-bottom: 2px solid $color-gray-bg
			margin-bottom: 30px
			@media (max-width: $media-mobile-max)
				padding-bottom: 20px
				border-bottom: 1px solid $color-gray-blue
				margin-bottom: 20px
		p
			line-height: 140%
			&:not(:last-child) 
				margin-bottom: 10px
			
		

	// .clm-left-footer__bottom
	&__bottom
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: space-between
		@media (max-width: $media-mobile-max)
			display: block

	// .clm-left-footer__rights
	&__rights
		@media (min-width: $media-mobile-min)
			&:not(:last-child)
				margin-right: 20px
		@media (max-width: $media-mobile-max)
			&:not(:last-child)
				margin-bottom: 20px
		

	// .clm-left-footer__pay
	&__pay


.clm-pay
	display: flex
	align-items: center
	&:not(:last-child) 
		margin-right: 20px
	
	// .clm-pay__item
	&__item
		&:not(:last-child) 
			margin-right: 20px
		img
			vertical-align: top
			max-width: 100%
			max-height: 100%
			object-fit: contain


.clm-right-footer
	color: $color-white
	// .clm-right-footer__bg
	&__bg
		flex: 1 1 auto
		padding: 30px 20px
		border-radius: $border-radius
		background-color: $color-primary-dark
		@media (min-width: $media-mobile-min)
			&:not(:last-child)
				margin-bottom: 25px
		@media (max-width: $media-mobile-max)
			padding: 20px 15px
			border-radius: 0
			margin: 0 -15px

	// .clm-right-footer__item
	&__item
		display: flex
		flex-direction: column
		&:not(:last-child)
			margin-bottom: 20px

	// .clm-right-footer__main
	&__main
		font-weight: 500
		line-height: 130%
		color: $color-white
		&.big
			font-size: 23px
			line-height: 120%
		&:not(:last-child)
			margin-bottom: 5px

	// .clm-right-footer__submain
	&__submain
		line-height: 120%
		color: $color-lightest-gray

	// .clm-right-footer__btn
	&__btn


.big


