@import '../modules/_variables'

// * Кнопка вызова меню десктоп
.open-desk-menu-btn
	display: flex
	align-items: center
	height: 100%
	min-height: 40px
	padding-top: 10px
	padding-right: 20px
	padding-bottom: 10px
	padding-left: 20px
	border-radius: $border-radius
	font-family: $font-rubik
	font-weight: 500
	font-size: 15px
	color: $color-white
	background-color: $color-primary
	@media (max-width: 1280px)
		font-size: 14px
		padding-right: 10px
		padding-left: 10px
		

	// .open-desk-menu-btn__text
	&__text

	// .open-desk-menu-btn__icon
	&__icon
		flex: 0 0 20px
		width: 20px
		height: 20px
		margin-right: 10px
		display: flex
		align-items: center
		justify-content: center
		svg
			width: 100%
			height: 100%
			object-fit: contain

//========================================================================================================================================================

// * Меню каталога десктоп (menu-category-desktop)
.mcd
	position: fixed
	z-index: 2400
	top: 0
	left: 0
	display: flex
	width: 100%
	height: 100%
	visibility: hidden
	font-family: "Rubik", sans-serif
	// * Mozila Firefox
	// /* «цвет ползунка» «цвет полосы скроллбара» */
	scrollbar-color: $color-primary-dark transparent
	// /* толщина (auto | thin | none)*/
	scrollbar-width: thin
	// * webkit
	// /* полоса прокрутки (скроллбар) */
	::-webkit-scrollbar
		// /* ширина для вертикального скролла */
		width: 6px
		background-color: transparent

	// /* ползунок скроллбара */
	::-webkit-scrollbar-thumb 
		background-color: $color-primary-dark
		border-radius: $border-radius

	*
		box-sizing: border-box
	&.active
		visibility: visible
	
	// .mcd__body
	&__body
		position: relative
		z-index: 2
		display: flex
		max-width: calc(100% - 60px)
		transform: translate(-105%,0px)
		background-color: $color-white
		transition: all 0.3s ease 0s
		.mcd.active &
			transform: translate(0px,0px)

	// .mcd__bg
	&__bg
		position: fixed
		z-index: 1
		top: 0
		left: 0
		width: 100%
		height: 100%
		flex: 1 1 auto
		background: rgba(0, 0, 0, 0.2)
		transition: all 0.3s ease 0s
		opacity: 0
		.mcd.active &
			opacity: 1

	// .mcd__first
	&__first
		width: 390px
		padding: 30px 10px
		overflow-y: auto
		@media (max-width: $clm-container)
			width: 300px

	// .mcd__second
	&__second
		display: none
		width: 390px
		padding: 40px 15px 40px 20px
		border-left: 1px solid $color-lightest-gray
		overflow: auto
		@media (max-width: $clm-container)
			width: 300px
		&.active
			display: block
			
		
			
			
		// .mcd__second--finish
		&--finish
	
	// .mcd__second-inner
	&__second-inner
		display: none
		&.active
			display: block
		

	// .mcd__spoiler
	&__spoiler
		&:not(:last-child)
			padding-bottom: 20px
			border-bottom: 1px solid $color-lightest-gray
			margin-bottom: 20px

	// .mcd__list
	&__list
		&:not(:last-child)
			padding-bottom: 20px
			border-bottom: 1px solid $color-lightest-gray
			margin-bottom: 20px
			.mcd__spoiler &
				padding-bottom: 0
				border-bottom: none
				margin-bottom: 0

		// .mcd__list--hide
		&--hide
			display: none

	// .mcd__item
	&__item
		font-weight: 400
		font-size: 17px
		color: $color-gray
		&.active
			svg
				fill: $color-primary-dark
				path
					fill: $color-primary-dark
			.mcd__link
				color: $color-primary-dark
			.mcd__text
				color: $color-primary-dark
			.mcd__arrow
				opacity: 1
			.mcd__first &
				.mcd__link
					background-color: $color-gray-blue-light

		svg
			width: 100%
			height: 100%
			object-fit: contain
			fill: $color-gray

		// .mcd__item--title
		&--title
			display: flex
			align-items: center
			// min-height: 30px
			// margin-bottom: 10px
			min-height: 40px
			font-weight: 500
			font-size: 19px
			color: $color-main
			cursor: default

		// .mcd__item--button
		&--button
			color: $color-primary-dark
			.mcd__text
				flex-grow: 0
			.mcd__arrow
				opacity: 1
				transform: rotate(90deg)

		// .mcd__item--more
		&--more
			color: $color-primary-dark
			.mcd__arrow
				opacity: 1

		// .mcd__item--close
		&--close
			color: $color-primary-dark
			.mcd__arrow
				opacity: 1
				transform: rotate(180deg)
				margin-left: -10px
				margin-right: 10px

	// .mcd__link
	&__link
		display: flex
		align-items: center
		color: inherit
		cursor: pointer
		.mcd__first &
			min-height: 50px
			padding: 10px
			border-radius: $border-radius

		.mcd__second &
			min-height: 40px
			padding: 5px 0
			@media (any-hover: hover)
				&:hover
					svg
						fill: $color-primary-dark
					.mcd__text
						color: $color-primary-dark
					.mcd__arrow
						opacity: 1

	// .mcd__icon
	&__icon
		flex: 0 0 30px
		width: 30px
		height: 30px
		margin-right: 10px

	// .mcd__text
	&__text
		flex: 1 1 auto
	// .mcd__txt
	&__txt
		min-height: 40px
		display: flex
		align-items: center

	// .mcd__arrow
	&__arrow
		flex: 0 0 30px
		width: 30px
		height: 30px
		margin-left: 10px
		opacity: 0
		transition: transform 0.2s ease 0s
		svg
			fill: $color-primary-dark
		.mcd__spoiler.active &
			transform: rotate(90deg) scale(-1, 1)
	// .mcd__close
	&__close
		position: absolute
		top: 10px
		right: -40px
		flex: 0 0 30px
		width: 30px
		height: 30px
		cursor: pointer
		svg
			width: 100%
			height: 100%
			object-fit: contain
			transition: fill 0.3s ease 0s
			fill: $color-white

//========================================================================================================================================================

// * Меню каталога mobile (menu-category-mobile)
.mcm
	font-family: "Rubik", sans-serif
	// .mcm__body
	&__body
		padding: 10px 0px
	// .mcm__list
	&__list
		background-color: $color-white
		// .mcm__list--no-first
		&--no-first
			position: absolute
			z-index: 1
			top: 0
			left: 0
			width: 100%
			height: 100%
			transform: translate(100%,0px)
			transition: transform 0.3s ease 0s
			&.active
				transform: translate(0px,0px)

	// .mcm__item
	&__item

		svg
			width: 100%
			height: 100%
			object-fit: contain
		
		// .mcm__item--title
		&--title
			margin-bottom: 10px

		// .mcm__item--section-title
		&--section-title
			.mcm__link
				color: $color-main

	&__btn, &__back-btn, &__link
		display: flex
		align-items: center
		text-align: left
		width: 100%
		min-height: 40px
		padding: 5px 0
		font-size: 15px
		color: $color-gray

	// .mcm__btn
	&__btn
	
	// .mcm__back-btn
	&__back-btn
		font-weight: 500
		font-size: 17px
		color: $color-black
		.mcm__arrow
			margin-right: 15px
			margin-left: 0

	// .mcm__link
	&__link

	// .mcm__icon
	&__icon
		flex: 0 0 30px
		width: 30px
		height: 30px
		margin-right: 10px

	// .mcm__text
	&__text
		flex: 1 1 auto

	// .mcm__arrow
	&__arrow
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 8px
		width: 8px
		height: 14px
		margin-left: 10px


	// .mcm__scroll-section
	&__scroll-section
		height: 100%
		padding: 10px 15px
		overflow-y: auto



