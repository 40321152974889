@import './modules/_variables'

.clm-certificat
	font-family: $font-rubik
	line-height: 1.2
	box-sizing: border-box
	word-break: break-word
	*
		box-sizing: border-box
	// .clm-certificat__link
	&__link
		display: flex
		flex-direction: column
		min-height: 100%
		padding: 20px
		border: 1px solid $color-gray-blue
		border-radius: 20px
		background-color: $color-white
		transition: transform 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				transform: scale(1.045)
				.clm-certificat__ctrl-text
					color: $color-primary
				.clm-certificat__ctrl-img svg path
					fill: $color-primary
		

	// .clm-certificat__title
	&__title
		font-weight: 500
		font-size: 15px
		color: $color-main
		&:not(:last-child)
			margin-bottom: 10px
		@media (max-width: $media-mobile-max)
			font-size: 17px
			

	// .clm-certificat__text
	&__text
		font-size: 14px
		color: $color-gray
		&:not(:last-child)
			margin-bottom: 10px

	// .clm-certificat__ctrl
	&__ctrl
		display: flex
		align-items: center
		justify-content: space-between
		margin-top: auto
		&:not(:last-child)
			margin-bottom: 10px

	// .clm-certificat__ctrl-text
	&__ctrl-text
		font-size: 14px
		line-height: 140%
		color: $color-primary-dark
		transition: color 0.2s ease 0s
		&:not(:last-child) 
			margin-right: 10px
		
	// .clm-certificat__ctrl-img
	&__ctrl-img
		flex: 0 0 20px
		width: 20px
		height: 20px
		svg
			width: 100%
			height: 100%
			object-fit: contain
			path
				transition: fill 0.2s ease 0s
				fill: $color-primary-dark

	// .clm-certificat__count
	&__count
		font-size: 14px
		line-height: 120%
		color: $color-gray

	// .clm-certificat__count-number
	&__count-number

	// .clm-certificat__count-text
	&__count-text

	// .clm-certificat__images-block
	&__images-block
		display: none