@import '../modules/_variables'
.clm-callback
	font-size: 14px
	line-height: 120%
	color: $color-gray

	.warning
		color: $color-error

	.ppl-content &
		margin-top: 20px


	// .clm-callback__outer-wrap
	&__outer-wrap
		margin-bottom: 25px

	// .clm-callback__row
	&__row
		display: flex
		flex-direction: column
		padding: 20px 0
		border-bottom: 2px solid $color-gray-bg
		&:first-child
			border-top: 2px solid $color-gray-bg
			@media (min-width: $media-mobile-min)
				border-top: none
				padding-top: 0


	// .clm-callback__text
	&__text
		font-size: 15px
		line-height: 140%
		color: $color-main
		&:not(:last-child)
			margin-bottom: 20px
		@media (max-width: $media-mobile-max)
			font-size: 14px

	// .clm-callback__main
	&__main
		font-size: 19px
		color: $color-main
		&:not(:last-child)
			margin-bottom: 5px

	// .clm-callback__link
	&__link
		font-size: 19px
		line-height: normal
		color: $color-link
		&:not(:last-child)
			margin-bottom: 5px


	// .clm-callback__sublink
	&__sublink

	// .clm-callback__form
	&__form

	// .clm-callback__wrap-form
	&__wrap-form
		.ppz-exit__inner
			display: block

	// .clm-callback__item
	&__item
		&:not(:last-child)
			margin-bottom: 25px
		&.active
			.clm-callback__icon
				opacity: 1
		&.error
			.clm-callback__warning
				opacity: 1



	// .clm-callback__label
	&__label
		.clm-callback__title
			cursor: pointer

	// .clm-callback__title
	&__title
		display: block
		margin-bottom: 6px

	// .clm-callback__input-block
	&__input-block
		position: relative

	// .clm-callback__icon
	&__icon
		position: absolute
		top: 50%
		right: 20px
		display: flex
		align-items: center
		justify-content: center
		width: 16px
		height: 16px
		transform: translate(0px,-50%)
		opacity: 0
		img
			max-width: 100%
			max-height: 100%
			object-fit: contain

	// .clm-callback__warning
	&__warning
		position: absolute
		top: calc(100% + 4px)
		left: 0
		font-size: 12px
		color: $color-error
		opacity: 0

	// .clm-callback__checkboxes
	&__checkboxes
		display: flex
		flex-wrap: wrap
		border: 1px solid $color-gray-blue
		border-radius: $border-radius
		background-color: $color-gray-blue
		overflow: hidden

	// .clm-callback__check
	&__check
		flex: 1 0 33%
		margin: -1px
		cursor: pointer
		input
			position: absolute
			width: 0
			height: 0
			opacity: 0
			&:checked
				& + .clm-callback__block
					background-color: $color-primary
					color: $color-white
		@media (any-hover: hover)
			&:hover
				.clm-callback__block
					background-color: $color-gray-blue


	// .clm-callback__block
	&__block
		display: flex
		align-items: center
		justify-content: center
		min-height: 50px
		border: 1px solid $color-gray-blue
		background-color: $color-white
		transition: background-color 0.2s ease 0s, color 0.2s ease 0s

	// .clm-callback__captcha
	&__captcha
		margin-bottom: 25px

	// .clm-callback__btn
	&__btn

	// .clm-callback__success
	&__success
		display: none

	// .clm-callback__error
	&__error
		display: none

	// .clm-callback__rights
	&__rights
		margin-top: 15px
		font-size: 13px
		line-height: 130%

	// .clm-callback__color-rights
	&__color-rights
		color: $color-link
		transition: border-bottom 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				border-bottom: 1px solid $color-link

	// .clm-callback__social
	&__social
		display: flex
		margin-top: 10px
		.socil-links__item
			position: relative
			display: flex
			align-items: center
			justify-content: center
			width: 40px
			height: 40px
			// border: 1px solid $color-gray-blue
			border-radius: 50%
			// background-color: $color-gray-bg
			&:not(:last-child) 
				margin-right: 15px
			
			.socil-links__icon
				transition: opacity 0.2s ease 0s
				&.hover
					position: absolute
					top: 0
					left: 0
					opacity: 0
			
			svg
				max-width: 100%
				max-height: 100%
				object-fit: contain
				vertical-align: top

			@media (any-hover: hover)
				&:hover
					.socil-links__icon
						opacity: 0
						&.hover
							opacity: 1


	// .clm-callback__query-btn
	&__query-btn

.callback-query-btn
	display: flex
	align-items: center
	min-height: 50px
	padding: 5px 20px
	border: 1px solid $color-gray-blue
	border-radius: $border-high-radius
	background-color: $color-gray-blue-light
	@media (any-hover: hover)
		&:hover
			background-color: $color-gray-blue
	

	// .callback-query-btn__text
	&__text
		font-weight: 500
		font-size: 15px
		color: $color-main
		margin-right: 20px

	// .callback-query-btn__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 26px
		width: 26px
		height: 26px
		border-radius: 50%
		margin-left: auto
		background-color: $color-primary-dark
		img
			width: 8px
			height: 12px
			object-fit: contain
		
		


