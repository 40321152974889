.products-indicator
	margin-top: 20px
	@media (max-width: 1024px)
		margin-top: 30px
		margin-bottom: 30px
		
	*
		box-sizing: border-box
	// .products-indicator__list
	&__list

	// .products-indicator__item
	&__item
		&:not(:last-child)
			margin-bottom: 15px
		.mf-mark
			position: relative
			margin-left: 5px
			cursor: pointer
			transition: background-color 0.2s ease 0s
			@media (any-hover: hover)
				&:hover
					background-color: $color-primary-lightest
					.float-description
						opacity: 1
						transform: translate(0px,0px)
						pointer-events: all

	// .products-indicator__title
	&__title
		font-size: 0
		&:not(:last-child)
			margin-bottom: 10px
		
		

	// .products-indicator__text
	&__text
		vertical-align: middle
		font-family: "Rubik", sans-serif
		font-size: 14px
		line-height: 120%
		color: #909090
		
	// .products-indicator__line
	&__line
		display: flex

	// .products-indicator__elem
	&__elem
		flex: 1 1 auto
		height: 4px
		border: 1px solid #DDDDDD
		border-radius: 2px
		background-color: #fff
		&.active
			border: 1px solid transparent
		
		&:not(:last-child) 
			margin-right: 5px
		

.float-description
	position: absolute
	z-index: 25
	bottom: calc(100% + 2px)
	left: 50%
	width: 300px
	overflow-y: auto
	padding: 15px
	border-radius: 4px
	border: 1px solid #DDDDDD
	cursor: default
	opacity: 0
	transform: translate(0px,-20px)
	pointer-events: none
	transition: all 0.2s ease 0s
	background-color: #F8FAFC
	font-family: 'Rubik'
	font-style: normal
	font-weight: 400
	font-size: 13px
	line-height: 120%
	color: #333333

	// .float-description__text
	&__text

