@import './modules/_variables'

.hero-filter-section
	margin: 20px 0 50px
	@media (max-width: $media-mobile-max)
		margin: 20px 0

	&__ov
		position: relative

	&__wrap
		margin-left: auto
		margin-right: auto
		position: relative
		overflow: hidden
		list-style: none
		padding: 0
		z-index: 1
		height: 600px
		@media (max-width: $media-mobile-max)
			height: 400px

	&__container
		position: relative
		width: 100%
		height: 100%
		z-index: 1
		display: flex
		transition-property: transform
		box-sizing: content-box

	&__pag
		@media (max-width: $media-mobile-max)
			position: absolute
			bottom: 15px
			z-index: 3
		&.swiper-pagination-bullets
			display: flex
			align-items: center
			justify-content: center
			margin-top: 20px
			& .swiper-pagination-bullet
				width: 35px
				height: 4px
				margin: 0 7px !important
				background: $color-lightest-gray
				border-radius: 10px
				opacity: 1
				&.swiper-pagination-bullet-active
					background: $color-primary-dark

	&__arrow
		width: 40px
		height: 40px
		border-radius: 50%
		cursor: pointer
		border: 1px solid $color-gray-blue
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		z-index: 2
		top: 280px
		background: $color-gray-blue-light
		color: $color-primary-dark
		box-sizing: border-box
		box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)
		@media (max-width: $media-mobile-max)
			display: none
		&.swiper-button-disabled
			display: none

		&--left
			left: -20px
			padding-right: 2px

		&--right
			padding-left: 2px
			right: -20px

	&__slide
		flex-shrink: 0
		width: 100%
		height: 100%
		position: relative
		transition-property: transform
		border-radius: 6px
		overflow: hidden

		&-img
			width: 100%
			height: 100%
			object-fit: cover
			object-position: center center

.hero-filter
	font-family: $font-rubik
	box-sizing: border-box
	font-size: 14px
	line-height: 1.3
	color: $color-main
	padding: 50px
	border: 1px solid $color-gray-blue
	border-radius: 6px
	background: $color-gray-blue-light
	@media (max-width: $media-mobile-max)
		display: none

	&__title
		font-weight: 600
		font-size: 39px
		margin-bottom: 30px

	&__grid
		display: grid
		grid-template-columns: repeat(4, 1fr)
		column-gap: 20px
		row-gap: 20px
		grid-auto-rows: 200px

	&__card
		background: $color-white
		border-radius: 6px
		position: relative
		cursor: pointer
		display: flex
		align-items: flex-end
		justify-content: flex-end
		transition: background-color .2s ease 0s
		padding: 10px

		&:hover
			background-color: $color-primary
			& .hero-filter__card-title,
			& .hero-filter__card-subtitle
				color: $color-white
			& .hero-filter__card-check
				opacity: 1

		&-img
			height: 150px
			width: auto
			object-fit: contain
			border-radius: 50%
			overflow: hidden

		&-inner
			position: absolute
			top: 15px
			right: 20px
			bottom: 15px
			left: 20px
			display: flex
			flex-direction: column

		&-title
			font-weight: 500
			font-size: 19px
			margin-bottom: 5px
			transition: color .2s ease 0s

		&-subtitle
			font-weight: 400
			font-size: 14px
			color: $color-gray
			margin-bottom: auto
			transition: color .2s ease 0s

		&-check
			display: inline-flex
			color: $color-primary
			background-color: $color-white
			border-radius: 100px 6px 6px 100px
			padding: 0 15px 0 35px
			position: relative
			font-weight: 400
			font-size: 14px
			height: 40px
			align-items: center
			transition: opacity .2s ease 0s
			opacity: 0
			&::after
				content: ''
				position: absolute
				width: 10px
				height: 10px
				background-color: $color-primary
				border-radius: 50%
				left: 10px
				top: 15px