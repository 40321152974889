@import '../modules/_variables'

.pdp
	font-family: $font-rubik
	*
		box-sizing: border-box

.pdp-link-underline
	display: inline-flex
	align-items: center
	color: $color-link
	min-height: 24px
	cursor: pointer
	& span
		position: relative
		&::after
			position: absolute
			left: 0
			right: 0
			content: ''
			height: 1px
			bottom: 0
			background-color: $color-link
			opacity: 0
			transition: opacity 0.2s ease 0s
	& img
		margin-right: 8px

	&:focus span::after
		opacity: 1
	@media (any-hover: hover)
		&:hover span::after
			opacity: 1

.pdp-sub
	display: flex
	margin-bottom: 20px
	&__item
		margin-right: 25px
		font-size: 14px
		&.pdp-sub__item--stars
			margin-right: 8px
		&:last-child
			margin-right: 0

	& .link-underline
		display: inline-flex
		align-items: center
		color: $color-link

	& .pmp-rating__review
		display: none
	& .pmp-rating
		margin-top: 0

h1.pdp-title,
.pdp-title
	font-weight: 500
	font-size: 35px
	line-height: 120%
	color: $color-main
	margin-bottom: 10px
	margin-top: 20px
	font-family: $font-rubik

.pdp-wrapper
	display: grid
	grid-template-columns: calc(100% - 440px) 400px
	column-gap: 40px
	margin-bottom: 100px
	@media (max-width: 1170px)
		grid-template-columns: calc(100% - 420px) 400px
		column-gap: 20px
	&__item
		margin-bottom: 20px
		&:last-child
			margin-bottom: 0
	// .pdp-wrapper__total-sticky
	&__total-sticky
		position: sticky
		top: 20px
	// .pdp-wrapper__total
	&__total


.pdp-city-selection
	position: relative
	border-radius: 6px
	box-shadow: 0 4px 40px rgba(51, 51, 51, 0.1)
	font-size: 14px
	color: $color-main
	&__inner
		z-index: 1
		padding: 20px
		border: 1px solid $color-gray-blue
		border-radius: 6px
		background-color: $color-gray-blue-light
		position: relative
	&__top
		display: flex
		flex-wrap: wrap
		margin-bottom: 16px
		align-items: baseline
		justify-content: space-between
	&__title
		font-weight: 500
		font-size: 15px
		white-space: nowrap
		margin-right: 16px
	&__link
		white-space: nowrap
		color: $color-link
		cursor: pointer
	&__item
		display: flex
		align-items: center
		margin-bottom: 16px

		&:last-child
			margin-bottom: 0
		&-i
			color: $color-primary-dark
			margin-right: 10px

.pdp-test
	display: flex
	align-items: center
	justify-content: center
	border: 1px solid #EFF0F4
	border-radius: 6px
	font-size: 23px
	color: $color-gray
	width: 100%

.pdp-swiper
	min-height: 380px
	display: flex
	flex-shrink: 0
	margin-right: 70px
	@media (max-width: 1400px)
		margin-right: 40px
	@media (max-width: 1300px)
		margin-right: 20px
	@media (max-width: 1250px)
		margin-right: 0
		width: 100%


.pdp-swiper-right
	position: relative
	height: 380px
	width: 380px
	margin: 0
	@media (max-width: 1250px)
		margin: 0 auto


	&__slide
		text-decoration: none
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
		position: relative
		&-video
			position: absolute
			z-index: 1
			top: 0
			left: 0
			width: 100%
			height: 100%
			display: flex
			align-items: center
			justify-content: center
			pointer-events: none
			color: $color-primary-dark
		& > img
			width: 100%
			height: 100%
			object-fit: contain
			object-position: center

	&__achiv
		position: absolute
		pointer-events: none
		z-index: 1
		left: 0
		top: 0


.pdp-swiper-left
	width: 60px
	margin-right: 40px
	@media (max-width: 1400px)
		margin-right: 20px
	@media (max-width: 1250px)
		margin-right: 0

	&__swiper
		max-height: 320px
		margin: 10px 0

	&__arrow
		width: 100%
		height: 20px
		display: flex
		align-items: center
		justify-content: center
		border-radius: 6px
		border: 1px solid $color-gray-blue
		transition: background-color .2s ease 0s
		background-color: $color-gray-blue-light
		color: $color-primary-dark

		&--bottom
			& svg
				transform: rotate(180deg)

		&:not(.swiper-button-disabled)
			cursor: pointer
			&:hover
				background-color: $color-gray-blue

	&__wrapper
		flex-direction: column
		height: auto


	&__slide
		$slide: &

		height: 60px
		width: 60px
		margin-bottom: 5px
		border: 1px solid transparent
		transition: border-color .2s ease 0s
		cursor: pointer
		border-radius: 6px
		overflow: hidden
		position: relative

		&.swiper-slide-thumb-active,
		&:hover,
		&:focus
			border-color: $color-primary-dark

		& > img
			width: 100%
			height: 100%
			object-fit: contain
			object-position: center

		&-video
			position: absolute
			z-index: 1
			top: 0
			left: 0
			width: 100%
			height: 100%
			display: flex
			align-items: center
			justify-content: center
			pointer-events: none
			color: $color-primary-dark

		&:last-child
			margin-bottom: 0

.pdp-main
	border: 1px solid $color-gray-blue
	border-radius: 6px
	padding: 20px 40px 20px 20px
	display: flex
	@media (max-width: 1400px)
		padding: 20px
	@media (max-width: 1250px)
		flex-wrap: wrap

	&__content
		padding-top: 30px
		flex-grow: 1
		&-title
			font-weight: 500
			font-size: 15px
			margin-bottom: 16px
		&-spec
			margin-bottom: 14px
			max-width: 350px
			overflow: hidden
			padding-bottom: 2px
			@media (max-width: 1250px)
				max-width: 100%
	&__btns
		display: flex
		flex-wrap: wrap
		align-items: center
		margin-left: -5px
		margin-right: -5px
		margin-bottom: -10px
		box-sizing: border-box
		& > div
			padding: 0 5px 10px
			flex-grow: 1
			& > button
				min-width: 140px
				width: 100%

	&__indicators
		margin-top: 20px

.pdp-spec
	& .ppl-spec__item:not(:last-child)
		margin-bottom: 12px
	& .ppl-spec__parameter
		color: $color-less-light-gray
	& .ppl-spec__parameter::before
		border-color: #EFF0F4
	& .ppl-spec__value
		color: $color-main
		text-decoration: none
	& .ppl-spec__value a
		position: relative
		color: $color-primary-dark
		&::after
			position: absolute
			content: ''
			left: 0
			right: 0
			height: 1px
			bottom: 0
			background-color: $color-primary-dark
			transition: opacity .2s ease 0s
			opacity: 0
		&:hover,
		&:focus
			&::after
				opacity: 1

.pdp-like-btn
	display: flex
	align-items: center
	justify-content: center
	color: $color-gray
	transition: all .2s ease 0s
	font-weight: 500
	font-size: 14px
	height: 40px
	outline: none
	border: 1px solid $color-gray-blue
	border-radius: 100px
	padding: 0 16px
	background-color: $color-gray-blue-light
	white-space: nowrap
	line-height: 1
	& > svg
		transition: color .2s ease 0s
		margin-right: 6px
		flex-shrink: 0
	& > span
		padding-top: 2px
	&:hover,
	&:focus
		outline: none
		background-color: $color-gray-blue
		
	& .pdp-like-btn__active
		display: none
	
	&.active
		background-color: $color-primary
		border-color: $color-primary
		color: $color-white
		& .pdp-like-btn__active
			display: inline
		& .pdp-like-btn__no-active
			display: none

.pdp-tabs
	border: 1px solid $color-gray-blue
	border-radius: 6px
	padding: 20px

	&__titles
		display: flex
		flex-wrap: wrap
		align-items: center
		list-style: none
		margin-bottom: 14px
		&-item
			padding: 7px 16px
			font-weight: 400
			font-size: 14px
			border-radius: 6px
			color: $color-gray
			transition: all .2s ease 0s
			margin-right: 10px
			margin-bottom: 6px
			white-space: nowrap

			&.active
				background-color: $color-primary
				color: $color-white

			&:not(.active)
				&:hover,
				&:focus
					background-color: $color-gray-blue
					cursor: pointer
					color: $color-gray

	&__sections
		&-item
			display: none
			&.active
				display: block

.pdp-compound
	padding-top: 6px
	&__item
		display: flex
		align-items: center
		padding: 2px 0
		&:last-child
			& .pdp-compound__item-img
				transform: scale(1, -1)
		&-img
			flex: 0 1 120px
			margin-right: 20px
		&-text
			font-weight: 400
			font-size: 14px
			display: flex
			align-items: center
			& .pdp-mark
				margin-left: 10px

.pdp-mark
	width: 20px
	height: 20px
	border: 1px solid $color-primary-dark
	border-radius: 50%
	display: inline-flex
	align-items: center
	justify-content: center
	color: $color-primary-dark
	transition: all .2s ease 0s

	&:hover
		background-color: $color-primary-dark
		color: $color-white
		box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.08)

.pdp-characteristics
	&__title
		font-weight: 500
		font-size: 15px
		margin-bottom: 20px
	&__content
		column-count: 2
		column-gap: 40px
		@media (max-width: 1250px)
			column-gap: unset
			column-count: unset

.pdp-instructions
	display: grid
	grid-template-columns: repeat(2, 1fr)
	column-gap: 20px
	row-gap: 20px

	&__item
		display: flex
		flex-direction: column
		text-decoration: none
		color: $color-gray
		border-radius: 20px
		border: 1px solid $color-gray-blue
		transition: border-color .2s ease 0s
		padding: 20px 40px 20px 20px
		cursor: pointer
		&:hover,
		&:focus
			border-color: $color-primary-dark
		&-title
			font-weight: 500
			font-size: 15px
			color: $color-main
			margin-bottom: 10px
		&-text
			font-size: 14px
			margin-bottom: 16px
		&-bottom
			margin-top: auto
			display: flex
			align-items: center
			justify-content: space-between
		&-icon
			color: $color-primary-dark
			flex-shrink: 0
			margin-left: 10px
		&-file
			color: $color-primary-dark
			font-weight: 400
			font-size: 14px


.pdp-user-section
	font-weight: 400
	font-size: 14px
	color: $color-gray
	line-height: 1.4
	text-decoration: none
	font-family: $font-rubik

	& p
		margin-bottom: 16px
		font-family: $font-rubik

	& h1,h2,h3,h4,h5,h6
		color: $color-main
		font-family: $font-rubik
		font-size: 15px
		font-weight: 500
		line-height: 1.2
		margin-bottom: 8px

	& img
		max-width: 100%
		width: auto
		height: auto
		margin-top: 15px
		margin-bottom: 15px

	& a
		text-decoration: none
		color: $color-primary-dark
		position: relative
		&::after
			position: absolute
			left: 0
			right: 0
			content: ''
			height: 1px
			bottom: 0
			background-color: $color-primary-dark
			opacity: 0
			transition: opacity 0.2s ease 0s
		&:focus::after
			opacity: 1
		@media (any-hover: hover)
			&:hover::after
				opacity: 1

	& ul,ol
		margin-bottom: 16px
		text-align: left
		max-width: 640px

	& ul
		list-style-type: none
		padding-left: 0
		& li
			position: relative
			padding-left: 20px
			margin-bottom: 8px
			&::before
				content: ''
				width: 10px
				height: 10px
				border-radius: 50%
				background-color: $color-primary-dark
				position: absolute
				left: 0
				top: 4px
			&:last-child
				margin-bottom: 0

	& :last-child
		margin-bottom: 0 !important
	& :first-child
		margin-top: 0 !important


.pdp-price
	position: relative
	border-radius: 6px
	box-shadow: 0 4px 40px rgba(51, 51, 51, 0.1)
	font-size: 15px
	color: $color-main
	&__inner
		display: flex
		flex-direction: column
		min-height: 422px
		z-index: 1
		padding: 20px
		border-radius: 6px
		background-color: $color-white
		position: relative
		
	&__count
		display: flex
		align-items: center
		padding-bottom: 20px
		border-bottom: 1px solid $color-gray-blue-light
		justify-content: space-between
		&-title
			font-weight: 400
			font-size: 15px
			color: $color-main
		&-mp
			flex-shrink: 0
			margin-left: 10px
			
	&__price
		padding: 20px 0
		margin-bottom: auto
		&-top
			display: flex
			align-items: baseline
			justify-content: space-between
		&-title
			font-weight: 400
			font-size: 15px
			color: $color-main
		&-num
			font-weight: 400
			font-size: 23px
			color: $color-main
			&.active
				color: $color-error
		&-old
			text-align: right
			font-weight: 400
			font-size: 15px
			color: $color-gray
			text-decoration: line-through

.pdp-count
	display: flex
	align-items: center

	&__count
		text-align: center
		flex-shrink: 0
		width: 40px
		margin: 0 4px
		font-weight: 400
		font-size: 15px
	
	&__btn
		flex-shrink: 0
		cursor: default
		border-radius: 50%
		width: 38px
		height: 38px
		background-color: $color-gray-blue-light
		border: 1px solid $color-gray-blue
		color: $color-gray
		
		display: flex
		align-items: center
		justify-content: center
		
		transition: background-color .2s
		
		outline: none
		&:hover,
		&:focus
			outline: none

		&.active
			color: $color-primary
			
			&:hover,
			&:focus
				cursor: pointer
				background-color: $color-gray-blue
				
.pdp-buy
	display: flex
	flex-direction: column
	flex: 1 1 auto
	&.active
		.pdp-buy__text-1
			display: none
		.pdp-buy__block-text
			display: block
	&__btn
		display: flex
		align-items: center
		justify-content: center
		min-height: 50px
		padding: 5px 15px
		margin-top: auto
		border-radius: 100px
		background-color: $color-primary
		line-height: 1
		width: 100%
		transition: background-color .2s
		
		&:hover
			outline: none
			background-color: $color-primary-dark
			cursor: pointer
		
	&__text-1
		font-size: 15px
		font-weight: 500
		color: $color-white
	
	&__block-text
		display: none
	
	&__text-2
		font-size: 15px
		font-weight: 500
		color: $color-white
		
	&__text-3
		margin-top: 2px
		font-size: 12px
		font-weight: 500
		color: $color-white
		
.pdp-buy-also
	border: 1px solid #EFF0F4
	border-radius: 6px
	padding: 20px
	
	max-height: 370px
	overflow-y: auto
	
	scrollbar-color: #C1C1C1 #F8FAFC /* «цвет ползунка» «цвет полосы скроллбара» */
	scrollbar-width: thin  /* толщина */
	&::-webkit-scrollbar
		box-sizing: border-box
		width: 15px
		border: 1px solid $color-gray-blue
		border-right: none
		border-radius: 6px
	&::-webkit-scrollbar-thumb
		box-sizing: border-box
		background: #C1C1C1
		border-radius: 10px
		border: 5px solid transparent
		background-clip: content-box
	
	&__title
		font-weight: 500
		font-size: 15px
	
	&__item
		padding: 15px 0
		display: flex
		align-items: flex-start
		line-height: 1.2
		
		border-bottom: 1px solid $color-gray-blue
	
		&:last-child
			border-bottom: none
			padding-bottom: 0
			
		&-img
			width: 70px
			height: 70px
			flex-shrink: 0
			margin-right: 10px
			
			& > img
				width: 100%
				height: 100%
				object-fit: contain
				object-position: top center

		// .pdp-buy-also__item-right
		&-right
			flex-grow: 1
				
		&-title
			font-weight: 400
			font-size: 14px
			margin-bottom: 8px
			color: $color-main
			display: block
		
		&-price
			font-weight: 400
			font-size: 19px
			margin-bottom: 10px
			
		&-control
			display: flex
			align-items: center
			
		&-btn
			height: 38px
			padding: 5px
			border-radius: 100px
			margin-right: 8px
			width: 100%
			display: flex
			padding: 5px
			align-items: center
			justify-content: center
			line-height: 1
			transition: color .2s, background-color .2s
			border: 1px solid $color-primary
			color: $color-primary
			outline: none
			
			font-weight: 500
			font-size: 14px
			
			&.active
				color: $color-white
				background-color: $color-primary
				& .pdp-buy-also__item-btn-no-active
					display: none
				& .pdp-buy-also__item-btn-active
					display: inline
			
			&:hover
				cursor: pointer
				outline: none
				color: $color-white
				background-color: $color-primary

			&-active
				display: none
				
.pdp-reviews-link
	position: relative
	display: inline-flex
	align-items: center
	color: $color-link
	min-height: 24px
	cursor: pointer
	&::before
		content: ''
		position: absolute
		left: -10px
		right: -10px
		bottom: -30px
		top: 0
		z-index: 1
		visibility: hidden
		
	&:hover
		&::before
			visibility: visible
		& .pdp-reviews-link__btn
			opacity: 1
			visibility: visible
	
	&__btn
		position: absolute
		top: 25px
		left: 50%
		transform: translateX(-50%)
		white-space: nowrap
		font-weight: 400
		font-size: 13px
		padding: 6px 7px
		background: $color-white
		color: $color-black
		line-height: 1
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)
		border-radius: 6px
		cursor: pointer
		display: block
		z-index: 2
		transition: opacity .2s ease 0s, background-color .2s ease
		opacity: 0
		visibility: hidden
		&:hover
			background-color: $color-gray-blue-light

.pdp-share
	position: relative
	display: inline-flex
	align-items: center
	color: $color-link
	min-height: 24px
	
	&:hover
		& .pdp-share__list
			opacity: 1
			visibility: visible
	
	& > img
		margin-right: 8px
	
	&__list
		position: absolute
		z-index: 2
		left: -8px
		top: 25px
		transition: opacity .2s ease 0s
		opacity: 0
		visibility: hidden
		
		&:hover
			opacity: 1
			visibility: visible
		
		&::before
			content: ''
			z-index: 1
			position: absolute
			top: -30px
			left: -10px
			right: -10px
			bottom: -10px
	
		&-link
			display: flex
			align-items: center
			justify-content: center
			transition: background-color .2s ease
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)
			background: $color-white
			border-radius: 50%
			width: 32px
			height: 32px
			margin-bottom: 4px
			position: relative
			z-index: 2
			cursor: pointer
			
			&:hover
				background-color: $color-gray-blue-light
	
.pdp-size
	cursor: pointer
	color: $color-primary
	margin-top: 20px
	transition: color .2s ease 0s, border-color .2s ease 0s !important
	&:hover,
	&:focus
		color: $color-primary-dark
		border-color: $color-primary-dark
		
.pdp-certificates
	display: grid
	grid-template-columns: repeat(4, 1fr)
	column-gap: 20px
	row-gap: 20px
	@media (max-width: 1300px)
		grid-template-columns: repeat(3, 1fr)
	@media (max-width: 1150px)
		grid-template-columns: repeat(2, 1fr)
		& .clm-certificat
			min-height: 200px
	
	& .clm-certificat
		min-height: 280px