@import './modules/_variables'

.new-header
	font-family: "Rubik", sans-serif
	font-size: 14px
	line-height: 120%
	color: $color-gray
	*
		box-sizing: border-box

	// .new-header__top
	&__top

	// .new-header__middle
	&__middle
	
	@media (max-width: $media-mobile-max)
		display: none
		

.new-top-header
	background-color: $color-gray-bg
	// .new-top-header__container
	&__container
		display: flex
		align-items: center

	// .new-top-header__links
	&__links
		display: flex
		flex-wrap: wrap
		margin-right: 40px
		margin-left: -30px

	// .new-top-header__link
	&__link
		display: flex
		align-items: center
		min-height: 40px
		margin-left: 30px
		color: $color-gray
		transition: color 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				color: $color-primary-dark
		
		

	// .new-top-header__geo
	&__geo
		margin-left: auto
		flex-shrink: 0

.new-header-links
	position: relative
	@media (any-hover: hover)
		&:hover
			.new-header-links__icon
				svg path
					fill: $color-primary-dark

	&.active
		color: $color-primary-dark
		.new-header-links__icon
			svg
				transform: scale(1, -1)
				path
					fill: $color-primary-dark
	
	
	// .new-header-links__title
	&__title
		align-self: stretch
		display: flex
		align-items: center
		cursor: pointer

	// .new-header-links__text
	&__text

	// .new-header-links__icon
	&__icon
		margin-left: 8px
		svg 
			transition: transform 0.2s ease 0s
			path
				transition: fill 0.2s ease 0s

	// .new-header-links__body
	&__body
		display: none
		position: absolute
		z-index: 10
		top: 100%
		left: -20px
		width: 200px
		padding: 20px
		border-radius: 0 0 $border-radius $border-radius
		background-color: $color-white
		box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.2)

	// .new-header-links__item
	&__item
		&:not(:last-child)
			margin-bottom: 10px

	// .new-header-links__link
	&__link
		color: $color-gray
		transition: color 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				color: $color-primary-dark
				
		


.geo-header
	display: flex
	button
		transition: color 0.2s ease 0s
	// .geo-header__current
	&__current
		display: flex
		align-items: center
		color: $color-gray
		@media (any-hover: hover)
			&:hover
				color: $color-primary-dark
				.geo-header__icon svg path
					fill: $color-primary-dark
				
		

	// .geo-header__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		margin-right: 8px
		svg path
			transition: fill 0.2s ease 0s

	// .geo-header__city
	&__city


	// .geo-header__ctrl
	&__ctrl
		display: flex
		align-items: center
		margin-left: 10px
		&.cookie
			display: none

	// .geo-header__button
	&__button
		padding: 4px 12px
		border-radius: $border-radius
		background-color: $color-primary
		font-weight: 500
		font-size: 13px
		color: $color-white
		transition: background-color 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				background-color: $color-primary-dark
		
		&:not(:last-child)
			margin-right: 10px

	// .geo-header__switch
	&__switch
		color: $color-link
		@media (any-hover: hover)
			&:hover
				color: $color-link
		

.new-middle-header
	// .new-middle-header__container
	&__container
		display: flex
		align-items: center
		min-height: 100px

	// .new-middle-header__left
	&__left
		display: flex
		align-items: center
		flex: 1 1 auto
		&:not(:last-child) 
			margin-right: 30px
		
	// .new-middle-header__logo
	&__logo
		a
			display: flex
			align-items: center
			justify-content: center
			width: 200px
			height: 55px
			img
				max-width: 100%
				max-height: 100%
				object-fit: contain


	// .new-middle-header__search
	&__search
		display: none
		flex: 1 1 auto
		margin-left: 50px

	// .new-middle-header__phone
	&__phone
		flex-shrink: 0
		margin-left: auto
		&:not(:last-child) 
			margin-right: 30px
		

	// .new-middle-header__icons
	&__icons

.new-search-header
	position: relative
	height: 44px
	&.active
		& .new-search-header__form
			position: absolute
			left: 0
			top: 0
			right: 0
			z-index: 1000
			background-color: $color-white
			box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2)
		& .new-search-header__input
			border-color: $color-gray-blue
			background-color: $color-gray-bg

	// .new-search-header__form
	&__form
		display: block
		border: 1px solid $color-lightest-gray
		border-radius: $border-radius
		transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2)
				border-color: $color-gray-blue
				& .new-search-header__input
					background-color: $color-gray-bg

	&__results
		max-height: 500px
		overflow-y: auto
		scrollbar-color: #DDDDDD #F8FAFC /* «цвет ползунка» «цвет полосы скроллбара» */
		scrollbar-width: thin  /* толщина */
		&::-webkit-scrollbar
			box-sizing: border-box
			width: 15px
			border: 1px solid $color-gray-blue
			border-right: none
			border-radius: 6px
		&::-webkit-scrollbar-thumb
			box-sizing: border-box
			background: #DDDDDD
			border-radius: 10px
			border: 5px solid transparent
			background-clip: content-box
		&-item
			display: block
			padding: 12px 20px
			color: $color-main
			font-size: 14px
			line-height: 1.3
			transition: background-color .2s ease 0s
			position: relative
			&:not(:last-child)::after
				content: ''
				position: absolute
				left: 20px
				right: 20px
				height: 1px
				bottom: 0
				background-color: $color-gray-blue

			&.is-span
				color: $color-less-light-gray
			&:not(.is-span)
				&:hover,
				&:focus
					cursor: pointer
					background-color: #EFF0F4

	&__content
		display: none
		border-radius: 0 0 6px 6px
		overflow: hidden
		&.active
			display: block
		&-top
			border-top: 1px solid $color-gray-blue
			background-color: $color-gray-blue-light
		&-bottom
			background-color: $color-white
			border-top: 1px solid $color-gray-blue
			padding: 20px
			display: flex
			align-items: center
			justify-content: space-between

	// .new-search-header__line
	&__line
		position: relative

	// .new-search-header__input
	&__input
		width: 100%
		height: 44px
		padding: 5px 60px 5px 20px
		background-color: $color-white
		border-radius: $border-radius
		color: $color-main
		border: none
		&::placeholder
			color: $color-gray
		//&:active, &:focus
		//	border-color: $color-gray-blue
		//	background-color: $color-gray-bg
		//	box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2)
		
		

	// .new-search-header__button
	&__button
		position: absolute
		top: 0
		right: 0
		display: flex
		align-items: center
		justify-content: center
		width: 58px
		height: 100%
		cursor: default
		svg
			vertical-align: top

.new-phone-header
	text-align: left
	cursor: pointer
	&.active
		.new-phone-header__icon
			svg
				transform: scale(-1,1)
		.new-phone-header__text
			color: $color-primary-dark
		.new-phone-header__icon svg path
			fill: $color-primary-dark
		.new-phone-header__bottom
			color: $color-main
	
	@media (any-hover: hover)
		&:hover
			.new-phone-header__text
				color: $color-primary-dark
			.new-phone-header__icon svg path
				fill: $color-primary-dark
			.new-phone-header__bottom
				color: $color-main
	
	// .new-phone-header__top
	&__top
		display: flex
		align-items: center
		&:not(:last-child)
			margin-bottom: 3px

	// .new-phone-header__text
	&__text
		font-weight: 500
		font-size: 19px
		color: $color-main
		transition: color 0.2s ease 0s

	// .new-phone-header__icon
	&__icon
		margin-left: 10px
		svg
			vertical-align: top
			transition: transform 0.2s ease 0s
			path
				transition: fill 0.2s ease 0s

	// .new-phone-header__bottom
	&__bottom
		color: $color-gray
		transition: color 0.2s ease 0s

.new-header-icons
	display: flex
	margin: 0 -15px
	// .new-header-icons__item
	&__item
		display: flex
		flex-direction: column
		align-items: center
		color: $color-gray
		transition: color 0.2s ease 0s
		margin: 0 15px
		@media (any-hover: hover)
			&:hover
				color: $color-primary-dark

				.new-header-icons__icon
					svg path
						fill: $color-primary-dark
		
		

	// .new-header-icons__wrap-icon
	&__wrap-icon
		position: relative

	// .new-header-icons__icon
	&__icon
		width: 30px
		height: 30px
		margin-bottom: 4px
		svg
			vertical-align: top
			path
				transition: fill 0.2s ease 0s

	// .new-header-icons__text
	&__text

	// .new-header-icons__count
	&__count
		position: absolute
		top: -6px
		right: -8px
		display: flex
		align-items: center
		justify-content: center
		width: 22px
		height: 22px
		border: 2px solid $color-white
		border-radius: 50%
		background-color: $color-primary-dark
		font-weight: 500
		font-size: 11px
		color: $color-white



