@import './modules/_variables'
@import './header_mobile/_header_mobile'
@import './tap-bar/_tap-bar'
@import './basket-section/_basket-section'
@import './favorite-empty/_favorite-empty'
@import './shares-mobile/_shares-mobile'
@import './mobile_filter/_mobile_filter'
@import './catalog/_catalog'
@import './product-mobile-page/_product-mobile-page'
@import './product-desktop-page/_product-desktop-page'
@import './popup-layouts/_popup-layouts'
@import './_products-indicator'
@import './breadcrumbs/_breadcrumbs.sass'
@import './custom-description/_custom-description'
@import './_common'
@import './_see-more.sass'
@import '_desktop-smart-filter'
@import './blocks/_menu-category'
@import './feedback/_feedback'

/*========================================================================================================================================================*/
// * назначение стилей для заголовков (класс уже был задан всем заголовкам предыдущим разработчиком)
.section-title
	font-family: $font-main
	color: $color-black
	@media (max-width: $media-mobile-max)
		font-size: 17px
		font-weight: 700
		line-height: 130%
.font-rubik
	font-family: $font-rubik
/*========================================================================================================================================================*/
// * новая верстка в старых файлах
.rds-title
	margin-top: 20px
	font-family: $font-rubik
	font-size: 19px
	font-weight: 500
	color: $color-black
.rds-btn
	margin-top: 10px
/*========================================================================================================================================================*/

.new-mobile-menu__white-content .new-mobile-menu__white-content-link
	padding: 10px 0
	.old-menu &
		padding: 0

.old-menu
	
	.new-mobile-menu__white:first-child
		border-top: none
	.new-mobile-menu__white
		border-top: 1px solid #F8F7F7
	.new-mobile-menu__white-title
		padding-top: 18px
		padding-bottom: 16px
	.new-mobile-menu__white-title-text,
	.new-mobile-menu__white-content-link,
	.new-mobile-menu__gray-text
		color: $color-black
	.new-mobile-menu__white-title-text
		text-transform: uppercase
		font-size: 15px


.js-ppzStatic[data-name="product-description"] .ppl-content
	font-size: 15px
	line-height: 1.2



@import './_header'
@import './product-card/_product-card'
@import './callback-form/_callback-form'
@import './size-form/_clm-size'
@import './_service-modal-window'
@import './_certificat'
@import './_footer'
@import './_popular-categories'
@import './_home-cards'
@import './_social-links-next'
@import './_hero-filter'