@import '../modules/_variables'
.clm-card
	position: relative
	display: flex
	flex-direction: column
	border-radius: $border-radius
	box-sizing: border-box
	background-color: $color-white

	@include default-style-title

	*
		box-sizing: border-box

	@media (any-hover: hover)
		&:hover
			.clm-card__wrap
				position: absolute
				z-index: 15
				width: 100%
				min-height: 100%
				box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.1)
			.clm-card__bottom
				display: flex
			.clm-card__info
				display: flex
				align-items: center
				gap: 10px
				// display: block
			.clm-card__specifications
				display: block
				// display: flex
				// align-items: center
				// gap: 10px
			.clm-card__remove-favorite
				z-index: 16
			.clm-card__remove-compare
				z-index: 16

	.swiper-pagination
		position: absolute
		bottom: 10px
		display: flex
		gap: 8px
		justify-content: center
		width: 100%
		padding: 0 10px
	.swiper-pagination-bullet
		flex: 0 1 10px
		min-width: 3px
		height: 3px
		border-radius: $border-high-radius
		background-color: $color-lightest-gray
		transition: background-color 0.2s ease 0s
		&.swiper-pagination-bullet-active
			background-color: $color-primary-dark

	.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet
		margin: 0

	// .clm-card__remove-favorite
	&__remove-favorite
		display: none
		.clm-favorites &
			display: flex

	// .clm-card__remove-compare
	&__remove-compare
		display: none
		.product-comparsion
			display: flex

	.clm-achieve
		top: 20px
		left: 20px

	// .clm-card__wrap
	&__wrap
		display: flex
		flex-direction: column
		flex: 1 1 auto
		border-radius: $border-radius
		background-color: $color-white
		font-family: $font-rubik
		font-size: 14px
		line-height: 120%
		color: $color-main
		@media (any-hover: none)
			border: 1px solid $color-gray-blue
			border-radius: $border-radius

	// .clm-card__wrap-slider
	&__wrap-slider
		position: relative
		// height: 200px
		border-radius: $border-radius
		margin-bottom: 15px
		overflow: hidden
		background-color: $color-white



	// .clm-card__block-slider
	&__block-slider

	// .clm-card__outre-slider
	&__outer-slider

	// .clm-card__slider
	&__slider


	// .clm-card__img
	&__img
		position: relative
		width: 100%
		padding-bottom: 100%
		&.active
			display: block
		img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: contain
			vertical-align: top

		@media (min-width: $media-mobile-min)
			display: none

	// .clm-card__pagination-slider
	&__pagination-slider


	// .clm-card__grid-slider
	&__grid-slider
		position: absolute
		z-index: 5
		top: 0
		left: 0
		width: 100%
		height: 100%
		display: flex
		@media (max-width: $media-mobile-max)
			display: none

	// .clm-card__cell-slider
	&__cell-slider
		flex: 1 1 auto

	// .clm-card__slider-dots
	&__slider-dots
		position: absolute
		z-index: 6
		bottom: 10px
		left: 50%
		display: flex
		gap: 8px
		justify-content: center
		width: 100%
		padding: 0 10px
		transform: translate(-50%,0px)
		pointer-events: none
		@media (max-width: $media-mobile-max)
			display: none


	// .clm-card__slider-dot
	&__slider-dot
		flex: 0 1 10px
		min-width: 3px
		height: 3px
		border-radius: $border-high-radius
		background-color: $color-lightest-gray
		&.active
			background-color: $color-primary-dark



	// .clm-card__content-block
	&__content-block
		display: flex
		flex-direction: column
		flex:  1 1 auto
		padding: 0 20px 20px 20px

	// .clm-card__breadcrumbs
	&__breadcrumbs
		&.clm-breadcrumbs-card
			margin: -10px 0 10px -10px

	// .clm-card__text
	&__text

	// .clm-card__title
	&__title
		display: -webkit-box
		overflow: hidden
		-webkit-box-orient: vertical
		-webkit-line-clamp: 2
		font-size: 15px
		&:not(:last-child)
			margin-bottom: 10px

	// .clm-card__param
	&__param

	// .clm-card__specifications
	&__specifications
		display: none
		margin-top: 10px
		@media (any-hover: none)
			display: block
			// display: flex
			// align-items: center
			// gap: 10px

	// .clm-card__ctrl-block
	&__ctrl-block
		display: flex
		flex-direction: column
		justify-content: flex-end
		flex: 1 1 auto


	// .clm-card__price
	&__price
		display: flex
		flex-wrap: wrap
		gap: 10px
		margin-top: 15px

	// .clm-card__current-price
	&__current-price
		flex-shrink: 0
		font-size: 25px
		color: $color-main
		&.double-price
			color: $color-error


	// .clm-card__old-price
	&__old-price
		flex-shrink: 0
		font-size: 15px
		text-decoration: line-through
		color: $color-less-light-gray

	// .clm-card__bottom
	&__bottom
		display: none
		align-items: center
		gap: 20px
		margin-top: 15px
		@media (any-hover: none)
			display: flex




	// .clm-card__btn
	&__btn
		width: auto
		flex: 1 1 auto
		min-height: 40px

	// .clm-card__ctrl
	&__ctrl
		flex-shrink: 0



.js-hovSlider

.js-catalogSlider

.js-hovSlider-img

.active

.js-hovSlider-cell

.js-hovSlider-dot

.clm-breadcrumbs-card
	display: flex
	margin: -10px 0 0 -10px
	// .clm-breadcrumbs-card__item
	&__item
		display: flex
		align-items: center
		margin: 10px 0 0 10px
		color: $color-less-light-gray
		&:not(:last-child)
			&::after
				content: ''
				width: 4px
				height: 4px
				border-radius: 50%
				margin-top: 2px
				margin-left: 10px
				background-color: $color-less-light-gray
		&:last-child
			color: $color-primary-dark


.clm-param-card

	// .clm-param-card__line
	&__line
		display: flex
		&:not(:last-child)
			margin-bottom: 10px

	// .clm-param-card__title
	&__title
		flex-shrink: 0
		max-width: 130px
		color: $color-less-light-gray
		&:not(:last-child)
			margin-right: 8px

	// .clm-param-card__description
	&__description
		display: flex
		align-items: flex-start

	// .clm-param-card__icon
	&__icon
		display: inline-block
		flex: 0 0 24px
		width: 24px
		height: 24px
		vertical-align: middle

		&:not(:last-child)
			margin-top: -3px
			margin-right: 5px
		img
			display: inline-block
			width: 100%
			height: 100%
			object-fit: contain

	// .clm-param-card__text
	&__text


.clm-info-card
	flex: 1 1 auto
	display: flex
	flex-wrap: wrap
	justify-content: space-evenly
	border-radius: $border-radius
	background-color: $color-gray-bg
	box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.2)
	overflow: hidden
	// .clm-info-card__item
	&__item
		display: flex
		align-items: center
		flex: 1 1 auto
		margin-left: -1px
		&::before
			content: ''
			flex: 0 0 1px
			width: 1px
			height: calc( 100% - 10px )
			border-radius: $border-high-radius
			background: rgba(144, 144, 144, 0.2)

	// .clm-info-card__wrap-item
	&__wrap-item
		display: flex
		flex-direction: column
		align-items: center
		flex: 1 1 auto
		padding: 6px

	// .clm-info-card__title
	&__title
		font-size: 12px
		color: $color-gray
		&:not(:last-child)
			margin-bottom: 3px

	// .clm-info-card__param
	&__param


.clm-ctrl-card
	display: flex
	align-items: center
	gap: 15px
	// .clm-ctrl-card__item
	&__item
		vertical-align: top
		flex: 0 0 30px
		height: 30px
		width: 30px
		svg path
			transition: fill 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				svg path
					fill: $color-primary-dark

		&.active
			svg path
				fill: $color-primary-dark



.clm-indicator
	margin-top: 10px
	margin-bottom: 15px

	*
		box-sizing: border-box

	&__item
		&:not(:last-child)
			margin-bottom: 8px
		.mf-mark
			position: relative
			margin-left: 5px
			cursor: pointer
			transition: background-color 0.2s ease 0s
			border-color: $color-gray-blue
			background-color: $color-gray-bg

			@media (any-hover: hover) and (min-width: $media-mobile-min)
				&:hover
					.float-description
						opacity: 1
						transform: translate(0px,0px)
						pointer-events: all

		.float-description
			@media (max-width: $media-mobile-max)
				display: none

	&__mark
		width: 20px
		height: 20px
		border-radius: 50%


	&__title
		font-size: 0
		&:not(:last-child)
			margin-bottom: 4px

	&__text
		vertical-align: middle
		font-family: "Rubik", sans-serif
		font-size: 14px
		line-height: 1.2
		color: var(--color-less-light-gray)

	&__line
		display: flex

	&__elem
		flex: 1 1 auto
		height: 10px
		border: 1px solid $color-gray-blue
		border-radius: 6px
		background-color: #fff
		padding: 1px
		overflow: hidden
		&-i
			display: none
			width: 100%
			height: 100%
			border-radius: 6px
			&.active
				display: block
		&:not(:last-child)
			margin-right: 5px

.product-card-slick .clm-indicator
	display: none