@import './modules/_variables'
.see-more
	margin-top: 50px
	font-family: $font-rubik

	@media (any-hover: hover)
		.clm-card__specifications
			display: block
		.clm-card__bottom
			display: flex
		.clm-card:hover .clm-card__wrap
			position: static
			width: auto
			box-shadow: none
	.clm-card
		height: 100%
	.clm-card__wrap
		height: 100%
	// .see-more__container
	&__container
		position: relative
		@media (max-width: $clm-container)
			padding-bottom: 80px
		@media (max-width: $media-mobile-max)
			padding-bottom: 40px
		*
			box-sizing: border-box

	// .see-more__top
	&__top
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: space-between
		margin-top: -20px
		@media (max-width: $media-mobile-max)
			display: block
			margin-top: 0

	// .see-more__title
	&__title
		margin-right: 20px
		margin-top: 20px
		font-size: 35px
		color: $color-main
		@media (max-width: $media-mobile-max)
			font-weight: 500
			font-size: 25px
			margin-top: 0
			margin-right: 0

	// .see-more__filter
	&__filter
		margin-top: 20px
		@media (max-width: $media-mobile-max)
			margin-top: 10px

	// .see-more__slider
	&__slider
		margin-top: 30px
		@media (max-width: $media-mobile-max)
			margin-top: 20px


	// .see-more__wrapper
	&__wrapper

	// .see-more__slide
	&__slide
		display: flex
		flex-direction: column
		height: auto

	// .see-more__pagination
	&__pagination
		display: none
		position: absolute
		bottom: 0 !important
		justify-content: center
		width: 100%
		padding: 0 10px
		@media (max-width: $media-mobile-max)
			display: flex
		.swiper-pagination-bullet
			flex: 0 1 30px
			min-width: 3px
			height: 3px
			border-radius: $border-high-radius
			margin: 0 5px !important
			background-color: $color-lightest-gray
			transition: background-color 0.2s ease 0s
			&.swiper-pagination-bullet-active
				background-color: $color-primary-dark

	// .see-more__slider-btn
	&__slider-btn
		position: absolute
		display: none
		z-index: 10
		top: 50%
		align-items: center
		justify-content: center
		flex: 0 0 50px
		width: 50px
		height: 50px
		border-radius: 50%
		background: $color-gray-bg
		color: $color-primary-dark
		box-shadow: 0px 0px 8px rgb(0 0 0 / 15%)
		transition: background-color 0.2s ease-in-out
		transform: translate(0px,50%)
		cursor: pointer
		&.swiper-button-disabled
			display: none
		&[aria-disabled="false"]
			display: flex

		@media (any-hover: hover)
			&:hover
				background-color: $color-primary-dark
				color: $color-white
		
		@media (max-width: $clm-container)
			top: auto
			bottom: 0
		@media (max-width: $media-mobile-max)
			display: none
			&[aria-disabled="false"]
				display: none
		// .see-more__slider-btn--prev
		&--prev
			left: -40px
			transform: rotate(-180deg)
			@media (max-width: $clm-container)
				left: 50%
				transform: rotate(-180deg) translate(65px,0px)

		// .see-more__slider-btn--next
		&--next
			right: -40px
			@media (max-width: $clm-container)
				right: 50%
				transform: translate(65px,0px)


		

			

.filter-see-more
	padding-bottom: 10px
	margin-right: -15px
	margin-bottom: -10px
	margin-left: -20px
	overflow-x: auto

	// .filter-see-more__wrapper
	&__wrapper
		display: flex
		margin: 0 15px
	
	// .filter-see-more__item
	&__item
		display: flex
		align-items: center
		justify-content: center
		flex-shrink: 0
		min-height: 30px
		padding: 5px 15px
		border-radius: $border-radius
		margin: 0 5px
		font-size: 14px
		color: $color-gray
		background-color: $color-white
		transition: all 0.2s ease 0s
		cursor: pointer
		@media (any-hover: hover)
			&:hover
				color: $color-white
				background-color: $color-primary
		&:focus
			color: $color-white
			background-color: $color-primary
		
		&.active
			color: $color-white
			background-color: $color-primary
		
