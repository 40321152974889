.tb
	height: 60px
	*
		box-sizing: border-box
	@media (min-width: $media-mobile-min)
		display: none

	&.pmp-buy-fixed
		height: 140px

	// .tb__body
	&__body
		position: fixed
		bottom: 0
		left: 0
		width: 100%
		z-index: 1000001
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2)
		font-family: $font-main

	// .tb__list
	&__list
		display: flex
		justify-content: center
		padding-bottom: 10px
		background-color: $color-white
	// .tb__item
	&__item
		flex: 0 1 20%
		&.active
			.tb__icon
				svg path
					fill: $color-primary
			.tb__text
				color: $color-primary
		&:nth-child(2)
			&.active
				.tb__icon
					svg path
						stroke: $color-primary
						fill: transparent
		&:nth-child(5)
			&.active
				.tb__icon
					svg rect
						fill: $color-primary

	// .tb__btn
	&__btn
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		width: 100%
		height: 50px
		padding: 5px

	// .tb__icon
	&__icon
		position: relative
	// .tb__count
	&__count
		position: absolute
		top: -4px
		right: -8px
		display: flex
		justify-content: center
		align-items: center
		width: 19px
		height: 19px
		border-radius: 50%
		border: 2px solid $color-white
		background-color: $color-primary
		font-family: $font-rubik
		font-size: 11px
		font-weight: 500
		line-height: 1
		color: $color-white
	// .tb__text
	&__text
		font-size: 12px
		font-weight: 600
		line-height: 1
		color: $color-black

@media (max-width: $media-mobile-max)
	.tb-modal-phone
		&.show
			background-color: rgba(0, 0, 0, 0.3)
		&.modal.fade .modal-dialog
			transform: translate(0, 0)
		.modal-dialog
			max-width: none
			width: 100%
			margin: 0
			position: absolute
			bottom: 60px
			left: 0
		.modal-content
			overflow: auto
			height: calc(100% - 110px)
