.mf
	font-family: $font-main
	*
		box-sizing: border-box
	img
		vertical-align: top
	@media (min-width: $media-mobile-min)
		display: none

	// .mf__body
	&__body
		padding: 10px 0 20px
		.section-title
			margin-bottom: 15px

.mf-section
	&:not(:last-child)
		margin: 0px 0px 20px 0px

	// .mf-section__title
	&__title
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 10px
		font-size: 14px
		font-weight: 500
		line-height: 120%
		color: $color-gray
		@media (min-width: $media-mobile-min)
			font-weight: 400
			margin-bottom: 8px

.mf-mark
	display: inline-flex
	justify-content: center
	align-items: center
	box-sizing: border-box
	flex: 0 0 20px
	width: 20px
	height: 20px
	border: 1px solid $color-primary-dark
	border-radius: 50%
	margin-left: 10px
	margin-right: 18px
	font-size: 14px
	font-weight: 600
	line-height: 1
	vertical-align: middle
	color: $color-primary-dark
	&.js-ppzPopupUrl
		cursor: pointer

.mf-spoiler
	&.active
		.mf-spoiler__icon svg
			transform: scale(1 , -1)

	// .mf-spoiler__heading
	&__heading
		display: flex
		align-items: center
		justify-content: space-between
		height: 50px
		padding: 10px 15px
		background: $color-gray-bg
		box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15)
		border-radius: 4px
		@media (min-width: $media-mobile-min)
			box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
			border-radius: 6px
			border: 1px solid $color-gray-blue
			transition: background-color .2s
			&:hover,
			&:focus
				background-color: $color-gray-blue
				cursor: pointer
			&.active
				position: relative
				&::after
					content: ''
					position: absolute
					left: 0
					top: 0
					bottom: 0
					width: 6px
					border-radius: 6px 0 0 6px
					background-color: $color-primary-dark
					z-index: 1

	// .mf-spoiler__title
	&__title
		padding-right: 10px
		font-weight: 500
		font-size: 15px
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		color: $color-black
		@media (min-width: $media-mobile-min)
			font-weight: 400
			&.active
				font-weight: 500
	// .mf-spoiler__title-list
	&__title-list
		padding-right: 10px
		font-weight: 500
		font-size: 15px
		line-height: 120%
		color: $color-black
		@media (min-width: $media-mobile-min)
			font-weight: 400

	// .mf-spoiler__icon
	&__icon
		flex: 0 0 26px
		display: flex
		align-items: center
		justify-content: center
		width: 26px
		height: 26px
		border-radius: 50%
		background-color: $color-primary-dark
		svg
			transition: transform 0.3s ease 0s
	// .mf-spoiler__icon-list
	&__icon-list
		flex: 0 0 20px
		width: 20px
		height: 20px
		border-radius: 50%
		border: 1px solid $color-gray

	// .mf-spoiler__block-list
	&__block-list
		padding-top: 20px
		@media (min-width: $media-mobile-min)
			padding-top: 10px
		&.checked
			.mf-spoiler__item
				flex-direction: row-reverse
				justify-content: flex-end
				align-items: flex-start
			.mf-spoiler__title
				padding-right: 0
			.mf-spoiler__title-list
				padding-right: 0
			.mf-spoiler__icon-list
				margin-right: 10px
				border-radius: 0

	// .mf-spoiler__list
	&__list
		padding: 0 15px
		border-radius: 4px
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15)
		background: #F8FAFC
		@media (min-width: $media-mobile-min)
			box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
			border-radius: 6px
			border: 1px solid $color-gray-blue
			padding: 0
			max-height: 400px
			overflow-y: auto
			scrollbar-color: #C1C1C1 #F8FAFC /* «цвет ползунка» «цвет полосы скроллбара» */
			scrollbar-width: thin  /* толщина */
			&::-webkit-scrollbar
				box-sizing: border-box
				width: 15px
				border: 1px solid $color-gray-blue
				border-right: none
				border-radius: 6px
			&::-webkit-scrollbar-thumb
				box-sizing: border-box
				background: #C1C1C1
				border-radius: 10px
				border: 5px solid transparent
				background-clip: content-box

	// .mf-spoiler__item
	&__item
		display: flex
		align-items: center
		justify-content: space-between
		padding: 15px 0
		@media (min-width: $media-mobile-min)
			padding: 15px
			transition: background-color .2s
			position: relative
			&:hover,
			&:focus
				background-color: $color-gray-blue
				cursor: pointer
		&:not(:last-child)
			border-bottom: 1px solid rgba(101, 102, 104, 0.08)
			@media (min-width: $media-mobile-min)
				border: none
				&::after
					content: ''
					position: absolute
					bottom: 0
					left: 15px
					right: 15px
					height: 1px
					background-color: $color-gray-blue
		&.active
			.mf-spoiler__icon-list
				border: none
				background: url('/img/base/mobile-filter/checked.svg') no-repeat center / 12px 10px $color-primary-dark

		&.disabled
			cursor: auto !important
			&:hover, &:focus
				background-color: unset
			.mf-spoiler__title-list
				cursor: auto
				opacity: 0.4
			.mf-spoiler__icon-list
				opacity: 0.4

.mf-check-line
	display: flex
	justify-content: space-between
	padding: 20px 0
	border-top: 1px solid $color-gray-bg
	border-bottom: 1px solid $color-gray-bg
	@media (min-width: $media-mobile-min)
		padding-right: 16px

	&.active
		.mf-check-line__icon
			border: none
			background: url('/img/base/mobile-filter/checked.svg') no-repeat center / 12px 10px $color-primary-dark

	// .mf-check-line__text
	&__text
		padding-right: 10px
		margin-top: 4px
		font-weight: 500
		font-size: 15px
		line-height: 120%

	// .mf-check-line__icon
	&__icon
		flex: 0 0 26px
		width: 26px
		height: 26px
		border-radius: 50%
		border: 1px solid $color-gray
		@media (min-width: $media-mobile-min)
			cursor: pointer
.mf-range
	// .mf-range__block
	&__block
		// min-height: 55px
		border-radius: 4px
		box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15)
		background: #F8FAFC
		@media (min-width: $media-mobile-min)
			box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
			border-radius: 6px
			border: 1px solid $color-gray-blue

	// .mf-range__row
	&__row
		display: flex
		align-items: center
		justify-content: space-between
		padding: 15px 15px

	// .mf-range__price
	&__price
		flex: 0 0 calc(50% - 14px)
		font-weight: 500
		font-size: 15px
		&.to
			text-align: right

	// .mf-range__icon
	&__icon
		width: 15px
		height: 2px
		background-color: $color-gray

	// .mf-range__line
	&__line

.from

.to

.mf-reboot
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	min-height: 40px
	padding: 10px 15px
	border-radius: 4px
	background-color: $color-gray-bg
	// .mf-reboot__icon
	&__icon
		margin-right: 8px

	// .mf-reboot__text
	&__text
		font-family: $font-main
		font-size: 14px
		font-weight: 500
		color: $color-gray

.mf-more-param
	display: flex
	align-items: center
	justify-content: center
	color: $color-primary-dark
	&.active
		.mf-more-param__more
			display: none
		.mf-more-param__less
			display: block
		.mf-more-param__icon
			transform: scale(1, -1)

	// .mf-more-param__text
	&__text
		margin-right: 8px
		font-weight: 500
		font-size: 15px
		color: $color-primary-dark
		cursor: pointer

	// .mf-more-param__more
	&__more

	// .mf-more-param__less
	&__less
		display: none

	// .mf-more-param__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		transition: all 0.3s ease 0s

.mf-btn
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	min-height: 50px
	border-radius: 100px
	background-color: $color-primary
	transition: background-color .2s
	// .mf-btn___text
	&___text
		font-family: $font-rubik
		font-weight: 500
		font-size: 15px
		color: $color-white

	@media (min-width: $media-mobile-min)
		&:not(.disabled):not(.loaded):hover,
		&:not(.disabled):not(.loaded):focus
			outline: none
			cursor: pointer
			background-color: $color-primary-dark
