@import '../modules/_variables'

.clm-size
	font-size: 14px
	line-height: 120%
	color: $color-gray
	
	& .warning
		color: $color-error
	
	.ppl-content &
		margin-top: 20px
	
	&__row
		display: flex
		flex-direction: column
		padding: 20px 0
		border-bottom: 2px solid $color-gray-bg
		&:first-child
			border-top: 2px solid $color-gray-bg
			@media (min-width: $media-mobile-min)
				border-top: none
				padding-top: 0
	
	&__text
		font-size: 15px
		line-height: 140%
		color: $color-main
		&:not(:last-child)
			margin-bottom: 20px
		@media (max-width: $media-mobile-max)
			font-size: 14px
	
	&__link
		font-size: 19px
		color: $color-link
		&:not(:last-child)
			margin-bottom: 5px
	
	&__captcha
		margin-bottom: 25px
	
	&__rights
		margin-top: 15px
		font-size: 13px
		line-height: 130%
	
	&__color-rights
		color: $color-link
		transition: border-bottom 0.2s ease 0s
		@media (any-hover: hover)
			&:hover
				border-bottom: 1px solid $color-link
	
	&__form
		padding: 25px 0 0
	
	&__user
		padding-bottom: 25px
		border-bottom: 2px solid $color-gray-bg
		
	&__mattress
		padding: 25px 0
		@media (max-width: $media-mobile-max)
			padding: 20px 0
		&-top
			margin-bottom: 20px
			display: flex
			align-items: center
		&-img
			display: block
			width: 196px
			height: auto
			object-fit: cover
			flex-shrink: 0
			margin-right: 20px
			border-radius: 6px
			padding-top: 4px
			overflow: hidden
			@media (max-width: $media-mobile-max)
				width: 160px
				
		&-title
			font-weight: 400
			font-size: 15px
			margin-bottom: 8px
			color: $color-black
		&-char
			margin-bottom: 10px
			font-size: 14px
			font-weight: 400
			&:last-child
				margin-bottom: 0
			&-title
				margin-bottom: 2px
				color: $color-light-gray

	&__wrap-form
		& .ppz-exit__inner
			display: block
	
	&__items-list
		display: grid
		grid-template-columns: repeat(2, 1fr)
		row-gap: 20px
		column-gap: 20px
		@media (max-width: $media-mobile-max)
			row-gap: 10px
			column-gap: 10px
			&.clm-size__items-list--mob100
				display: block
				row-gap: unset
				column-gap: unset
				
				& .clm-size__item
					margin-bottom: 20px
					&:last-child
						margin-bottom: 0
			
	
	&__item
		&.active
			.clm-size__icon
				opacity: 1
		&.error
			.clm-size__warning
				opacity: 1
	
	&__label
		& .clm-size__title
			cursor: pointer
	
	&__title
		display: block
		margin-bottom: 6px
	
	&__input-block
		position: relative
	
	&__icon
		position: absolute
		top: 50%
		right: 20px
		display: flex
		align-items: center
		justify-content: center
		width: 16px
		height: 16px
		transform: translate(0px, -50%)
		opacity: 0
		& img
			max-width: 100%
			max-height: 100%
			object-fit: contain
	
	&__warning
		position: absolute
		top: calc(100% + 4px)
		left: 0
		font-size: 12px
		color: $color-error
		opacity: 0
	
	&__success
		display: none
		
		&-icon
			width: 200px
			height: 200px
			border-radius: 50%
			background-color: #F8FAFC
			margin: 0 auto 20px
			display: flex
			align-items: center
			justify-content: center
			color: $color-primary
			
		&-title
			text-align: center
			font-weight: 600
			font-size: 23px
			color: $color-black
			margin-bottom: 10px

		&-text
			font-size: 14px
			margin-bottom: 20px
			text-align: center
			color: $color-light-gray
	
	&__error
		display: none
		
		&-title
			text-align: center
			font-weight: 600
			font-size: 23px
			color: $color-error
			margin-bottom: 10px
		&-text
			font-size: 14px
			margin-bottom: 20px
			text-align: center
			color: $color-light-gray
		
	&__exit
		display: none