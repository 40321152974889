@import '../modules/_variables'
.ppl
	font-family: $font-rubik

	@media (min-width: $media-mobile-min)
		// display: none
	*
		box-sizing: border-box

	img
		vertical-align: top
	// .ppl__wrapper
	&__wrapper

	// .ppl__body
	&__body
		background: $color-white
		box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2)
		border-radius: 5px 5px 0px 0px
		overflow: hidden

.ppl-img
	width: 100%
	height: 270px
	margin-bottom: 10px
	position: relative
	img:not(.no-style-img)
		width: 100%
		height: 100%
		object-fit: cover
	&__close
		position: absolute
		z-index: 1
		top: 20px
		right: 20px
.ppl-container
	padding: 0 25px 50px
	@media (max-width: $media-mobile-max)
		padding: 0 20px 20px
.ppl-head
	display: flex
	align-items: center
	justify-content: space-between
	padding-top: 50px
	margin-bottom: 10px
	@media (max-width: $media-mobile-max)
		padding-top: 20px
		.clm-only-mobile
			display: flex
	@media (min-width: $media-mobile-min)
		padding-bottom: 20px
		border-bottom: 2px solid $color-gray-blue-light
		margin-bottom: 20px

	// .ppl-head__back
	&__back
		display: flex
		align-items: center
		flex: 0 0 70px
		cursor: pointer

	// .ppl-head__img
	&__img
		margin-right: 10px
		margin-bottom: 2px

	// .ppl-head__text
	&__text
		font-size: 15px
		line-height: 1
		color: $color-gray

	// .ppl-head__title
	&__title
		padding: 0 5px
		font-size: 23px
		font-weight: 500
		color: $color-black
		@media (max-width: $media-mobile-max)
			font-size: 17px
		@media (min-width: $media-mobile-min)
			padding-left: 0

	// .ppl-head__forward
	&__forward
		flex: 0 0 70px

	// .ppl-head__desc-close
	&__desc-close
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 50px
		width: 50px
		height: 50px
		border: 1px solid $color-gray-blue
		border-radius: $border-high-radius
		background-color: $color-gray-bg
		cursor: pointer
		@media (any-hover: hover)
			&:hover
				background-color: $color-gray-blue
		@media (min-width: $media-mobile-min)
			&.clm-only-desctop
				display: flex



.ppl-title
	margin-top: 20px
	margin-bottom: 10px
	font-size: 15px
	font-weight: 500
	line-height: 120%
	color: $color-black
	@media (min-width: $media-mobile-min)
		font-size: 19px

.ppl-text
	margin-top: 10px
	margin-bottom: 10px
	font-size: 14px
	color: $color-gray
	@media (min-width: $media-mobile-min)
		font-size: 15px
	p
		font-family: inherit
		font-size: inherit
		margin: 10px 0

	ul
		margin: 10px 0
		li
			display: flex
			align-items: center
			&::before
				content: ''
				display: inline-block
				flex: 0 0 14px
				width: 14px
				height: 14px
				border-radius: 50%
				margin-right: 8px
				background-color: $color-primary-dark
			&:not(:last-child)
				margin-bottom: 10px

.ppl-price
	margin-top: 15px
	margin-bottom: 10px
	display: flex
	align-items: flex-end
	font-family: $font-rubik
	// .ppl-price__current
	&__current
		font-size: 19px
		font-weight: 500
		color: $color-black

	// .ppl-price__old
	&__old
		margin-left: 8px
		font-size: 15px
		color: $color-gray
		text-decoration: line-through

.ppl-bottom
	display: flex
	align-items: center
	margin-top: 20px
	// .ppl-bottom__back
	&__back
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 50px
		width: 50px
		border-radius: 50%
		height: 50px
		background-color: $color-gray-bg
		margin-right: 15px
		@media (min-width: $media-mobile-min)
			border: 1px solid $color-gray-blue
			&:hover
				cursor: pointer
				background-color: $color-gray-blue

	// .ppl-bottom__btn
	&__btn
		display: flex
		align-items: center
		justify-content: center
		flex: 1 1 auto
		width: 100%
		min-height: 50px
		border-radius: 100px
		background-color: $color-primary
		font-family: $font-rubik
		font-size: 15px
		font-weight: 500
		color: $color-white
		cursor: pointer

.ppl-content
	&--cloth
		& .ppl-title
			display: none
	// .ppl-content--description
	&--description
		font-family: $font-rubik
		color: $color-gray

		h1
			display: block
			font-size: 2em
			margin-top: 0.67em
			margin-bottom: 0.67em
			margin-left: 0
			margin-right: 0
			font-weight: 700
			line-height: normal
			color: $color-main
			text-transform: unset

		h2
			display: block
			font-size: 1.5em
			margin-top: 0.83em
			margin-bottom: 0.83em
			margin-left: 0
			margin-right: 0
			font-weight: 700
			line-height: normal
			color: $color-main
			text-transform: unset

		h3
			display: block
			font-size: 1.17em
			margin-top: 1em
			margin-bottom: 1em
			margin-left: 0
			margin-right: 0
			font-weight: 700
			line-height: normal
			color: $color-main
			text-transform: unset

		h4
			display: block
			margin-top: 1.33em
			margin-bottom: 1.33em
			margin-left: 0
			margin-right: 0
			font-weight: 700
			line-height: normal
			color: $color-main
			text-transform: unset

		h5
			display: block
			font-size: .83em
			margin-top: 1.67em
			margin-bottom: 1.67em
			margin-left: 0
			margin-right: 0
			font-weight: 700
			line-height: normal
			color: $color-main
			text-transform: unset

		h6
			display: block
			font-size: .67em
			margin-top: 2.33em
			margin-bottom: 2.33em
			margin-left: 0
			margin-right: 0
			font-weight: 700
			line-height: normal
			color: $color-main
			text-transform: unset

		p
			display: block
			margin-top: 1em
			margin-bottom: 1em
			font-family: inherit
			color: inherit

		ul, ol
			margin: 1em 0
			padding: 0
			list-style-position: inside
			li
				margin: 0.5em 0
				color: $color-gray
				list-style-type: none
		
		ul
			li
				position: relative
				padding-left: 20px
				&::before
					content: ''
					width: 10px
					height: 10px
					border-radius: 50%
					background-color: $color-primary-dark
					position: absolute
					top: 4px
					left: 0
				&:last-child
					margin-bottom: 0

		ol
			counter-reset: num
			li
				position: relative
				padding-left: 20px
				counter-increment: num
				&::before
					position: absolute
					content: counter(num)
					top: 0
					left: 0
					color: $color-primary-dark

			
		table
			display: table
			width: 100%
			border-collapse: collapse
			box-sizing: border-box
			text-indent: initial
			border-color: #d3d8dc

		tbody
			display: table-row-group
			vertical-align: middle
			border-color: inherit


		th, td
			width: auto
			padding: 0.8em 0.6em
			border: 1px solid $color-gray-blue
			background-color: $color-gray-blue-light
			font-size: 1em
			color: $color-gray

		td
			vertical-align: top
			text-align: left

		img
			max-width: 100%
			width: auto
			height: auto
			margin-top: 15px
			margin-bottom: 15px

		a
			text-decoration: none
			color: inherit
			position: relative
			border-bottom: 1px solid transparent
			color: $color-primary
			transition: color 0.2s ease 0s
			&:focus
				color: $color-primary-dark
			@media (any-hover: hover)
				&:hover
					color: $color-primary-dark

		& :last-child
			margin-bottom: 0

		& :first-child
			margin-top: 0


.ppl-bottom-exit
	margin-top: 20px
.ppl-spec
	margin-top: 20px
	margin-bottom: 10px
	// .ppl-spec__list
	&__list

	// .ppl-spec__item
	&__item
		display: flex
		align-items: flex-end
		&:not(:last-child)
			margin-bottom: 15px

	// .ppl-spec__parameter
	&__parameter
		position: relative
		flex: 1 1 auto
		padding-right: 30px
		font-size: 14px
		font-weight: 400
		color: $color-gray
		&::before
			content: ''
			position: absolute
			left: 0
			bottom: 0.2em
			display: inline-block
			width: 100%
			height: 100%
			border-bottom: 1px dashed $color-light-gray
		span
			position: relative
			padding-right: 3px
			background-color: $color-white

	// .ppl-spec__value
	&__value
		padding-left: 3px
		font-family: $font-rubik
		font-size: 14px
		color: $color-main
		text-align: right
		overflow-wrap: anywhere

		&.is-link
			text-decoration: underline
			color: $color-primary

	// .ppl-spec__link
	&__link
		color: inherit

.ppl-cert
	margin-top: 20px
	.swiper
		padding-bottom: 20px
	.swiper-wrapper
		min-height: 200px
	.swiper-slide
		height: auto
	.swiper-pagination
		bottom: 0px
		.swiper-pagination-bullet
			flex: 0 1 30px
			height: 5px

	&__title
		margin-bottom: 10px
		font-size: 15px
		font-weight: 500
		color: $color-black

	// .ppl-cert__description
	&__description
		margin-bottom: 10px
		font-size: 14px
		color: $color-gray

	// .ppl-cert__slider
	&__slider

	// .ppl-cert__img
	&__img
		width: 100%
		height: 460px
		img
			width: 100%
			height: 100%
			object-fit: contain

	// .ppl-cert__link
	&__link

.ppl-doc
	margin-top: 20px
	margin-bottom: 10px
	// .ppl-doc__list
	&__list

	// .ppl-doc__item
	&__item
		display: flex
		align-items: center
		&:not(:last-child)
			margin-bottom: 40px

	// .ppl-doc__img
	&__img
		margin-right: 10px

	// .ppl-doc__text
	&__text
		font-size: 15px

	// .ppl-doc__title
	&__title
		margin-bottom: 3px
		font-weight: 500
		color: $color-black

	// .ppl-doc__link
	&__link
		text-decoration: underline
		color: $color-primary-dark

.ppl-review
	margin-top: 20px
	// .ppl-review__title
	&__title
		margin-bottom: 10px
		font-size: 15px
		font-weight: 500
		color: $color-black

	// .ppl-review__mark
	&__mark
		margin-top: 10px
		margin-bottom: 20px
		font-size: 14px
		color: $color-gray

	// .ppl-review__subtitle
	&__subtitle
		margin-top: 20px
		margin-bottom: 10px
		font-size: 15px
		font-weight: 500
		text-align: center
		color: $color-black

	// .ppl-review__description
	&__description
		margin-top: 10px
		margin-bottom: 10px
		font-size: 14px
		text-align: center
		color: $color-gray

	// .ppl-review__form
	&__form
		margin-top: 15px
		margin-bottom: 10px

	// .ppl-review__line
	&__line
		&:not(:last-child)
			margin-bottom: 15px

	// .ppl-review__input
	&__input
		width: 100%
		height: 50px
		padding: 0 15px
		border: none
		border-radius: 4px
		outline: none
		background-color: $color-gray-bg
		font-size: 14px
		color: $color-gray

	// .ppl-review__txtarea
	&__txtarea
		width: 100%
		height: 100px
		resize: vertical
		padding: 15px
		border: none
		border-radius: 4px
		outline: none
		background-color: $color-gray-bg
		font-size: 14px
		color: $color-gray
	// .ppl-review__btn
	&__btn
		margin-top: 20px

.ppl-rating
	margin-top: 20px
	margin-bottom: 10px
	text-align: center
	&.active
		.empty-icons
			opacity: 0
		.empty-icons-active
			opacity: 1
		.fill-icons
			opacity: 1
	// .ppl-rating__icons
	&__icons
		display: inline-block
		position: relative

	// .ppl-rating__row
	&__row
		display: flex

		// .empty-icons
		&.empty-icons

		// .empty-icons-active
		&.empty-icons-active
			// opacity: 0
			overflow: hidden
			position: absolute
			top: 0
			left: 0
			width: 100%
			// width: 100%
			// height: calc(100% - 2px)
			// top: 2px
			// left: 2px
			// .pmp-rating__img
			// 	&:not(:last-child)
			// 		margin-right: 6px

		// .fill-icons
		&.fill-icons
			// opacity: 0
			overflow: hidden
			position: absolute
			top: 0
			left: 0
			width: 100%
			// width: 100%
			// height: calc(100% - 2px)
			// top: 2px
			// left: 2px
			// .pmp-rating__img
			// 	&:not(:last-child)
			// 		margin-right: 6px

	// .ppl-rating__img
	&__img
		flex: 0 0 28px
		width: 28px
		height: 28px
		&:not(:last-child)
			margin-right: 10px
		img
			width: 100%
			height: 100%
			object-fit: contain

	// .ppl-rating__review
	&__review
		margin-top: 10px
		font-size: 14px
		text-align: center
		color: $color-gray

.empty-icons

.empty-icons-active

.fill-icons

.ppl-captcha
	margin-top: 15px
	margin-bottom: 10px
	text-align: center

.ppl-options
	margin-top: 20px
	margin-bottom: 10px
	.pmp-spoiler-head__icon
		transform: rotate(-90deg)
	// .ppl-options__list
	&__list

	// .ppl-options__item
	&__item
		&:not(:last-child)
			margin-bottom: 20px

	// .ppl-options__title
	&__title
		margin-bottom: 10px
		font-size: 14px
		font-weight: 400
		color: $color-gray

		.font-rubik
			font-weight: 500
			color: $color-black

.ppl-more

	// .ppl-more__list
	&__list

	// .ppl-more__item
	&__item
		padding-top: 20px
		margin-top: 20px
		border-top: 1px solid $color-gray-bg

	// .ppl-more__head
	&__head
		display: flex

	// .ppl-more__img
	&__img
		flex: 0 1 35%
		height: 90px

		img
			width: 100%
			height: 100%
			object-fit: cover

	// .ppl-more__info
	&__info
		flex: 0 1 65%
		padding: 10px 0 10px 10px

	// .ppl-more__title
	&__title
		font-size: 15px
		font-weight: 500
		line-height: 1

	// .ppl-more__description
	&__description
		margin-top: 5px
		color: $color-black

	// .ppl-more__subtitle
	&__subtitle
		font-size: 12px
		line-height: 1
		color: $color-gray
		margin-top: 5px

	// .ppl-more__spoiler
	&__spoiler
		.pmp-spoiler
			margin-bottom: 10px

.ppl-more-price
	margin-top: 15px
	margin-bottom: 10px
	display: flex
	align-items: flex-end
	// .ppl-more-price__current
	&__current
		font-size: 19px
		font-weight: 500
		color: $color-black

	// .ppl-more-price__old
	&__old
		margin-left: 8px
		font-size: 15px
		text-decoration: line-through
		color: $color-gray

.ppl-more-buy
	display: flex
	margin-top: 15px
	margin-bottom: 10px
	&.active
		.ppl-more-buy__btn
			background-color: $color-primary
			border: none
			color: $color-white
		.ppl-more-buy__text-1
			display: none
		.ppl-more-buy__text-2
			display: block

	// .ppl-more-buy__btn
	&__btn
		display: flex
		align-items: center
		justify-content: center
		flex: 1 1 auto
		min-height: 50px
		padding: 10px 15px
		border: 1px solid $color-primary
		border-radius: 100px
		background-color: $color-white
		font-size: 15px
		font-weight: 500
		line-height: 1
		color: $color-primary

	// .ppl-more-buy__text-1
	&__text-1

	// .ppl-more-buy__text-2
	&__text-2
		display: none

	// .ppl-more-buy__ctrls
	&__ctrls
		display: flex
		align-items: center
		flex: 0 0 150px
		margin-left: 15px

	// .ppl-more-buy__cnt
	&__cnt
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 50px
		width: 50px
		height: 50px
		border-radius: 50%
		background-color: $color-gray-bg

		svg rect
			fill: $color-gray

		&.active svg rect
			fill: $color-primary

	// .ppl-more-buy__count
	&__count
		flex: 0 0 50px
		text-align: center
		font-size: 23px
		color: $color-black

.ppl-color
	margin-top: 20px
	line-height: 1
	// .ppl-color__ctrl
	&__ctrl
		display: flex
		align-items: center
		width: 100%
		box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15)
		border-radius: 100px
		background: $color-gray-bg
		height: 44px
		padding: 0 5px
		font-weight: 500
		font-size: 15px
		@media (min-width: $media-mobile-min)
			height: 50px
			box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
			border: 1px solid $color-gray-blue

		&.active
			.ppl-color__filter
				&.one
					background-color: transparent
					color: $color-black
				&.two
					background-color: $color-primary
					color: $color-white

	// .ppl-color__filter
	&__filter
		display: flex
		align-items: center
		justify-content: center
		flex: 0 1 50%
		height: 34px
		border-radius: 100px
		text-align: center
		@media (min-width: $media-mobile-min)
			height: 40px
			&:hover
				cursor: pointer

		&.one
			background-color: $color-primary
			color: $color-white

		&.two
			color: $color-black

.ppl-color-spoiler
	margin-top: 20px
	@media (min-width: $media-mobile-min)
		margin-top: 30px
		&:first-child
			margin-top: 15px
	&.active
		.ppl-clr-splr__icon
			img
				transform: scale(1, -1)

.ppl-clr-splr
	display: flex
	align-items: center
	justify-content: space-between
	min-height: 50px
	padding: 10px 15px
	border-radius: 4px
	background: $color-gray-bg
	box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.20)
	font-size: 15px
	font-weight: 500
	color: $color-black
	@media (min-width: $media-mobile-min)
		box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
		border: 1px solid $color-gray-blue
		padding: 9px 19px
		border-radius: 6px


	// .ppl-clr-splr__title
	&__title
		margin-right: 10px

	// .ppl-clr-splr__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 26px
		width: 26px
		height: 26px
		border-radius: 50%
		background-color: $color-primary-dark
		img
			transition: transform 0.3s ease 0s

.green
	color: $color-primary

.ppl-color-select
	padding-top: 15px
	@media (min-width: $media-mobile-min)
		padding-top: 20px
		&:first-child
			padding-top: 15px
	// .ppl-color-select__list
	&__list
		&:not(:last-child)
			margin-bottom: 20px

	// .ppl-color-select__item
	&__item
		&:not(:last-child)
			margin-bottom: 20px

	// .ppl-color-select__title
	&__title
		margin-bottom: 15px
		font-size: 15px

	// .ppl-color-select__sublist
	&__sublist
		display: grid
		grid-template-columns: repeat(auto-fit, 54px)
		grid-auto-rows: 54px
		grid-gap: 12px

	// .ppl-color-select__subitem
	&__subitem
		&.active
			.ppl-color-select__img
				flex: 0 0 40px
				width: 40px
				height: 40px
			.ppl-color-select__color
				border-color: $color-primary

	// .ppl-color-select__color
	&__color
		display: flex
		align-items: center
		justify-content: center
		width: 54px
		height: 54px
		border: 2px solid transparent
		border-radius: 50%

	// .ppl-color-select__img
	&__img
		flex: 0 0 50px
		width: 50px
		height: 50px
		border-radius: 50%
		overflow: hidden

		@media (min-width: $media-mobile-min)
			transition: box-shadow .2s
			box-shadow: 0 5px 15px rgba(49, 49, 49, 0.1)

			&:hover
				cursor: pointer
				box-shadow: 0 5px 20px rgba(49, 49, 49, 0.2)

		img
			width: 100%
			height: 100%
			object-fit: cover

.ppl-color-price
	margin-top: 20px
	// .ppl-color-price__list
	&__list

	// .ppl-color-price__item
	&__item
		&:not(:last-child)
			margin-bottom: 40px

	// .ppl-color-price__head
	&__head
		margin-bottom: 15px
		font-size: 17px
		font-weight: 500
		color: $color-black

	// .ppl-color-price__sublist
	&__sublist

	// .ppl-color-price__subitem
	&__subitem
		&:not(:last-child)
			margin-bottom: 15px
			@media (min-width: $media-mobile-min)
				margin-bottom: 20px

	// .ppl-color-select__color
	&__color

	// .ppl-color-price__title
	&__title
		margin-bottom: 10px
		font-size: 15px
		color: $color-black

.ppl-clr-prs
	// .ppl-clr-prs__list
	&__list
		display: grid
		grid-template-columns: repeat(auto-fit, 72px)
		grid-gap: 20px

	// .ppl-clr-prs__item
	&__item
		@media (min-width: $media-mobile-min)
			cursor: pointer
			&:hover .ppl-clr-prs__img
				box-shadow: 0 5px 20px rgba(49, 49, 49, 0.2)
		&.active
			.ppl-clr-prs__img
				flex: 0 0 50px
				width: 50px
				height: 50px
			.ppl-clr-prs__color
				border-color: $color-primary

	// .ppl-clr-prs__color
	&__color
		display: flex
		align-items: center
		justify-content: center
		width: 64px
		height: 64px
		border: 2px solid transparent
		border-radius: 50%

	// .ppl-clr-prs__img
	&__img
		flex: 0 0 60px
		width: 60px
		height: 60px
		border-radius: 50%
		overflow: hidden
		@media (min-width: $media-mobile-min)
			transition: box-shadow .2s
		img
			width: 100%
			height: 100%
			object-fit: cover

	// .ppl-clr-prs__title
	&__title
		margin-top: 10px
		font-size: 12px
		font-weight: 500
		color: $color-black

.ppl-town
	&__section
		border-top: 1px solid $color-gray-blue-light
		padding: 20px 0
		&:first-child
			border-top: none
			@media (min-width: $media-mobile-min)
				padding-top: 0

		&-title
			font-weight: 500
			font-size: 15px
			margin-bottom: 10px
		&-list
			font-size: 14px
			& > *
				margin-bottom: 10px
				&:last-child
					margin-bottom: 0

	&__link
		text-decoration: none
		display: inline-block
		position: relative
		color: $color-link
		cursor: pointer
		border-bottom: 1px solid transparent
		&::after
			position: absolute
			left: 0
			right: 0
			content: ''
			height: 1px
			bottom: 0
			background-color: $color-link
			opacity: 0
			transition: opacity 0.2s ease 0s
		&:hover,
		&:focus
			&::after
				opacity: 1

	&__capital
		font-size: 19px
		margin-bottom: 10px
		&:last-child
			margin-bottom: 0


	&__search-err
		font-size: 14px
		color: $color-gray

	&__input
		position: relative

		&-input
			height: 50px
			border: 1px solid $color-lightest-gray
			color: $color-main
			font-size: 14px
			border-radius: 6px
			padding: 1px 50px 0 20px
			width: 100%
			transition: border-color .2s ease 0s
			&:hover,
			&:focus,
			&:not(:placeholder-shown)
				border-color: $color-main

		&-icon
			position: absolute
			right: 20px
			top: 16px

		&-hint
			margin-top: 10px
			font-size: 13px
			color: $color-gray
