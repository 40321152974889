
.basket-section
	padding: 50px 0
	@media (max-width: $media-mobile-max)
		padding: 5px 0

	&.empty
		padding: 50px 0
		font-family: $font-main
	*
		box-sizing: border-box

	// .basket-section__body
	&__body
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center

	// .basket-section__img
	&__img
		display: flex
		justify-content: center
		align-items: center
		width: 200px
		height: 200px
		margin-bottom: 20px
		border-radius: 50%
		background-color: $color-gray-bg
		img
			width: 100px
			height: 100px
			object-fit: contain

	// .basket-section__title
	&__title
		margin-bottom: 10px
		font-size: 23px
		font-weight: 700
		color: $color-black
		text-align: center

	// .basket-section__subtitle
	&__subtitle
		margin-bottom: 20px
		font-size: 14px
		font-weight: 500
		color: $color-gray
		text-align: center

	// .basket-section__btn
	&__btn
		@media (max-width: $media-mobile-max)
			width: 100%
		a
			display: flex
			justify-content: center
			align-items: center
			width: 100%
			min-height: 50px
			padding: 0 15px
			border: 1px solid $color-primary
			box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
			border-radius: 60px
			background: $color-primary
			font-size: 17px
			font-weight: 500
			color: $color-white
			@media (min-width: $media-mobile-min)
				width: auto
				min-width: 345px
