.breadcrumbs
	margin-top: 26px

	@media (max-width: $media-mobile-max)
		margin-top: 15px

	&__wrapper
		display: flex
		align-items: center
		flex-wrap: wrap

	&__item
		display: inline-flex
		align-items: center
		margin-bottom: 5px
		font-size: 14px
		a
			font-family: "Raleway", sans-serif
			font-size: 14px
			color: $color-light-gray
			transition: color 0.2s ease 0s
			&:hover
				color: $color-primary

			@media (max-width: $media-mobile-max)
				font-weight: 500
				font-size: 12px
				
				
		&:not(:last-child)
			&::after
				content: '/'
				margin-left: 15px
				margin-right: 15px
				font-weight: 700
				font-size: 17px
				color: $color-light-gray
				transition: color 0.2s ease 0s
				@media (max-width: $media-mobile-max)
					margin-left: 5px
					margin-right: 5px
					font-size: 15px
					
		&:last-child
			a
				color: $color-primary
