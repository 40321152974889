@import './modules/_variables'

.home-cards
	font-family: $font-rubik
	box-sizing: border-box
	font-size: 14px
	line-height: 1.3
	color: $color-main
	margin: 50px 0
	@media (max-width: $media-mobile-max)
		margin: 20px 0
		& .container
			padding: 0

	&__wrap
		@media (max-width: $media-mobile-max)
			margin-left: auto
			margin-right: auto
			position: relative
			overflow: hidden
			list-style: none
			padding: 0
			z-index: 1
			width: 100%
			height: 100%

	&__pag
		@media (min-width: $media-mobile-min)
			display: none !important
		&.swiper-pagination-bullets
			display: flex
			align-items: center
			justify-content: center
			margin-top: 20px
			& .swiper-pagination-bullet
				width: 35px
				height: 4px
				margin: 0 7px !important
				background: $color-lightest-gray
				border-radius: 10px
				opacity: 1
				&.swiper-pagination-bullet-active
					background: $color-primary-dark

	&__grid
		display: grid
		grid-template-columns: repeat(4, 1fr)
		column-gap: 20px
		row-gap: 20px
		grid-auto-rows: 450px
		@media (max-width: 1300px)
			grid-auto-rows: 400px
		@media (max-width: 1250px)
			grid-auto-rows: 350px
		@media (max-width: 1150px)
			grid-auto-rows: 300px
		@media (max-width: $media-mobile-max)
			display: flex
			row-gap: unset
			column-gap: unset
			position: relative
			width: 100%
			height: 100%
			z-index: 1
			transition-property: transform
			box-sizing: content-box

	&__card
		border-radius: 6px
		overflow: hidden
		position: relative
		text-decoration: none
		color: $color-white
		@media (max-width: $media-mobile-max)
			width: 200px
			height: 268px
			margin-left: 15px
			flex-shrink: 0
			&:last-child
				margin-right: 15px
		&:hover,
		&:focus
			& .home-cards__card-img
				transform: scale(1.05)

		&-achivs
			display: flex
			flex-wrap: wrap
			&-i
				font-weight: 500
				font-size: 14px
				padding: 8px 16px 7px
				border-radius: 6px
				background-color: $color-primary
				white-space: nowrap
				margin-right: 5px
				margin-bottom: 5px
				@media (max-width: $media-mobile-max)
					font-size: 10px
					padding: 5px 10px 4px
		&-gb
			position: absolute
			z-index: 1
			left: 0
			right: 0
			top: 0
			bottom: 0
			background: linear-gradient(360deg, #333333 0%, rgba(51, 51, 51, 0) 64%)

		&-img
			width: 100%
			height: 100%
			object-fit: cover
			object-position: center center
			transition: transform .3s ease 0s

		&-inner
			position: absolute
			z-index: 2
			left: 20px
			top: 20px
			right: 20px
			bottom: 20px
			display: flex
			flex-direction: column
			@media (max-width: $media-mobile-max)
				left: 10px
				top: 10px
				bottom: 10px
				right: 10px

		&-title
			font-size: 19px
			font-weight: 500
			margin-top: auto
			margin-bottom: 10px
			text-transform: uppercase
			@media (max-width: $media-mobile-max)
				font-size: 14px
				margin-bottom: 5px

		&-text
			font-size: 14px
			-webkit-line-clamp: 3
			display: -webkit-box
			-webkit-box-orient: vertical
			overflow: hidden
			@media (max-width: $media-mobile-max)
				font-size: 12px