
@import './modules/_variables'
/*========================================================================================================================================================*/
// * новый ограничивающий контейнер
.clm-container
	max-width: $clm-container
	padding-right: 15px
	padding-left: 15px
	margin: 0px auto

/*========================================================================================================================================================*/
// * глобальный класс для отображения/скрытия блока
.clm-only-mobile
	display: none
	@media (max-width: $media-mobile-max)
		display: block

.clm-only-desctop
	display: block
	@media (max-width: $media-mobile-max)
		display: none
/*========================================================================================================================================================*/
// * глобалтные стили input
.clm-input
	width: 100%
	height: 50px
	padding: 10px 45px 10px 20px
	border: 1px solid $color-gray-blue
	border-radius: $border-radius
	background-color: $color-gray-bg
	font-size: 15px
	color: $color-main
	&:focus
		box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2)
		&::placeholder
			opacity: 0
	&::placeholder
		color: $color-gray
	&.error
		border: 1px solid $color-error
/*========================================================================================================================================================*/
// * глобалтные стили ссылки с нижним подчеркиванием
.link-underline
	border-bottom: 1px solid transparent
	transition: all 0.2s ease 0s
	@media (any-hover: hover)
		&:hover
			border-bottom: 1px solid $color-link
/*========================================================================================================================================================*/
// * capthca капча
.clm-captcha
	margin-top: 8px
	font-size: 14px
	color: var(--color-error)
/*========================================================================================================================================================*/
// * кнопка
.global-btn
	display: flex
	align-items: center
	justify-content: center
	box-sizing: border-box
	width: 100%
	min-height: 50px
	padding: 5px 15px
	background-color: $color-white
	border-radius: 100px
	border: 1px solid $color-primary
	transition: all 0.2s ease 0s

	@media (any-hover: hover)
		&:hover
			border-color: $color-primary-dark
			.global-btn__text
				color: $color-primary-dark
			
	

	&.color
		border: none
		background-color: $color-primary
		.global-btn__text
			color: $color-white
		@media (any-hover: hover)
			&:hover
				background-color: $color-primary-dark
				.global-btn__text
					color: $color-white



	// .global-btn__img
	&__img
		display: flex
		align-items: center
		justify-content: center
		margin-right: 8px

	// .global-btn__text
	&__text
		font-family: $font-rubik
		font-weight: 500
		font-size: 15px
		line-height: 1
		color: $color-primary
		transition: color 0.2s ease 0s
/*========================================================================================================================================================*/
// * круглая кнопка с иконкой
.clm-interactive-btn
	display: flex
	align-items: center
	justify-content: center
	flex: 0 0 40px
	width: 40px
	height: 40px
	border-radius: 50%
	background-color: $color-primary-dark
	transition: background-color 0.2s ease 0s
	@media (any-hover: hover)
		&:hover
			background-color: $color-primary

	// .clm-interactive-btn__img
	&__img
		img
			width: 100%
			height: 100%
			object-fit: contain
			vertical-align: top
/*========================================================================================================================================================*/
// * toggle переключатель
.clm-toggle
	display: flex
	align-items: center
	cursor: pointer
	&.active
		.clm-toggle__icon
			background-color: $color-primary-dark
			border-color: $color-primary-dark
			&::before
				background-color: $color-white
				transform: translate(23px, 0px)
				
			
	
	// .clm-toggle__text
	&__text
		font-size: 15px
		margin-right: 20px

	// .clm-toggle__icon
	&__icon
		position: relative
		width: 50px
		height: 26px
		border: 1px solid $color-gray-blue
		border-radius: $border-high-radius
		margin-left: auto
		background: $color-gray-blue-light
		background-color: #fff
		transition: all 0.3s ease 0s
		&::before
			content: ''
			position: absolute
			z-index: 1
			top: 2px
			left: 3px
			width: 20px
			height: 20px
			flex: 0 0 20px
			border-radius: 50%
			transform: translate(0px,0px)
			background-color: $color-primary-dark
			transition: all 0.3s ease 0s
			
		
/*========================================================================================================================================================*/
// * ачивки на карточке товара в списке товаров

.clm-achieve
	position: absolute
	z-index: 10
	top: 0
	left: 0
	font-weight: 500
	font-size: 15px
	line-height: 100%
	color: $color-gray-bg
	pointer-events: none
	&--main
		left: 10px
		top: 10px
		max-width: calc(100% - 10px)
		font-family: $font-rubik
	&--main-mobile
		top: 10px
		max-width: calc( 100% - 105px )


	*
		box-sizing: border-box
	// .clm-achieve__list
	&__list
		display: flex
		align-items: center
		flex-wrap: wrap
		margin: -10px 0 0 -10px
	// .clm-achieve__item
	&__item
		display: flex
		align-items: center
		justify-content: center
		margin: 10px 0 0 10px
		&.text
			padding: 4px 8px
			border-radius: $border-radius
			&.large-height
				min-height: 32px
			&.large-radius
				border-radius: $border-high-radius
		&.text-with-icon
			.clm-achieve__text
				color: $color-main

		&--hidden
			display: none

	// .clm-achieve__badges
	&__badges
		display: flex
		flex-wrap: wrap

	// .clm-achieve__text
	&__text
		&:not(:last-child)
			margin-right: 4px


	// .clm-achieve__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 24px
		width: 24px
		height: 24px
		padding: 3px
		border-radius: 50%
		img
			max-width: 100%
			max-height: 100%
			object-fit: contain

/*========================================================================================================================================================*/
// * слайдер
body .swiper-pagination
	position: absolute
	bottom: 10px
	display: flex
	gap: 8px
	justify-content: center
	width: 100%
	padding: 0 10px
	.swiper-pagination-bullet
		flex: 0 1 10px
		min-width: 3px
		height: 3px
		border-radius: $border-high-radius
		background-color: $color-lightest-gray
		transition: background-color 0.2s ease 0s
		&.swiper-pagination-bullet-active
			background-color: $color-primary-dark

body .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet
	margin: 0

/*========================================================================================================================================================*/
// * лоадер

.clm-loader
	position: relative
	min-height: 300px
	width: 100%
	background-color: #ffffff
	z-index: 1
	.ppz &
		position: static

	&::after 
		content: ""
		position: absolute
		left: calc(50% - 24px)
		top: calc(50% - 24px)
		width: 48px
		height: 48px
		border: 5px solid var(--color-primary)
		border-bottom-color: transparent
		border-radius: 50%
		display: inline-block
		box-sizing: border-box
		animation: load-rotation 1s linear infinite

	@keyframes load-rotation
		0%
			transform: rotate(0deg)
		100% 
			transform: rotate(360deg)
/*========================================================================================================================================================*/
// * миксин для заголовков и параграфов (вызывается: @include default-style-title)

@mixin default-style-title
	h1
		display: block
		font-size: 2em
		margin-top: 0.67em
		margin-bottom: 0.67em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h2
		display: block
		font-size: 1.5em
		margin-top: 0.83em
		margin-bottom: 0.83em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h3
		display: block
		font-size: 1.17em
		margin-top: 1em
		margin-bottom: 1em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h4
		display: block
		margin-top: 1.33em
		margin-bottom: 1.33em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h5
		display: block
		font-size: .83em
		margin-top: 1.67em
		margin-bottom: 1.67em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h6
		display: block
		font-size: .67em
		margin-top: 2.33em
		margin-bottom: 2.33em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	p
		display: block
		margin-top: 1em
		margin-bottom: 1em
/*========================================================================================================================================================*/
// * стили для текста по умолчанию

.clm-content-block
	column-gap: 40px
	column-count: 2
	padding-top: 100px
	padding-bottom: 100px
	font-size: 15px
	font-family: $font-rubik
	line-height: normal
	color: $color-gray
	@media (max-width: $media-mobile-max)
		padding-top: 50px
		padding-bottom: 50px
		


	h1
		display: block
		font-size: 2em
		margin-top: 0.67em
		margin-bottom: 0.67em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h2
		display: block
		font-size: 1.5em
		margin-top: 0.83em
		margin-bottom: 0.83em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h3
		display: block
		font-size: 1.17em
		margin-top: 1em
		margin-bottom: 1em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h4
		display: block
		margin-top: 1.33em
		margin-bottom: 1.33em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h5
		display: block
		font-size: .83em
		margin-top: 1.67em
		margin-bottom: 1.67em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	h6
		display: block
		font-size: .67em
		margin-top: 2.33em
		margin-bottom: 2.33em
		margin-left: 0
		margin-right: 0
		font-weight: 700
		line-height: normal
		color: $color-main
		text-transform: unset

	p
		display: block
		margin-top: 1em
		margin-bottom: 1em
		font-family: inherit
		color: inherit

	ul, ol
		margin: 1em 0
		padding: 0
		list-style-position: inside
		li
			margin: 0.5em 0
			color: $color-gray
			list-style-type: none
	
	ul
		li
			position: relative
			padding-left: 20px
			&::before
				content: ''
				width: 10px
				height: 10px
				border-radius: 50%
				background-color: $color-primary-dark
				position: absolute
				top: 4px
				left: 0
			&:last-child
				margin-bottom: 0

	ol
		counter-reset: num
		li
			position: relative
			padding-left: 20px
			counter-increment: num
			&::before
				position: absolute
				content: counter(num)
				top: 0
				left: 0
				color: $color-primary-dark

		
	table
		display: table
		width: 100%
		border-collapse: collapse
		box-sizing: border-box
		text-indent: initial
		border-color: #d3d8dc

	tbody
		display: table-row-group
		vertical-align: middle
		border-color: inherit


	th, td
		width: auto
		padding: 0.8em 0.6em
		border: 1px solid $color-gray-blue
		background-color: $color-gray-blue-light
		font-size: 1em
		color: $color-gray

	td
		vertical-align: top
		text-align: left

	img
		max-width: 100%
		width: auto
		height: auto
		margin-top: 15px
		margin-bottom: 15px

	a
		text-decoration: none
		color: inherit
		position: relative
		border-bottom: 1px solid transparent
		color: $color-primary
		transition: color 0.2s ease 0s
		&:focus
			color: $color-primary-dark
		@media (any-hover: hover)
			&:hover
				color: $color-primary-dark

	& :last-child
		margin-bottom: 0

	& :first-child
		margin-top: 0

/*========================================================================================================================================================*/
// * стили для всплывашки по наведеню на десктопе (хинт)
// .clm-hint
// 	position: relative
// 	@media (any-hover: hover)
// 		&:hover
// 			.clm-hint__body
// 				opacity: 1
// 				transform: translate(-50%,0px)



	// .clm-hint__body
	// &__body
	// 	position: absolute
	// 	z-index: 10
	// 	bottom: calc(100% + 10px)
	// 	left: 50%
	// 	transform: translate(-50%,-20px)
	// 	opacity: 0
	// 	display: flex
	// 	align-items: center
	// 	justify-content: center
	// 	width: 200px
	// 	min-height: 50px
	// 	padding: 10px
	// 	border-radius: $border-radius
	// 	background-color: $color-gray
	// 	overflow-y: auto
	// 	transition: all 0.2s ease 0s
	// 	text-align: center
	// 	font-size: 14px
	// 	line-height: 120%
	// 	color: $color-white
	// 	pointer-events: none
	// 	@media (max-width: $media-mobile-max)
	// 		display: none

	// .clm-hint__text
	// &__text

// * стили для всплывашки генерируемой плагином
// <div data-tippy-root>
//   <div class="tippy-box" data-placement="top">
//     <div class="tippy-content">
//       My content
//     </div>
//   </div>
// </div>

// A tippy is essentially three nested divs.
// [data-tippy-root] is the outermost node. It is what Popper uses to position the tippy. You don't need to apply any styles to this element.
// tippy-box is the actual box node.
// tippy-content is the content node.

.tippy-box[data-theme="error-link"]
	box-sizing: border-box
	background-color: transparent
.tippy-box[data-theme="fft-2"]
	box-sizing: border-box
	opacity: 0
	display: flex
	align-items: center
	justify-content: center
	width: 200px
	min-height: 50px
	padding: 10px
	border-radius: $border-radius
	background-color: $color-gray
	overflow-y: auto
	transition: all 0.2s ease 0s
	text-align: center
	font-family: $font-rubik
	font-size: 14px
	line-height: 120%
	color: $color-white
	pointer-events: none
	&[data-placement^=top]
		transform: translate(0px,-20px)
	&[data-placement^=right]
		transform: translate(20px,0px)
	&[data-placement^=bottom]
		transform: translate(0px,20px)
	&[data-placement^=left]
		transform: translate(-20px,0px)
	&.hintBig
		width: 250px
	&.animated
		opacity: 1
		transform: translate(0px,0px)



.tippy-content



/*========================================================================================================================================================*/
