.pmp
	font-family: $font-rubik
	*
		box-sizing: border-box
	@media (min-width: $media-mobile-min)
		display: none

.pmp-title
	margin: 15px 0 0
	font-weight: 700
	font-size: 17px
	line-height: 130%
	color: $color-black

.pmp-code-rate
	display: flex
	justify-content: space-between
	margin-top: 8px

.pmp-code
	// flex: 0 1 calc(100% - 130px)
	font-family: $font-rubik
	font-size: 14px
	// .pmp-code__title
	&__title
		font-size: 14px
		font-weight: 400
		color: $color-light-gray

	// .pmp-code__value
	&__value
		margin-top: 4px
		font-size: 14px
		font-weight: 400
		color: $color-black

.pmp-rating
	display: flex
	flex-direction: column
	align-items: flex-end
	margin-top: -3px
	&.active
		.empty-icons
			opacity: 0
		.empty-icons-active
			opacity: 1
		.fill-icons
			opacity: 1

	// .pmp-rating__icons
	&__icons
		position: relative

	// .pmp-rating__row
	&__row
		display: flex

		// .empty-icons
		&.empty-icons

		// .empty-icons-active
		&.empty-icons-active
			opacity: 0
			overflow: hidden
			position: absolute
			width: 100%
			height: calc(100% - 2px)
			top: 2px
			left: 2px
			.pmp-rating__img
				&:not(:last-child)
					margin-right: 6px

		// .fill-icons
		&.fill-icons
			opacity: 0
			overflow: hidden
			position: absolute
			width: 100%
			height: calc(100% - 2px)
			top: 2px
			left: 2px
			.pmp-rating__img
				&:not(:last-child)
					margin-right: 6px

	// .pmp-rating__img
	&__img
		&:not(:last-child)
			margin-right: 2px

	// .pmp-rating__review
	&__review
		margin-top: 2px
		font-family: $font-rubik
		font-size: 14px
		line-height: 1
		color: $color-black

	// .pmp-rating__count
	&__count

.pmp-slider
	position: relative
	margin-top: 10px
	margin-bottom: 10px
	/* Слайдер */
	.slick-slider
		.slick-list
			margin-bottom: 0
	/* Слайдер запущен */
	.slick-slider.slick-initialized
	/* Слайдер с точками */
	.slick-slider.slick-dotted
	/* Ограничивающая оболочка */
	.slick-list
		overflow: hidden
	/* Лента слайдов */
	.slick-track
		display: flex
	/* Слайд */
	.slick-slide
		float: none
	/* Слайд активный (показывается) */
	.slick-slide.slick-active
	/* Слайд основной */
	.slick-slide.slick-current
	/* Слайд по центру */
	.slick-slide.slick-center
	/* Клонированный слайд */
	.slick-slide.slick-cloned
	/* Стрелка */
	.slick-arrow
	/* Стрелка влево */
	.slick-arrow.slick-prev
	/* Стрелка вправо */
	.slick-arrow.slick-next
	/* Стрелка не активная */
	.slick-arrow.slick-disabled
	/* Точки (булиты) */
	.slick-dots
		position: absolute
		left: 50%
		bottom: 10px
		transform: translate(-50%, 0px)
		display: flex
		align-items: center
		max-width: 100%
	.slick-dots li
		width: 30px
		height: 5px
		margin: 0 5px
		font-size: 0
		background-color: #E0E0E1
		border-radius: 10px
		transition: background-color 0.3s ease 0s
	/* Активная точка */
	.slick-dots li.slick-active
		background-color: $color-primary-dark
	/* Элемент точки */
	.slick-dots li button
	// .pmp-slider__wrapper
	&__wrapper
		// height: 230px
		overflow: hidden

	// .pmp-slider__slide
	&__slide
		position: relative
		width: 100%
		// height: 230px
		// padding-bottom: 66.67%
		padding-bottom: 100%
		img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: contain

.pmp-achieve
	position: absolute
	top: 10px
	left: 0
	width: calc( 100% - 105px )
	pointer-events: none
	// .pmp-achieve__list
	&__list
		display: flex
		flex-wrap: wrap

	// .pmp-achieve__item
	&__item
		display: flex
		align-items: center
		justify-content: center
		min-height: 26px
		padding: 0 8px
		border-radius: 4px
		margin-bottom: 8px
		background-color: $color-gray
		font-size: 12px
		font-weight: 500
		line-height: 1
		color: $color-white
		&:not(:last-child)
			margin-right: 8px

.pmp-com-fav
	position: absolute
	top: 10px
	right: 0
	// .pmp-com-fav__list
	&__list
		display: flex

	// .pmp-com-fav__item
	&__item
		display: flex
		align-items: center
		justify-content: center
		width: 40px
		height: 40px
		border-radius: 50%
		background-color: $color-gray-bg
		box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.15)
		&:not(:last-child)
			margin-right: 15px
		&.compare
			&.active
				svg path
					fill: $color-primary-dark
					stroke: $color-primary-dark
		&.favorite
			&.active
				svg path
					stroke: $color-primary-dark

.pmp-spoiler
	margin-top: 10px
	margin-bottom: 20px
	@media (min-width: $media-mobile-min)
		margin-top: 0
		margin-bottom: 20px
	&.popup
		.pmp-spoiler-head__icon
			transform: rotate(-90deg)

	// .pmp-spoiler__wrapper
	&__wrapper

	// .pmp-spoiler__inner
	&__inner
		margin-left: -10px
		margin-right: -10px
		padding: 0px 10px

	// .pmp-spoiler__title
	&__title
		display: flex
		align-items: center
		justify-content: space-between
		font-size: 14px
		font-weight: 400
		color: $color-gray

	// .pmp-spoiler__body
	&__body
		margin-top: 10px
		@media (min-width: $media-mobile-min)
			margin-top: 8px
		&.active
			.pmp-spoiler-head__icon
				img
					transform: scale(1, -1)

.pmp-spoiler-head
	display: flex
	align-items: center
	justify-content: space-between
	min-height: 50px
	padding: 10px 15px
	border-radius: 4px
	background: $color-gray-bg
	box-shadow: 0 5px 10px rgba(49, 49, 49, 0.15)
	font-family: $font-rubik
	font-size: 15px
	font-weight: 500
	color: $color-black
	@media (min-width: $media-mobile-min)
		padding: 9px 19px
		border-radius: 6px
		box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
		border: 1px solid $color-gray-blue
		line-height: 1
		transition: background-color .2s
		font-weight: 400

		&:hover,
		&:focus
			background-color: $color-gray-blue
			cursor: pointer

	// .pmp-spoiler-head__value
	&__value
		margin-right: 10px

	// .pmp-spoiler-head__price
	&__price
		flex-shrink: 0
		margin-right: 15px
		margin-left: auto

	// .pmp-spoiler-head__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 26px
		width: 26px
		height: 26px
		border-radius: 50%
		background-color: $color-primary-dark
		img
			transition: transform 0.3s ease 0s

.pmp-select
	padding-top: 10px
	// .pmp-select__list
	&__list
		padding: 15px
		border-radius: 4px
		background-color: $color-gray-bg
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15)
		@media (min-width: $media-mobile-min)
			box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08)
			border-radius: 6px
			border: 1px solid $color-gray-blue
			padding: 0
			// max-height: 400px
			overflow-y: auto
			scrollbar-color: #C1C1C1 #F8FAFC /* «цвет ползунка» «цвет полосы скроллбара» */
			scrollbar-width: thin  /* толщина */
			&::-webkit-scrollbar
				box-sizing: border-box
				width: 15px
				border: 1px solid $color-gray-blue
				border-right: none
				border-radius: 6px
			&::-webkit-scrollbar-thumb
				box-sizing: border-box
				background: #C1C1C1
				border-radius: 10px
				border: 5px solid transparent
				background-clip: content-box

	// .pmp-select__item
	&__item
		&:not(:first-child)
			padding-top: 15px

		&:not(:last-child)
			padding-bottom: 15px
			border-bottom: 1px solid rgba(101, 102, 104, 0.08)
			@media (min-width: $media-mobile-min)
				border-bottom: none
				position: relative
				&::after
					content: ''
					position: absolute
					bottom: 0
					left: 15px
					right: 15px
					height: 1px
					background-color: $color-gray-blue

		&.active
			.pmp-select-title__icon
				border: none
				background: url(/img/base/mobile-filter/checked.svg) no-repeat center/12px 10px $color-primary-dark

		@media (min-width: $media-mobile-min)
			padding: 0 !important

.pmp-select-short
	margin-bottom: 10px
	font-family: $font-rubik
	font-size: 15px
	font-weight: 500
	color: $color-primary-dark
	&.is-disabled
		color: $color-less-light-gray
	@media (min-width: $media-mobile-min)
		padding: 15px 20px 0
		margin-bottom: 0
		& ~ .pmp-select-title
			padding-top: 10px

.pmp-select-title
	display: flex
	align-items: center
	font-family: $font-rubik
	font-size: 15px
	line-height: 1
	color: $color-black
	position: relative
	@media (min-width: $media-mobile-min)
		padding: 15px 20px
		transition: background-color .2s
		&:hover,
		&:active
			background-color: $color-gray-blue
			cursor: pointer
	&.is-disabled
		color: $color-less-light-gray
		&::after
			content: ''
			position: absolute
			z-index: 1
			top: 0
			bottom: 0
			left: 0
			width: 5px
			background-color: $color-error
			transition: opacity .2s
			opacity: 0
			@media (max-width: $media-mobile-max)
				left: -15px

		&.is-disabled-visible
			&::after
				opacity: 1

		& .pmp-select-price__old
			color: $color-less-light-gray
		& .pmp-select-title__icon
			border-color: $color-lightest-gray

		&:hover,
		&:active
			cursor: default

	&__error
		background-color: #FFEAEA
		border: 1px solid $color-error
		font-size: 14px
		display: flex
		align-items: stretch
		color: $color-error
		border-radius: 6px
		min-height: 45px
		padding: 2px
		font-family: $font-rubik
		*
			box-sizing: border-box
		&-i
			flex-shrink: 0
			margin-left: 6px
			margin-right: 12px
		&-text
			margin-right: 20px
			display: flex
			align-items: center
			white-space: nowrap
			@media (max-width: $media-mobile-max)
				white-space: normal
		&-close
			border-left: 1px solid $color-error
			display: flex
			align-items: center
			justify-content: center
			width: 45px
			flex-shrink: 0

	// .pmp-select-title__value
	&__value
		display: flex
		align-items: center
		margin-right: 10px
		.mf-mark
			margin-left: 6px

	// .pmp-select-title__icon
	&__icon
		flex: 0 0 20px
		width: 20px
		height: 20px
		border-radius: 50%
		border: 1px solid $color-gray

		@media (min-width: $media-mobile-min)
			margin-left: 15px

.pmp-select-price
	flex-shrink: 0
	margin-right: 15px
	margin-left: auto
	text-align: right
	// .pmp-select-price__current
	@media (min-width: $media-mobile-min)
		margin-right: 0
	&__current

	// .pmp-select-price__old
	&__old
		margin-top: 3px
		margin-right: 2px
		font-size: 12px
		color: $color-gray
		text-decoration: line-through

.pmp-spoiler-info
	padding-bottom: 10px
	border-bottom: 1px solid $color-gray-bg
	margin-top: 10px
	font-size: 14px
	font-weight: 400
	color: $color-gray
	.font-rubik
		font-weight: 500
		color: $color-black

.pmp-price
	display: flex
	align-items: flex-end
	justify-content: center
	margin-bottom: 20px
	// .pmp-price__current
	&__current
		font-size: 23px
		font-weight: 500
		color: $color-black

	// .pmp-price__old
	&__old
		margin-left: 8px
		font-size: 17px
		text-decoration: line-through
		color: $color-gray

.pmp-buy
	display: flex
	align-items: center

	&.pmp-buy-fixed
		position: fixed
		z-index: 100
		bottom: 60px
		left: 0
		width: 100%
		padding: 15px
		margin-bottom: 0
		background-color: $color-white
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20)

	&.active
		.pmp-buy__text-1
			display: none
		.pmp-buy__block-text
			display: block
		.pmp-buy__controls
			display: flex

	// .pmp-buy__wrapper
	&__wrapper
		margin-bottom: 20px

	// .pmp-buy__btn
	&__btn
		display: flex
		align-items: center
		justify-content: center
		flex: 1 1 auto
		min-height: 50px
		padding: 5px 15px
		border-radius: 100px
		background-color: $color-primary
		line-height: 1

	// .pmp-buy__text-1
	&__text-1
		font-size: 15px
		font-weight: 500
		color: $color-white

	// .pmp-buy__block-text
	&__block-text
		display: none

	// .pmp-buy__text-2
	&__text-2
		font-size: 15px
		font-weight: 500
		color: $color-white
	// .pmp-buy__text-3
	&__text-3
		margin-top: 2px
		font-size: 12px
		font-weight: 500
		color: $color-white

	// .pmp-buy__controls
	&__controls
		display: none
		align-items: center
		flex: 0 0 150px
		margin-left: 15px

	// .pmp-buy__cnt
	&__cnt
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 50px
		width: 50px
		height: 50px
		border-radius: 50%
		background-color: $color-gray-bg

		svg rect
			fill: $color-gray

		&.active svg rect
			fill: $color-primary

	// .pmp-buy__count
	&__count
		flex: 0 0 50px
		text-align: center
		font-size: 23px
		color: $color-black

.active

.minus

.plus

.pmp-composition
	margin-bottom: 20px
	// .pmp-composition__list
	&__list
		padding: 15px
		border-radius: 4px
		background-color: $color-gray-bg

	// .pmp-composition__item
	&__item

		display: flex
		align-items: flex-end

		&:not(:last-child)
			margin-bottom: 15px

	// .pmp-composition__parameter
	&__parameter
		position: relative
		flex: 1 1 auto
		padding-right: 30px
		font-size: 14px
		color: $color-gray
		&::before
			content: ''
			position: absolute
			left: 0
			bottom: 0.2em
			display: inline-block
			width: 100%
			height: 100%
			border-bottom: 1px dashed $color-light-gray
		span
			position: relative
			padding-right: 3px
			background-color: $color-gray-bg

	// .pmp-composition__value
	&__value
		padding-left: 3px
		font-size: 14px
		color: $color-black
		background-color: $color-gray-bg

.pmp-big-achieve
	margin-bottom: 20px
	// .pmp-big-achieve__list
	&__list

	// .pmp-big-achieve__item
	&__item
		display: flex
		align-items: center
		min-height: 50px
		margin-bottom: 15px
		box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.15)
		border-radius: 100px
		background-color: $color-gray-bg
		&:not(:last-child)
			margin-bottom: 15px

	// .pmp-big-achieve__img
	&__img
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 50px
		width: 50px
		height: 50px
		border-radius: 50%
		box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.15)
		img
			max-width: 100%

	// .pmp-big-achieve__text
	&__text
		padding: 10px
		font-size: 14px
		color: $color-black

.pmp-compound
	// .pmp-compound__wrapper
	&__wrapper
		box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15)
		border-radius: 4px
		background-color: $color-gray-bg
		&.active
			.pmp-compound__icon
				img
					transform: scale(1, -1)

	// .pmp-compound__head
	&__head
		display: flex
		align-items: center
		min-height: 50px
		padding: 5px 15px

	// .pmp-compound__title
	&__title
		display: flex
		align-items: center
		margin-right: 10px
		font-size: 15px
		color: $color-black

	// .pmp-compound__icon
	&__icon
		display: flex
		align-items: center
		justify-content: center
		margin-left: auto
		flex: 0 0 26px
		width: 26px
		height: 26px
		border-radius: 50%
		background-color: $color-primary-dark
		img
			transition: transform 0.3s ease 0s

	// .pmp-compound__list
	&__list
		padding-bottom: 15px

	// .pmp-compound__item
	&__item
		display: flex
		align-items: center
		&:last-child
			.pmp-compound__img
				transform: scale(1, -1)

	// .pmp-compound__img
	&__img
		flex: 0 1 33.333%
		margin-right: 10px
		img
			max-width: 100%
			vertical-align: top

	// .pmp-compound__text
	&__text
		display: flex
		align-items: center
		justify-content: space-between
		flex: 0 1 66.666%
		font-size: 15px
		line-height: 1
		text-decoration: underline
		color: $color-primary-dark
		&.is-not-link
			text-decoration: none
			color: $color-main
		.mf-mark

.pmp-size.global-btn,
.pmp-size
	margin-bottom: 20px
	font-weight: 500
	font-size: 15px
	color: $color-primary