@import './modules/_variables'

.dsf
	width: 335px
	font-family: $font-rubik
	color: $color-black
	*
		box-sizing: border-box
	@media (max-width: 1180px)
		width: 275px

	&__loader
		display: flex
		position: relative
		height: 600px
		&::after
			content: ""
			position: absolute
			left: calc(50% - 24px)
			top: calc(50% - 24px)
			width: 48px
			height: 48px
			border: 5px solid var(--color-primary)
			border-bottom-color: transparent
			border-radius: 50%
			display: inline-block
			box-sizing: border-box
			animation: f-rotation 1s linear infinite

	&__inner
		margin-left: -15px
		margin-right: -15px
		padding-top: 10px

	&__bottom
		position: sticky
		bottom: 0
		background-color: #fff
		padding: 15px
		z-index: 10

	&__btns
		& .dsf__btn
			margin-bottom: 20px
			&:last-child
				margin-bottom: 0
		& .dsf__btn
			svg
				margin-left: 8px

	&__btn
		display: flex
		align-items: center
		justify-content: center
		outline: none
		width: 100%
		height: 50px

		border: none
		font-weight: 500
		font-size: 15px
		font-family: $font-rubik
		border-radius: 100px

		transition: all .2s

		padding-top: 2px

		&.disabled
			cursor: default

		&.is-primary
			background-color: $color-primary
			color: $color-white

		&.is-grey
			background-color: #F8FAFC
			color: $color-gray
			border: 1px solid $color-gray-blue

		&.loaded
			position: relative
			color: transparent !important
			cursor: default

			&::after
				content: ''
				position: absolute
				top: calc(50% - 13px)
				left: calc(50% - 13px)
				width: 26px
				height: 26px
				border: 2px solid var(--color-white)
				border-bottom-color: transparent
				border-radius: 50%
				box-sizing: border-box
				animation: f-rotation 1s linear infinite

		&:not(.disabled):not(.loaded):hover,
		&:not(.disabled):not(.loaded):focus
			outline: none
			cursor: pointer

			&.is-primary
				background-color: $color-primary-dark
			&.is-grey
				background-color: $color-gray-blue
				border-color: $color-gray-blue
				color: $color-black

.dsf-popup
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
	z-index: 1000

	@media (max-width: $media-mobile-max)
		display: none

	&__grid
		display: grid
		grid-template-columns: repeat(3, calc((100% - 200px) / 3))
		column-gap: 100px
		width: 100%
		//margin: 0 -50px
		//display: flex
		//&>div
		//	//width: 33%
		//	flex: 0 0 33.333%
		//	margin: 0 50px
		//	max-width: 33.333%
		& .tbc-section__citem
			padding-left: 0
			padding-right: 0

	&__bg
		position: absolute
		left: 0
		top: 0
		right: 0
		bottom: 0
		background-color: rgba(51, 51, 51, 0.3)
		transition: opacity .2s ease-in-out

	&__close
		cursor: pointer
		color: $color-gray
		transition: color .2s
		border-radius: 50%
		width: 50px
		height: 50px
		margin-left: 20px
		flex-shrink: 0
		background: #F8FAFC
		border: 1px solid $color-gray-blue
		display: flex
		align-items: center
		justify-content: center
		&:hover,
		&:focus
			color: $color-black

	&__container
		//padding: 0

	&__title
		font-weight: 500
		font-size: 35px
		color: $color-black

	&__top
		flex-shrink: 0
		padding: 50px 15px 40px 0

		&-inner
			display: flex
			align-items: center
			justify-content: space-between

	&__content
		overflow-y: scroll
		scrollbar-color: #C1C1C1 #F8FAFC /* «цвет ползунка» «цвет полосы скроллбара» */
		scrollbar-width: thin  /* толщина */

		&::-webkit-scrollbar
			box-sizing: border-box
			width: 15px
			border: 1px solid #E2E7F3
			border-right: none
			border-radius: 6px
		&::-webkit-scrollbar-thumb
			box-sizing: border-box
			background: #C1C1C1
			border-radius: 10px
			border: 5px solid transparent
			background-clip: content-box

	&__bottom
		flex-shrink: 0
		padding: 40px 15px 40px 0

	&__inner
		position: absolute
		left: 0
		right: 0
		bottom: 0
		//max-height: calc(100vh - 70px)
		overflow-y: auto
		background-color: #ffffff
		//min-height: 40vh

		transition: all .2s ease-in-out

		will-change: transform

		display: flex
		flex-direction: column
		max-height: calc(100vh - 70px)

	&__act-filters
		padding-bottom: 40px
		&-title
			font-weight: 500
			font-size: 17px
			margin-bottom: 20px

	// ANIMATIONS
.v-popup-enter-from,
.v-popup-leave-to
	& .dsf-popup__bg
		opacity: 0
	& .dsf-popup__inner
		transform: translateY(100%)

//.v-popup-enter-active
//	transition: all .2s ease-out
//.v-popup-leave-active
//	transition: all .2s ease-out

.dsf-check-list
	margin-bottom: -10px
	display: flex
	flex-wrap: wrap
	& .dsf-check
		margin-right: 10px
		margin-bottom: 10px

.dsf-check
	display: flex
	align-items: center
	white-space: nowrap
	font-weight: 400
	font-size: 13px
	cursor: pointer
	border-radius: 100px
	height: 30px
	padding: 0 12px

	color: $color-white
	background-color: $color-primary
	transition: all .2s

	&:hover,
	&:focus
		background-color: $color-primary-dark

	& > svg
		flex-shrink: 0
		margin-left: 8px

	&.is-grey
		color: $color-gray
		background-color: #F8FAFC
		border: 1px solid $color-gray-blue

		&:hover,
		&:focus
			background-color: #F8FAFC
			color: $color-black