.shares-mobile
	font-family: $font-main
	margin-top: 10px
	*
		box-sizing: border-box
	@media (min-width: $media-mobile-min)
		display: none

	// .shares-mobile__ul
	&__ul

	// .shares-mobile__li
	&__li
		border-radius: 4px
		overflow: hidden
		position: relative
		&:not(:last-child)
			margin: 0px 0px 15px 0px
	// .shares-mobile__link
	&__link

	// .shares-mobile__img
	&__img
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		img
			width: 100%
			height: 100%
			object-fit: cover
			vertical-align: top

	// .shares-mobile__text
	&__text
		position: relative
		z-index: 1
		display: flex
		flex-direction: column
		justify-content: center
		width: calc( 70% - 40px )
		min-height: 120px
		padding: 20px
		font-size: 15px
		font-weight: 600
		line-height: 120%
		color: $color-white
