@import './modules/_variables'

.popular-categories
	font-family: $font-rubik
	box-sizing: border-box
	font-size: 14px
	line-height: 1.2
	color: $color-main
	margin: 100px 0
	@media (max-width: $media-mobile-max)
		margin: 50px 0 20px
		& .container
			padding: 0

	&__top
		display: flex
		justify-content: space-between
		align-items: flex-end
		margin-bottom: 30px
		@media (max-width: $media-mobile-max)
			margin-bottom: 10px
			display: block
			padding: 0 15px

	&__title
		font-weight: 700
		font-size: 35px
		color: $color-main
		@media (max-width: $media-mobile-max)
			font-size: 25px

	&__link
		display: inline-flex
		margin-left: 10px
		flex-shrink: 0
		white-space: nowrap
		text-decoration: none
		height: 30px
		align-items: center
		padding: 0 16px
		border-radius: 6px
		color: $color-primary
		transition: color .2s ease 0s, background-color .2s ease 0s
		margin-bottom: 2px
		font-size: 14px
		@media (max-width: $media-mobile-max)
			display: none
		&:hover,
		&:focus
			cursor: pointer
			background-color: $color-primary
			color: $color-white

	&__wrap
		@media (max-width: $media-mobile-max)
			margin-left: auto
			margin-right: auto
			position: relative
			overflow: hidden
			list-style: none
			padding: 0
			z-index: 1
			width: 100%
			height: 100%

	&__pag
		@media (min-width: $media-mobile-min)
			display: none !important
		&.swiper-pagination-bullets
			display: flex
			align-items: center
			justify-content: center
			margin-top: 20px
			& .swiper-pagination-bullet
				width: 35px
				height: 4px
				margin: 0 7px !important
				background: $color-lightest-gray
				border-radius: 10px
				opacity: 1
				&.swiper-pagination-bullet-active
					background: $color-primary-dark


	&__grid
		@media (max-width: $media-mobile-max)
			position: relative
			width: 100%
			height: 100%
			z-index: 1
			display: flex
			transition-property: transform
			box-sizing: content-box
		@media (min-width: $media-mobile-min)
			display: grid
			grid-template-columns: repeat(4, 1fr)
			grid-auto-rows: 190px
			column-gap: 20px
			row-gap: 20px
			& > *
				&:nth-child(1)
					grid-area: 1 / 1 / 3 / 3
				&:nth-child(4)
					grid-area: 2 / 3 / 4 / 5
				&:nth-child(7)
					grid-area: 4 / 1 / 6 / 3
				&:nth-child(10)
					grid-area: 5 / 3 / 7 / 5

.popular-categories-card
	background: rgb(248, 248, 248)
	border-radius: 6px
	overflow: hidden
	position: relative
	text-decoration: none
	cursor: pointer
	display: flex
	align-items: flex-end
	justify-content: flex-end
	color: $color-main
	@media (max-width: $media-mobile-max)
		width: 335px
		height: 200px
		margin-left: 15px
		flex-shrink: 0
		&:last-child
			margin-right: 15px
	&:hover,
	&:focus
		& .popular-categories-card__check
			background-color: $color-primary
			color: $color-white

	&__img
		height: 100%
		width: auto
		object-fit: contain

	&__inner
		position: absolute
		left: 20px
		right: 20px
		top: 30px
		bottom: 20px
		display: flex
		flex-direction: column

	&__title
		font-weight: 500
		font-size: 19px
		margin-bottom: 5px

	&__subtitle
		font-weight: 400
		font-size: 12px
		color: $color-gray
		margin-bottom: auto

	&__check
		display: inline-flex
		background-color: $color-white
		border-radius: 100px 6px 6px 100px
		padding: 0 15px 0 35px
		position: relative
		font-weight: 400
		font-size: 14px
		height: 40px
		align-items: center
		transition: background-color .2s ease 0s, color .2s ease 0s
		&::after
			content: ''
			position: absolute
			width: 10px
			height: 10px
			background-color: #F6F6F6
			border-radius: 50%
			left: 10px
			top: 15px
