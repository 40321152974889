.ctl
	overflow: hidden
	margin-top: 10px
	*
		box-sizing: border-box
	@media (min-width: $media-mobile-min)
		display: none

	// .ctl__body
	&__body

.ctl-category
	// .ctl-category__list
	&__list
		display: flex
		overflow-x: auto
		padding-bottom: 10px
		margin: 0px -15px

	// .ctl-category__item
	&__item
		flex-shrink: 0
		margin-right: 10px
		&:first-child
			padding-left: 15px

	// .ctl-category__link
	&__link
		display: flex
		flex-direction: column
		align-items: center
		width: 100px
		height: 100%

	// .ctl-category__img
	&__img
		width: 70px
		height: 70px
		margin-bottom: 8px
		border-radius: 50%
		overflow: hidden
		background-color: $color-gray-bg
		img
			width: 100%
			height: 100%
			object-fit: contain

	// .ctl-category__title
	&__title
		font-size: 12px
		font-weight: 600
		line-height: 120%
		font-family: $font-main
		color: $color-black
		text-align: center

.ctl-title
	margin-top: 20px
	font-size: 19px
	font-weight: 500
	line-height: 120%
	font-family: $font-rubik
	color: $color-black

.ctl-controls
	margin: 15px -8px 0
	display: flex
	flex-wrap: wrap

	// .ctl-controls__btn
	&__btn
		flex: 1 1 auto
		padding: 0 8px
		color: $color-primary-dark

		// .ctl-controls__btn--dot
		&--dot
			flex: 0 0 40px
			width: 40px
			height: 40px
			padding: 0
			margin: 0 8px
			.ctl-controls__icon
				margin: 0px auto

		// .ctl-controls__btn--fill
		&--fill
			min-width: 100%
			flex: 0 0 100%
			margin-top: 10px
			color: $color-gray
		
			.ctl-controls__btn-inner
				color: $color-gray


			.ctl-controls__icon
				width: 14px
				height: 14px
				flex: 0 0 14px
				margin-right: 0px
				margin-left: 10px
		&.color
			.ctl-controls__btn-inner
				background-color: $color-primary
			.ctl-controls__text
				color: $color-white

	// .ctl-controls__btn-inner
	&__btn-inner
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		min-height: 40px
		padding: 0 10px
		border: 1px solid $color-gray-blue
		border-radius: 100px
		background-color: $color-gray-blue-light

	// .ctl-controls__icon
	&__icon
		margin-right: 8px
		width: 20px
		height: 20px
		img
			width: 100%
			height: 100%
			object-fit: contain

	// .ctl-controls__text
	&__text
		display: flex
		align-items: center
		font-family: $font-rubik
		font-size: 14px
		font-weight: 500

	// .ctl-controls__count
	&__count
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 22px
		width: 22px
		height: 22px
		margin-left: 8px
		border-radius: 50%
		overflow: hidden
		background-color: $color-primary-dark
		font-weight: 500
		font-size: 14px
		color: $color-white

.ctl-ff
	margin-top: 15px
	// .ctl-ff__list
	&__list
		display: flex
		align-items: center
		padding-bottom: 10px
		margin: 0px -15px
		overflow-x: auto

	// .ctl-ff__item
	&__item
		flex-shrink: 0
		margin-right: 10px
		&:first-child
			padding-left: 15px
		&.active
			.ctl-ff__icon
				display: block
			.ctl-ff__text
				color: $color-primary

	// .ctl-ff__btn
	&__btn
		display: flex
		align-items: center
		justify-content: center
		min-height: 30px
		padding: 5px 15px
		border-radius: 100px
		color: $color-gray
		background-color: $color-gray-bg

	// .ctl-ff__icon
	&__icon
		flex: 0 0 12px
		margin-right: 8px
		display: none

	// .ctl-ff__text
	&__text
		font-family: $font-rubik
		font-size: 12px
		font-weight: 500
		line-height: 1
