@import './modules/_variables'

.social-links-next
	font-family: $font-rubik
	box-sizing: border-box
	font-size: 14px
	line-height: 1.3
	color: $color-main
	margin: 50px 0

	&__top
		margin-bottom: 30px
		@media (max-width: $media-mobile-max)
			margin-bottom: 15px

	&__title
		font-weight: 700
		font-size: 35px
		color: $color-main
		@media (max-width: $media-mobile-max)
			font-size: 25px

	&__grid
		display: grid
		grid-template-columns: repeat(4, 1fr)
		grid-auto-rows: 330px
		column-gap: 20px
		row-gap: 20px
		@media (max-width: 1400px)
			grid-auto-rows: 300px
		@media (max-width: 1300px)
			grid-auto-rows: 250px
		@media (max-width: 1150px)
			grid-auto-rows: 200px

		@media (min-width: $media-mobile-min)
			& > *
				&:nth-child(1)
					grid-area: 1 / 1 / 3 / 3
				&:nth-child(4)
					grid-area: 2 / 3 / 3 / 5
				&:nth-child(7)
					grid-area: 4 / 1 / 5 / 3
				&:nth-child(8)
					grid-area: 4 / 3 / 6 / 5

		@media (max-width: $media-mobile-max)
			grid-template-columns: repeat(2, 1fr)
			grid-auto-rows: 162px
			column-gap: 15px
			row-gap: 15px
			& > *
				&:nth-child(1)
					grid-area: 1 / 1 / 3 / 3
				&:nth-child(4)
					grid-area: 4 / 1 / 5 / 3

	&__card
		text-decoration: none
		cursor: pointer
		display: block
		width: 100%
		height: 100%
		border-radius: 6px
		overflow: hidden
		&:hover,
		&:focus
			& .social-links-next__card-img
				transform: scale(1.05)

		&-img
			object-fit: cover
			width: 100%
			height: 100%
			object-position: center center
			transition: transform .5s ease 0s