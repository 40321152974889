@charset "UTF-8";
.hm {
  height: 60px;
}
.hm * {
  box-sizing: border-box;
}
@media (min-width: 1025px) {
  .hm {
    display: none;
  }
}
@media (max-width: 1024px) {
  .hm {
    margin-bottom: 15px;
  }
}
.hm__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000001;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.hm__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: var(--color-gray-bg);
}
.hm__logo {
  width: 147px;
  height: 40px;
  margin-right: 10px;
}
.hm__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.hm__icons {
  display: flex;
  align-items: center;
}
.hm__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: 34px;
  height: 34px;
  background: var(--color-gray-bg);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 60px;
}
.hm__icon:not(:last-child) {
  margin-right: 15px;
}

.tb {
  height: 60px;
}
.tb * {
  box-sizing: border-box;
}
@media (min-width: 1025px) {
  .tb {
    display: none;
  }
}
.tb.pmp-buy-fixed {
  height: 140px;
}
.tb__body {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000001;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  font-family: "Raleway", sans-serif;
}
.tb__list {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  background-color: var(--color-white);
}
.tb__item {
  flex: 0 1 20%;
}
.tb__item.active .tb__icon svg path {
  fill: var(--color-primary);
}
.tb__item.active .tb__text {
  color: var(--color-primary);
}
.tb__item:nth-child(2).active .tb__icon svg path {
  stroke: var(--color-primary);
  fill: transparent;
}
.tb__item:nth-child(5).active .tb__icon svg rect {
  fill: var(--color-primary);
}
.tb__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 5px;
}
.tb__icon {
  position: relative;
}
.tb__count {
  position: absolute;
  top: -4px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 2px solid var(--color-white);
  background-color: var(--color-primary);
  font-family: "Rubik", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: var(--color-white);
}
.tb__text {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-black);
}

@media (max-width: 1024px) {
  .tb-modal-phone.show {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .tb-modal-phone.modal.fade .modal-dialog {
    transform: translate(0, 0);
  }
  .tb-modal-phone .modal-dialog {
    max-width: none;
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: 60px;
    left: 0;
  }
  .tb-modal-phone .modal-content {
    overflow: auto;
    height: calc(100% - 110px);
  }
}
.basket-section {
  padding: 50px 0;
}
@media (max-width: 1024px) {
  .basket-section {
    padding: 5px 0;
  }
}
.basket-section.empty {
  padding: 50px 0;
  font-family: "Raleway", sans-serif;
}
.basket-section * {
  box-sizing: border-box;
}
.basket-section__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basket-section__img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 50%;
  background-color: var(--color-gray-bg);
}
.basket-section__img img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.basket-section__title {
  margin-bottom: 10px;
  font-size: 23px;
  font-weight: 700;
  color: var(--color-black);
  text-align: center;
}
.basket-section__subtitle {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
  text-align: center;
}
@media (max-width: 1024px) {
  .basket-section__btn {
    width: 100%;
  }
}
.basket-section__btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 0 15px;
  border: 1px solid var(--color-primary);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 60px;
  background: var(--color-primary);
  font-size: 17px;
  font-weight: 500;
  color: var(--color-white);
}
@media (min-width: 1025px) {
  .basket-section__btn a {
    width: auto;
    min-width: 345px;
  }
}

.favorite-empty {
  padding: 50px 0;
  font-family: "Raleway", sans-serif;
}
.favorite-empty * {
  box-sizing: border-box;
}
.favorite-empty__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.favorite-empty__img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 50%;
  background-color: var(--color-gray-bg);
}
.favorite-empty__img img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.favorite-empty__title {
  margin-bottom: 10px;
  font-size: 23px;
  font-weight: 700;
  color: var(--color-black);
  text-align: center;
}
.favorite-empty__subtitle {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
  text-align: center;
}
@media (max-width: 1024px) {
  .favorite-empty__button {
    width: 100%;
  }
}
.favorite-empty__button a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 0 15px;
  border: 1px solid var(--color-primary);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 60px;
  background: var(--color-primary);
  font-size: 17px;
  font-weight: 500;
  color: var(--color-white);
}
@media (min-width: 1025px) {
  .favorite-empty__button a {
    width: auto;
    min-width: 345px;
  }
}

.shares-mobile {
  font-family: "Raleway", sans-serif;
  margin-top: 10px;
}
.shares-mobile * {
  box-sizing: border-box;
}
@media (min-width: 1025px) {
  .shares-mobile {
    display: none;
  }
}
.shares-mobile__li {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.shares-mobile__li:not(:last-child) {
  margin: 0px 0px 15px 0px;
}
.shares-mobile__img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.shares-mobile__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}
.shares-mobile__text {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(70% - 40px);
  min-height: 120px;
  padding: 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 120%;
  color: var(--color-white);
}

.mf {
  font-family: "Raleway", sans-serif;
}
.mf * {
  box-sizing: border-box;
}
.mf img {
  vertical-align: top;
}
@media (min-width: 1025px) {
  .mf {
    display: none;
  }
}
.mf__body {
  padding: 10px 0 20px;
}
.mf__body .section-title {
  margin-bottom: 15px;
}

.mf-section:not(:last-child) {
  margin: 0px 0px 20px 0px;
}
.mf-section__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  color: var(--color-gray);
}
@media (min-width: 1025px) {
  .mf-section__title {
    font-weight: 400;
    margin-bottom: 8px;
  }
}

.mf-mark {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-primary-dark);
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 18px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  vertical-align: middle;
  color: var(--color-primary-dark);
}
.mf-mark.js-ppzPopupUrl {
  cursor: pointer;
}

.mf-spoiler.active .mf-spoiler__icon svg {
  transform: scale(1, -1);
}
.mf-spoiler__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 10px 15px;
  background: var(--color-gray-bg);
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15);
  border-radius: 4px;
}
@media (min-width: 1025px) {
  .mf-spoiler__heading {
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border-radius: 6px;
    border: 1px solid var(--color-gray-blue);
    transition: background-color 0.2s;
  }
  .mf-spoiler__heading:hover, .mf-spoiler__heading:focus {
    background-color: var(--color-gray-blue);
    cursor: pointer;
  }
  .mf-spoiler__heading.active {
    position: relative;
  }
  .mf-spoiler__heading.active::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    border-radius: 6px 0 0 6px;
    background-color: var(--color-primary-dark);
    z-index: 1;
  }
}
.mf-spoiler__title {
  padding-right: 10px;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);
}
@media (min-width: 1025px) {
  .mf-spoiler__title {
    font-weight: 400;
  }
  .mf-spoiler__title.active {
    font-weight: 500;
  }
}
.mf-spoiler__title-list {
  padding-right: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: var(--color-black);
}
@media (min-width: 1025px) {
  .mf-spoiler__title-list {
    font-weight: 400;
  }
}
.mf-spoiler__icon {
  flex: 0 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
}
.mf-spoiler__icon svg {
  transition: transform 0.3s ease 0s;
}
.mf-spoiler__icon-list {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--color-gray);
}
.mf-spoiler__block-list {
  padding-top: 20px;
}
@media (min-width: 1025px) {
  .mf-spoiler__block-list {
    padding-top: 10px;
  }
}
.mf-spoiler__block-list.checked .mf-spoiler__item {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
}
.mf-spoiler__block-list.checked .mf-spoiler__title {
  padding-right: 0;
}
.mf-spoiler__block-list.checked .mf-spoiler__title-list {
  padding-right: 0;
}
.mf-spoiler__block-list.checked .mf-spoiler__icon-list {
  margin-right: 10px;
  border-radius: 0;
}
.mf-spoiler__list {
  padding: 0 15px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  background: #F8FAFC;
}
@media (min-width: 1025px) {
  .mf-spoiler__list {
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border-radius: 6px;
    border: 1px solid var(--color-gray-blue);
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    scrollbar-color: #C1C1C1 #F8FAFC;
    scrollbar-width: thin;
  }
  .mf-spoiler__list::-webkit-scrollbar {
    box-sizing: border-box;
    width: 15px;
    border: 1px solid var(--color-gray-blue);
    border-right: none;
    border-radius: 6px;
  }
  .mf-spoiler__list::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    background: #C1C1C1;
    border-radius: 10px;
    border: 5px solid transparent;
    background-clip: content-box;
  }
}
.mf-spoiler__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}
@media (min-width: 1025px) {
  .mf-spoiler__item {
    padding: 15px;
    transition: background-color 0.2s;
    position: relative;
  }
  .mf-spoiler__item:hover, .mf-spoiler__item:focus {
    background-color: var(--color-gray-blue);
    cursor: pointer;
  }
}
.mf-spoiler__item:not(:last-child) {
  border-bottom: 1px solid rgba(101, 102, 104, 0.08);
}
@media (min-width: 1025px) {
  .mf-spoiler__item:not(:last-child) {
    border: none;
  }
  .mf-spoiler__item:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background-color: var(--color-gray-blue);
  }
}
.mf-spoiler__item.active .mf-spoiler__icon-list {
  border: none;
  background: url("/img/base/mobile-filter/checked.svg") no-repeat center/12px 10px var(--color-primary-dark);
}
.mf-spoiler__item.disabled {
  cursor: auto !important;
}
.mf-spoiler__item.disabled:hover, .mf-spoiler__item.disabled:focus {
  background-color: unset;
}
.mf-spoiler__item.disabled .mf-spoiler__title-list {
  cursor: auto;
  opacity: 0.4;
}
.mf-spoiler__item.disabled .mf-spoiler__icon-list {
  opacity: 0.4;
}

.mf-check-line {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid var(--color-gray-bg);
  border-bottom: 1px solid var(--color-gray-bg);
}
@media (min-width: 1025px) {
  .mf-check-line {
    padding-right: 16px;
  }
}
.mf-check-line.active .mf-check-line__icon {
  border: none;
  background: url("/img/base/mobile-filter/checked.svg") no-repeat center/12px 10px var(--color-primary-dark);
}
.mf-check-line__text {
  padding-right: 10px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
.mf-check-line__icon {
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid var(--color-gray);
}
@media (min-width: 1025px) {
  .mf-check-line__icon {
    cursor: pointer;
  }
}

.mf-range__block {
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15);
  background: #F8FAFC;
}
@media (min-width: 1025px) {
  .mf-range__block {
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border-radius: 6px;
    border: 1px solid var(--color-gray-blue);
  }
}
.mf-range__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
}
.mf-range__price {
  flex: 0 0 calc(50% - 14px);
  font-weight: 500;
  font-size: 15px;
}
.mf-range__price.to {
  text-align: right;
}
.mf-range__icon {
  width: 15px;
  height: 2px;
  background-color: var(--color-gray);
}
.mf-reboot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: var(--color-gray-bg);
}
.mf-reboot__icon {
  margin-right: 8px;
}
.mf-reboot__text {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
}

.mf-more-param {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-dark);
}
.mf-more-param.active .mf-more-param__more {
  display: none;
}
.mf-more-param.active .mf-more-param__less {
  display: block;
}
.mf-more-param.active .mf-more-param__icon {
  transform: scale(1, -1);
}
.mf-more-param__text {
  margin-right: 8px;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-primary-dark);
  cursor: pointer;
}
.mf-more-param__less {
  display: none;
}
.mf-more-param__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease 0s;
}

.mf-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  border-radius: 100px;
  background-color: var(--color-primary);
  transition: background-color 0.2s;
}
.mf-btn___text {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-white);
}
@media (min-width: 1025px) {
  .mf-btn:not(.disabled):not(.loaded):hover, .mf-btn:not(.disabled):not(.loaded):focus {
    outline: none;
    cursor: pointer;
    background-color: var(--color-primary-dark);
  }
}

.ctl {
  overflow: hidden;
  margin-top: 10px;
}
.ctl * {
  box-sizing: border-box;
}
@media (min-width: 1025px) {
  .ctl {
    display: none;
  }
}
.ctl-category__list {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  margin: 0px -15px;
}
.ctl-category__item {
  flex-shrink: 0;
  margin-right: 10px;
}
.ctl-category__item:first-child {
  padding-left: 15px;
}
.ctl-category__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
}
.ctl-category__img {
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-gray-bg);
}
.ctl-category__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ctl-category__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  font-family: "Raleway", sans-serif;
  color: var(--color-black);
  text-align: center;
}

.ctl-title {
  margin-top: 20px;
  font-size: 19px;
  font-weight: 500;
  line-height: 120%;
  font-family: "Rubik", sans-serif;
  color: var(--color-black);
}

.ctl-controls {
  margin: 15px -8px 0;
  display: flex;
  flex-wrap: wrap;
}
.ctl-controls__btn {
  flex: 1 1 auto;
  padding: 0 8px;
  color: var(--color-primary-dark);
}
.ctl-controls__btn--dot {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 8px;
}
.ctl-controls__btn--dot .ctl-controls__icon {
  margin: 0px auto;
}
.ctl-controls__btn--fill {
  min-width: 100%;
  flex: 0 0 100%;
  margin-top: 10px;
  color: var(--color-gray);
}
.ctl-controls__btn--fill .ctl-controls__btn-inner {
  color: var(--color-gray);
}
.ctl-controls__btn--fill .ctl-controls__icon {
  width: 14px;
  height: 14px;
  flex: 0 0 14px;
  margin-right: 0px;
  margin-left: 10px;
}
.ctl-controls__btn.color .ctl-controls__btn-inner {
  background-color: var(--color-primary);
}
.ctl-controls__btn.color .ctl-controls__text {
  color: var(--color-white);
}
.ctl-controls__btn-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding: 0 10px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 100px;
  background-color: #F8FAFC;
}
.ctl-controls__icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
.ctl-controls__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ctl-controls__text {
  display: flex;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.ctl-controls__count {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  margin-left: 8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-primary-dark);
  font-weight: 500;
  font-size: 14px;
  color: var(--color-white);
}

.ctl-ff {
  margin-top: 15px;
}
.ctl-ff__list {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin: 0px -15px;
  overflow-x: auto;
}
.ctl-ff__item {
  flex-shrink: 0;
  margin-right: 10px;
}
.ctl-ff__item:first-child {
  padding-left: 15px;
}
.ctl-ff__item.active .ctl-ff__icon {
  display: block;
}
.ctl-ff__item.active .ctl-ff__text {
  color: var(--color-primary);
}
.ctl-ff__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  padding: 5px 15px;
  border-radius: 100px;
  color: var(--color-gray);
  background-color: var(--color-gray-bg);
}
.ctl-ff__icon {
  flex: 0 0 12px;
  margin-right: 8px;
  display: none;
}
.ctl-ff__text {
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.pmp {
  font-family: "Rubik", sans-serif;
}
.pmp * {
  box-sizing: border-box;
}
@media (min-width: 1025px) {
  .pmp {
    display: none;
  }
}

.pmp-title {
  margin: 15px 0 0;
  font-weight: 700;
  font-size: 17px;
  line-height: 130%;
  color: var(--color-black);
}

.pmp-code-rate {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.pmp-code {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
}
.pmp-code__title {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light-gray);
}
.pmp-code__value {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-black);
}

.pmp-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -3px;
}
.pmp-rating.active .empty-icons {
  opacity: 0;
}
.pmp-rating.active .empty-icons-active {
  opacity: 1;
}
.pmp-rating.active .fill-icons {
  opacity: 1;
}
.pmp-rating__icons {
  position: relative;
}
.pmp-rating__row {
  display: flex;
}
.pmp-rating__row.empty-icons-active {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  top: 2px;
  left: 2px;
}
.pmp-rating__row.empty-icons-active .pmp-rating__img:not(:last-child) {
  margin-right: 6px;
}
.pmp-rating__row.fill-icons {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  top: 2px;
  left: 2px;
}
.pmp-rating__row.fill-icons .pmp-rating__img:not(:last-child) {
  margin-right: 6px;
}
.pmp-rating__img:not(:last-child) {
  margin-right: 2px;
}
.pmp-rating__review {
  margin-top: 2px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 1;
  color: var(--color-black);
}
.pmp-slider {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  /* Слайдер */
  /* Слайдер запущен */
  /* Слайдер с точками */
  /* Ограничивающая оболочка */
  /* Лента слайдов */
  /* Слайд */
  /* Слайд активный (показывается) */
  /* Слайд основной */
  /* Слайд по центру */
  /* Клонированный слайд */
  /* Стрелка */
  /* Стрелка влево */
  /* Стрелка вправо */
  /* Стрелка не активная */
  /* Точки (булиты) */
  /* Активная точка */
  /* Элемент точки */
}
.pmp-slider .slick-slider .slick-list {
  margin-bottom: 0;
}
.pmp-slider .slick-list {
  overflow: hidden;
}
.pmp-slider .slick-track {
  display: flex;
}
.pmp-slider .slick-slide {
  float: none;
}
.pmp-slider .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0px);
  display: flex;
  align-items: center;
  max-width: 100%;
}
.pmp-slider .slick-dots li {
  width: 30px;
  height: 5px;
  margin: 0 5px;
  font-size: 0;
  background-color: #E0E0E1;
  border-radius: 10px;
  transition: background-color 0.3s ease 0s;
}
.pmp-slider .slick-dots li.slick-active {
  background-color: var(--color-primary-dark);
}
.pmp-slider__wrapper {
  overflow: hidden;
}
.pmp-slider__slide {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.pmp-slider__slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pmp-achieve {
  position: absolute;
  top: 10px;
  left: 0;
  width: calc(100% - 105px);
  pointer-events: none;
}
.pmp-achieve__list {
  display: flex;
  flex-wrap: wrap;
}
.pmp-achieve__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  padding: 0 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: var(--color-gray);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: var(--color-white);
}
.pmp-achieve__item:not(:last-child) {
  margin-right: 8px;
}

.pmp-com-fav {
  position: absolute;
  top: 10px;
  right: 0;
}
.pmp-com-fav__list {
  display: flex;
}
.pmp-com-fav__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-gray-bg);
  box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.15);
}
.pmp-com-fav__item:not(:last-child) {
  margin-right: 15px;
}
.pmp-com-fav__item.compare.active svg path {
  fill: var(--color-primary-dark);
  stroke: var(--color-primary-dark);
}
.pmp-com-fav__item.favorite.active svg path {
  stroke: var(--color-primary-dark);
}

.pmp-spoiler {
  margin-top: 10px;
  margin-bottom: 20px;
}
@media (min-width: 1025px) {
  .pmp-spoiler {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.pmp-spoiler.popup .pmp-spoiler-head__icon {
  transform: rotate(-90deg);
}
.pmp-spoiler__inner {
  margin-left: -10px;
  margin-right: -10px;
  padding: 0px 10px;
}
.pmp-spoiler__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
}
.pmp-spoiler__body {
  margin-top: 10px;
}
@media (min-width: 1025px) {
  .pmp-spoiler__body {
    margin-top: 8px;
  }
}
.pmp-spoiler__body.active .pmp-spoiler-head__icon img {
  transform: scale(1, -1);
}

.pmp-spoiler-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 10px 15px;
  border-radius: 4px;
  background: var(--color-gray-bg);
  box-shadow: 0 5px 10px rgba(49, 49, 49, 0.15);
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
}
@media (min-width: 1025px) {
  .pmp-spoiler-head {
    padding: 9px 19px;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border: 1px solid var(--color-gray-blue);
    line-height: 1;
    transition: background-color 0.2s;
    font-weight: 400;
  }
  .pmp-spoiler-head:hover, .pmp-spoiler-head:focus {
    background-color: var(--color-gray-blue);
    cursor: pointer;
  }
}
.pmp-spoiler-head__value {
  margin-right: 10px;
}
.pmp-spoiler-head__price {
  flex-shrink: 0;
  margin-right: 15px;
  margin-left: auto;
}
.pmp-spoiler-head__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
}
.pmp-spoiler-head__icon img {
  transition: transform 0.3s ease 0s;
}

.pmp-select {
  padding-top: 10px;
}
.pmp-select__list {
  padding: 15px;
  border-radius: 4px;
  background-color: var(--color-gray-bg);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1025px) {
  .pmp-select__list {
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border-radius: 6px;
    border: 1px solid var(--color-gray-blue);
    padding: 0;
    overflow-y: auto;
    scrollbar-color: #C1C1C1 #F8FAFC;
    scrollbar-width: thin;
  }
  .pmp-select__list::-webkit-scrollbar {
    box-sizing: border-box;
    width: 15px;
    border: 1px solid var(--color-gray-blue);
    border-right: none;
    border-radius: 6px;
  }
  .pmp-select__list::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    background: #C1C1C1;
    border-radius: 10px;
    border: 5px solid transparent;
    background-clip: content-box;
  }
}
.pmp-select__item:not(:first-child) {
  padding-top: 15px;
}
.pmp-select__item:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(101, 102, 104, 0.08);
}
@media (min-width: 1025px) {
  .pmp-select__item:not(:last-child) {
    border-bottom: none;
    position: relative;
  }
  .pmp-select__item:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background-color: var(--color-gray-blue);
  }
}
.pmp-select__item.active .pmp-select-title__icon {
  border: none;
  background: url(/img/base/mobile-filter/checked.svg) no-repeat center/12px 10px var(--color-primary-dark);
}
@media (min-width: 1025px) {
  .pmp-select__item {
    padding: 0 !important;
  }
}

.pmp-select-short {
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-primary-dark);
}
.pmp-select-short.is-disabled {
  color: var(--color-less-light-gray);
}
@media (min-width: 1025px) {
  .pmp-select-short {
    padding: 15px 20px 0;
    margin-bottom: 0;
  }
  .pmp-select-short ~ .pmp-select-title {
    padding-top: 10px;
  }
}

.pmp-select-title {
  display: flex;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  line-height: 1;
  color: var(--color-black);
  position: relative;
}
@media (min-width: 1025px) {
  .pmp-select-title {
    padding: 15px 20px;
    transition: background-color 0.2s;
  }
  .pmp-select-title:hover, .pmp-select-title:active {
    background-color: var(--color-gray-blue);
    cursor: pointer;
  }
}
.pmp-select-title.is-disabled {
  color: var(--color-less-light-gray);
}
.pmp-select-title.is-disabled::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  background-color: var(--color-error);
  transition: opacity 0.2s;
  opacity: 0;
}
@media (max-width: 1024px) {
  .pmp-select-title.is-disabled::after {
    left: -15px;
  }
}
.pmp-select-title.is-disabled.is-disabled-visible::after {
  opacity: 1;
}
.pmp-select-title.is-disabled .pmp-select-price__old {
  color: var(--color-less-light-gray);
}
.pmp-select-title.is-disabled .pmp-select-title__icon {
  border-color: var(--color-lightest-gray);
}
.pmp-select-title.is-disabled:hover, .pmp-select-title.is-disabled:active {
  cursor: default;
}
.pmp-select-title__error {
  background-color: #FFEAEA;
  border: 1px solid var(--color-error);
  font-size: 14px;
  display: flex;
  align-items: stretch;
  color: var(--color-error);
  border-radius: 6px;
  min-height: 45px;
  padding: 2px;
  font-family: "Rubik", sans-serif;
}
.pmp-select-title__error * {
  box-sizing: border-box;
}
.pmp-select-title__error-i {
  flex-shrink: 0;
  margin-left: 6px;
  margin-right: 12px;
}
.pmp-select-title__error-text {
  margin-right: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .pmp-select-title__error-text {
    white-space: normal;
  }
}
.pmp-select-title__error-close {
  border-left: 1px solid var(--color-error);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  flex-shrink: 0;
}
.pmp-select-title__value {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.pmp-select-title__value .mf-mark {
  margin-left: 6px;
}
.pmp-select-title__icon {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--color-gray);
}
@media (min-width: 1025px) {
  .pmp-select-title__icon {
    margin-left: 15px;
  }
}

.pmp-select-price {
  flex-shrink: 0;
  margin-right: 15px;
  margin-left: auto;
  text-align: right;
}
@media (min-width: 1025px) {
  .pmp-select-price {
    margin-right: 0;
  }
}
.pmp-select-price__old {
  margin-top: 3px;
  margin-right: 2px;
  font-size: 12px;
  color: var(--color-gray);
  text-decoration: line-through;
}

.pmp-spoiler-info {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-gray-bg);
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
}
.pmp-spoiler-info .font-rubik {
  font-weight: 500;
  color: var(--color-black);
}

.pmp-price {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
}
.pmp-price__current {
  font-size: 23px;
  font-weight: 500;
  color: var(--color-black);
}
.pmp-price__old {
  margin-left: 8px;
  font-size: 17px;
  text-decoration: line-through;
  color: var(--color-gray);
}

.pmp-buy {
  display: flex;
  align-items: center;
}
.pmp-buy.pmp-buy-fixed {
  position: fixed;
  z-index: 100;
  bottom: 60px;
  left: 0;
  width: 100%;
  padding: 15px;
  margin-bottom: 0;
  background-color: var(--color-white);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.pmp-buy.active .pmp-buy__text-1 {
  display: none;
}
.pmp-buy.active .pmp-buy__block-text {
  display: block;
}
.pmp-buy.active .pmp-buy__controls {
  display: flex;
}
.pmp-buy__wrapper {
  margin-bottom: 20px;
}
.pmp-buy__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  min-height: 50px;
  padding: 5px 15px;
  border-radius: 100px;
  background-color: var(--color-primary);
  line-height: 1;
}
.pmp-buy__text-1 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
}
.pmp-buy__block-text {
  display: none;
}
.pmp-buy__text-2 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
}
.pmp-buy__text-3 {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-white);
}
.pmp-buy__controls {
  display: none;
  align-items: center;
  flex: 0 0 150px;
  margin-left: 15px;
}
.pmp-buy__cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-gray-bg);
}
.pmp-buy__cnt svg rect {
  fill: var(--color-gray);
}
.pmp-buy__cnt.active svg rect {
  fill: var(--color-primary);
}
.pmp-buy__count {
  flex: 0 0 50px;
  text-align: center;
  font-size: 23px;
  color: var(--color-black);
}

.pmp-composition {
  margin-bottom: 20px;
}
.pmp-composition__list {
  padding: 15px;
  border-radius: 4px;
  background-color: var(--color-gray-bg);
}
.pmp-composition__item {
  display: flex;
  align-items: flex-end;
}
.pmp-composition__item:not(:last-child) {
  margin-bottom: 15px;
}
.pmp-composition__parameter {
  position: relative;
  flex: 1 1 auto;
  padding-right: 30px;
  font-size: 14px;
  color: var(--color-gray);
}
.pmp-composition__parameter::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.2em;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-bottom: 1px dashed var(--color-light-gray);
}
.pmp-composition__parameter span {
  position: relative;
  padding-right: 3px;
  background-color: var(--color-gray-bg);
}
.pmp-composition__value {
  padding-left: 3px;
  font-size: 14px;
  color: var(--color-black);
  background-color: var(--color-gray-bg);
}

.pmp-big-achieve {
  margin-bottom: 20px;
}
.pmp-big-achieve__item {
  display: flex;
  align-items: center;
  min-height: 50px;
  margin-bottom: 15px;
  box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.15);
  border-radius: 100px;
  background-color: var(--color-gray-bg);
}
.pmp-big-achieve__item:not(:last-child) {
  margin-bottom: 15px;
}
.pmp-big-achieve__img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.15);
}
.pmp-big-achieve__img img {
  max-width: 100%;
}
.pmp-big-achieve__text {
  padding: 10px;
  font-size: 14px;
  color: var(--color-black);
}

.pmp-compound__wrapper {
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15);
  border-radius: 4px;
  background-color: var(--color-gray-bg);
}
.pmp-compound__wrapper.active .pmp-compound__icon img {
  transform: scale(1, -1);
}
.pmp-compound__head {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 5px 15px;
}
.pmp-compound__title {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 15px;
  color: var(--color-black);
}
.pmp-compound__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
}
.pmp-compound__icon img {
  transition: transform 0.3s ease 0s;
}
.pmp-compound__list {
  padding-bottom: 15px;
}
.pmp-compound__item {
  display: flex;
  align-items: center;
}
.pmp-compound__item:last-child .pmp-compound__img {
  transform: scale(1, -1);
}
.pmp-compound__img {
  flex: 0 1 33.333%;
  margin-right: 10px;
}
.pmp-compound__img img {
  max-width: 100%;
  vertical-align: top;
}
.pmp-compound__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 66.666%;
  font-size: 15px;
  line-height: 1;
  text-decoration: underline;
  color: var(--color-primary-dark);
}
.pmp-compound__text.is-not-link {
  text-decoration: none;
  color: var(--color-main);
}
.pmp-size.global-btn,
.pmp-size {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-primary);
}

.pdp {
  font-family: "Rubik", sans-serif;
}
.pdp * {
  box-sizing: border-box;
}

.pdp-link-underline {
  display: inline-flex;
  align-items: center;
  color: var(--color-link);
  min-height: 24px;
  cursor: pointer;
}
.pdp-link-underline span {
  position: relative;
}
.pdp-link-underline span::after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  height: 1px;
  bottom: 0;
  background-color: var(--color-link);
  opacity: 0;
  transition: opacity 0.2s ease 0s;
}
.pdp-link-underline img {
  margin-right: 8px;
}
.pdp-link-underline:focus span::after {
  opacity: 1;
}
@media (any-hover: hover) {
  .pdp-link-underline:hover span::after {
    opacity: 1;
  }
}

.pdp-sub {
  display: flex;
  margin-bottom: 20px;
}
.pdp-sub__item {
  margin-right: 25px;
  font-size: 14px;
}
.pdp-sub__item.pdp-sub__item--stars {
  margin-right: 8px;
}
.pdp-sub__item:last-child {
  margin-right: 0;
}
.pdp-sub .link-underline {
  display: inline-flex;
  align-items: center;
  color: var(--color-link);
}
.pdp-sub .pmp-rating__review {
  display: none;
}
.pdp-sub .pmp-rating {
  margin-top: 0;
}

h1.pdp-title,
.pdp-title {
  font-weight: 500;
  font-size: 35px;
  line-height: 120%;
  color: var(--color-main);
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: "Rubik", sans-serif;
}

.pdp-wrapper {
  display: grid;
  grid-template-columns: calc(100% - 440px) 400px;
  column-gap: 40px;
  margin-bottom: 100px;
}
@media (max-width: 1170px) {
  .pdp-wrapper {
    grid-template-columns: calc(100% - 420px) 400px;
    column-gap: 20px;
  }
}
.pdp-wrapper__item {
  margin-bottom: 20px;
}
.pdp-wrapper__item:last-child {
  margin-bottom: 0;
}
.pdp-wrapper__total-sticky {
  position: sticky;
  top: 20px;
}
.pdp-city-selection {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 40px rgba(51, 51, 51, 0.1);
  font-size: 14px;
  color: var(--color-main);
}
.pdp-city-selection__inner {
  z-index: 1;
  padding: 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  background-color: #F8FAFC;
  position: relative;
}
.pdp-city-selection__top {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  align-items: baseline;
  justify-content: space-between;
}
.pdp-city-selection__title {
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  margin-right: 16px;
}
.pdp-city-selection__link {
  white-space: nowrap;
  color: var(--color-link);
  cursor: pointer;
}
.pdp-city-selection__item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.pdp-city-selection__item:last-child {
  margin-bottom: 0;
}
.pdp-city-selection__item-i {
  color: var(--color-primary-dark);
  margin-right: 10px;
}

.pdp-test {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EFF0F4;
  border-radius: 6px;
  font-size: 23px;
  color: var(--color-gray);
  width: 100%;
}

.pdp-swiper {
  min-height: 380px;
  display: flex;
  flex-shrink: 0;
  margin-right: 70px;
}
@media (max-width: 1400px) {
  .pdp-swiper {
    margin-right: 40px;
  }
}
@media (max-width: 1300px) {
  .pdp-swiper {
    margin-right: 20px;
  }
}
@media (max-width: 1250px) {
  .pdp-swiper {
    margin-right: 0;
    width: 100%;
  }
}

.pdp-swiper-right {
  position: relative;
  height: 380px;
  width: 380px;
  margin: 0;
}
@media (max-width: 1250px) {
  .pdp-swiper-right {
    margin: 0 auto;
  }
}
.pdp-swiper-right__slide {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.pdp-swiper-right__slide-video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: var(--color-primary-dark);
}
.pdp-swiper-right__slide > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.pdp-swiper-right__achiv {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  left: 0;
  top: 0;
}

.pdp-swiper-left {
  width: 60px;
  margin-right: 40px;
}
@media (max-width: 1400px) {
  .pdp-swiper-left {
    margin-right: 20px;
  }
}
@media (max-width: 1250px) {
  .pdp-swiper-left {
    margin-right: 0;
  }
}
.pdp-swiper-left__swiper {
  max-height: 320px;
  margin: 10px 0;
}
.pdp-swiper-left__arrow {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid var(--color-gray-blue);
  transition: background-color 0.2s ease 0s;
  background-color: #F8FAFC;
  color: var(--color-primary-dark);
}
.pdp-swiper-left__arrow--bottom svg {
  transform: rotate(180deg);
}
.pdp-swiper-left__arrow:not(.swiper-button-disabled) {
  cursor: pointer;
}
.pdp-swiper-left__arrow:not(.swiper-button-disabled):hover {
  background-color: var(--color-gray-blue);
}
.pdp-swiper-left__wrapper {
  flex-direction: column;
  height: auto;
}
.pdp-swiper-left__slide {
  height: 60px;
  width: 60px;
  margin-bottom: 5px;
  border: 1px solid transparent;
  transition: border-color 0.2s ease 0s;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.pdp-swiper-left__slide.swiper-slide-thumb-active, .pdp-swiper-left__slide:hover, .pdp-swiper-left__slide:focus {
  border-color: var(--color-primary-dark);
}
.pdp-swiper-left__slide > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.pdp-swiper-left__slide-video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: var(--color-primary-dark);
}
.pdp-swiper-left__slide:last-child {
  margin-bottom: 0;
}

.pdp-main {
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  padding: 20px 40px 20px 20px;
  display: flex;
}
@media (max-width: 1400px) {
  .pdp-main {
    padding: 20px;
  }
}
@media (max-width: 1250px) {
  .pdp-main {
    flex-wrap: wrap;
  }
}
.pdp-main__content {
  padding-top: 30px;
  flex-grow: 1;
}
.pdp-main__content-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 16px;
}
.pdp-main__content-spec {
  margin-bottom: 14px;
  max-width: 350px;
  overflow: hidden;
  padding-bottom: 2px;
}
@media (max-width: 1250px) {
  .pdp-main__content-spec {
    max-width: 100%;
  }
}
.pdp-main__btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
  box-sizing: border-box;
}
.pdp-main__btns > div {
  padding: 0 5px 10px;
  flex-grow: 1;
}
.pdp-main__btns > div > button {
  min-width: 140px;
  width: 100%;
}
.pdp-main__indicators {
  margin-top: 20px;
}

.pdp-spec .ppl-spec__item:not(:last-child) {
  margin-bottom: 12px;
}
.pdp-spec .ppl-spec__parameter {
  color: var(--color-less-light-gray);
}
.pdp-spec .ppl-spec__parameter::before {
  border-color: #EFF0F4;
}
.pdp-spec .ppl-spec__value {
  color: var(--color-main);
  text-decoration: none;
}
.pdp-spec .ppl-spec__value a {
  position: relative;
  color: var(--color-primary-dark);
}
.pdp-spec .ppl-spec__value a::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  height: 1px;
  bottom: 0;
  background-color: var(--color-primary-dark);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}
.pdp-spec .ppl-spec__value a:hover::after, .pdp-spec .ppl-spec__value a:focus::after {
  opacity: 1;
}

.pdp-like-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray);
  transition: all 0.2s ease 0s;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  outline: none;
  border: 1px solid var(--color-gray-blue);
  border-radius: 100px;
  padding: 0 16px;
  background-color: #F8FAFC;
  white-space: nowrap;
  line-height: 1;
}
.pdp-like-btn > svg {
  transition: color 0.2s ease 0s;
  margin-right: 6px;
  flex-shrink: 0;
}
.pdp-like-btn > span {
  padding-top: 2px;
}
.pdp-like-btn:hover, .pdp-like-btn:focus {
  outline: none;
  background-color: var(--color-gray-blue);
}
.pdp-like-btn .pdp-like-btn__active {
  display: none;
}
.pdp-like-btn.active {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}
.pdp-like-btn.active .pdp-like-btn__active {
  display: inline;
}
.pdp-like-btn.active .pdp-like-btn__no-active {
  display: none;
}

.pdp-tabs {
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  padding: 20px;
}
.pdp-tabs__titles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin-bottom: 14px;
}
.pdp-tabs__titles-item {
  padding: 7px 16px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 6px;
  color: var(--color-gray);
  transition: all 0.2s ease 0s;
  margin-right: 10px;
  margin-bottom: 6px;
  white-space: nowrap;
}
.pdp-tabs__titles-item.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.pdp-tabs__titles-item:not(.active):hover, .pdp-tabs__titles-item:not(.active):focus {
  background-color: var(--color-gray-blue);
  cursor: pointer;
  color: var(--color-gray);
}
.pdp-tabs__sections-item {
  display: none;
}
.pdp-tabs__sections-item.active {
  display: block;
}

.pdp-compound {
  padding-top: 6px;
}
.pdp-compound__item {
  display: flex;
  align-items: center;
  padding: 2px 0;
}
.pdp-compound__item:last-child .pdp-compound__item-img {
  transform: scale(1, -1);
}
.pdp-compound__item-img {
  flex: 0 1 120px;
  margin-right: 20px;
}
.pdp-compound__item-text {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.pdp-compound__item-text .pdp-mark {
  margin-left: 10px;
}

.pdp-mark {
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-primary-dark);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-dark);
  transition: all 0.2s ease 0s;
}
.pdp-mark:hover {
  background-color: var(--color-primary-dark);
  color: var(--color-white);
  box-shadow: 0px 10px 20px rgba(49, 49, 49, 0.08);
}

.pdp-characteristics__title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 20px;
}
.pdp-characteristics__content {
  column-count: 2;
  column-gap: 40px;
}
@media (max-width: 1250px) {
  .pdp-characteristics__content {
    column-gap: unset;
    column-count: unset;
  }
}

.pdp-instructions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
.pdp-instructions__item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--color-gray);
  border-radius: 20px;
  border: 1px solid var(--color-gray-blue);
  transition: border-color 0.2s ease 0s;
  padding: 20px 40px 20px 20px;
  cursor: pointer;
}
.pdp-instructions__item:hover, .pdp-instructions__item:focus {
  border-color: var(--color-primary-dark);
}
.pdp-instructions__item-title {
  font-weight: 500;
  font-size: 15px;
  color: var(--color-main);
  margin-bottom: 10px;
}
.pdp-instructions__item-text {
  font-size: 14px;
  margin-bottom: 16px;
}
.pdp-instructions__item-bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdp-instructions__item-icon {
  color: var(--color-primary-dark);
  flex-shrink: 0;
  margin-left: 10px;
}
.pdp-instructions__item-file {
  color: var(--color-primary-dark);
  font-weight: 400;
  font-size: 14px;
}

.pdp-user-section {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-gray);
  line-height: 1.4;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
}
.pdp-user-section p {
  margin-bottom: 16px;
  font-family: "Rubik", sans-serif;
}
.pdp-user-section h1, .pdp-user-section h2, .pdp-user-section h3, .pdp-user-section h4, .pdp-user-section h5, .pdp-user-section h6 {
  color: var(--color-main);
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
}
.pdp-user-section img {
  max-width: 100%;
  width: auto;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.pdp-user-section a {
  text-decoration: none;
  color: var(--color-primary-dark);
  position: relative;
}
.pdp-user-section a::after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  height: 1px;
  bottom: 0;
  background-color: var(--color-primary-dark);
  opacity: 0;
  transition: opacity 0.2s ease 0s;
}
.pdp-user-section a:focus::after {
  opacity: 1;
}
@media (any-hover: hover) {
  .pdp-user-section a:hover::after {
    opacity: 1;
  }
}
.pdp-user-section ul, .pdp-user-section ol {
  margin-bottom: 16px;
  text-align: left;
  max-width: 640px;
}
.pdp-user-section ul {
  list-style-type: none;
  padding-left: 0;
}
.pdp-user-section ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
}
.pdp-user-section ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
  position: absolute;
  left: 0;
  top: 4px;
}
.pdp-user-section ul li:last-child {
  margin-bottom: 0;
}
.pdp-user-section :last-child {
  margin-bottom: 0 !important;
}
.pdp-user-section :first-child {
  margin-top: 0 !important;
}

.pdp-price {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 40px rgba(51, 51, 51, 0.1);
  font-size: 15px;
  color: var(--color-main);
}
.pdp-price__inner {
  display: flex;
  flex-direction: column;
  min-height: 422px;
  z-index: 1;
  padding: 20px;
  border-radius: 6px;
  background-color: var(--color-white);
  position: relative;
}
.pdp-price__count {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #F8FAFC;
  justify-content: space-between;
}
.pdp-price__count-title {
  font-weight: 400;
  font-size: 15px;
  color: var(--color-main);
}
.pdp-price__count-mp {
  flex-shrink: 0;
  margin-left: 10px;
}
.pdp-price__price {
  padding: 20px 0;
  margin-bottom: auto;
}
.pdp-price__price-top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.pdp-price__price-title {
  font-weight: 400;
  font-size: 15px;
  color: var(--color-main);
}
.pdp-price__price-num {
  font-weight: 400;
  font-size: 23px;
  color: var(--color-main);
}
.pdp-price__price-num.active {
  color: var(--color-error);
}
.pdp-price__price-old {
  text-align: right;
  font-weight: 400;
  font-size: 15px;
  color: var(--color-gray);
  text-decoration: line-through;
}

.pdp-count {
  display: flex;
  align-items: center;
}
.pdp-count__count {
  text-align: center;
  flex-shrink: 0;
  width: 40px;
  margin: 0 4px;
  font-weight: 400;
  font-size: 15px;
}
.pdp-count__btn {
  flex-shrink: 0;
  cursor: default;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background-color: #F8FAFC;
  border: 1px solid var(--color-gray-blue);
  color: var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  outline: none;
}
.pdp-count__btn:hover, .pdp-count__btn:focus {
  outline: none;
}
.pdp-count__btn.active {
  color: var(--color-primary);
}
.pdp-count__btn.active:hover, .pdp-count__btn.active:focus {
  cursor: pointer;
  background-color: var(--color-gray-blue);
}

.pdp-buy {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.pdp-buy.active .pdp-buy__text-1 {
  display: none;
}
.pdp-buy.active .pdp-buy__block-text {
  display: block;
}
.pdp-buy__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 5px 15px;
  margin-top: auto;
  border-radius: 100px;
  background-color: var(--color-primary);
  line-height: 1;
  width: 100%;
  transition: background-color 0.2s;
}
.pdp-buy__btn:hover {
  outline: none;
  background-color: var(--color-primary-dark);
  cursor: pointer;
}
.pdp-buy__text-1 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
}
.pdp-buy__block-text {
  display: none;
}
.pdp-buy__text-2 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
}
.pdp-buy__text-3 {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-white);
}

.pdp-buy-also {
  border: 1px solid #EFF0F4;
  border-radius: 6px;
  padding: 20px;
  max-height: 370px;
  overflow-y: auto;
  scrollbar-color: #C1C1C1 #F8FAFC;
  scrollbar-width: thin;
}
.pdp-buy-also::-webkit-scrollbar {
  box-sizing: border-box;
  width: 15px;
  border: 1px solid var(--color-gray-blue);
  border-right: none;
  border-radius: 6px;
}
.pdp-buy-also::-webkit-scrollbar-thumb {
  box-sizing: border-box;
  background: #C1C1C1;
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: content-box;
}
.pdp-buy-also__title {
  font-weight: 500;
  font-size: 15px;
}
.pdp-buy-also__item {
  padding: 15px 0;
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
  border-bottom: 1px solid var(--color-gray-blue);
}
.pdp-buy-also__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.pdp-buy-also__item-img {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  margin-right: 10px;
}
.pdp-buy-also__item-img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top center;
}
.pdp-buy-also__item-right {
  flex-grow: 1;
}
.pdp-buy-also__item-title {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--color-main);
  display: block;
}
.pdp-buy-also__item-price {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 10px;
}
.pdp-buy-also__item-control {
  display: flex;
  align-items: center;
}
.pdp-buy-also__item-btn {
  height: 38px;
  padding: 5px;
  border-radius: 100px;
  margin-right: 8px;
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: color 0.2s, background-color 0.2s;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  outline: none;
  font-weight: 500;
  font-size: 14px;
}
.pdp-buy-also__item-btn.active {
  color: var(--color-white);
  background-color: var(--color-primary);
}
.pdp-buy-also__item-btn.active .pdp-buy-also__item-btn-no-active {
  display: none;
}
.pdp-buy-also__item-btn.active .pdp-buy-also__item-btn-active {
  display: inline;
}
.pdp-buy-also__item-btn:hover {
  cursor: pointer;
  outline: none;
  color: var(--color-white);
  background-color: var(--color-primary);
}
.pdp-buy-also__item-btn-active {
  display: none;
}

.pdp-reviews-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: var(--color-link);
  min-height: 24px;
  cursor: pointer;
}
.pdp-reviews-link::before {
  content: "";
  position: absolute;
  left: -10px;
  right: -10px;
  bottom: -30px;
  top: 0;
  z-index: 1;
  visibility: hidden;
}
.pdp-reviews-link:hover::before {
  visibility: visible;
}
.pdp-reviews-link:hover .pdp-reviews-link__btn {
  opacity: 1;
  visibility: visible;
}
.pdp-reviews-link__btn {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  padding: 6px 7px;
  background: var(--color-white);
  color: var(--color-black);
  line-height: 1;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
  display: block;
  z-index: 2;
  transition: opacity 0.2s ease 0s, background-color 0.2s ease;
  opacity: 0;
  visibility: hidden;
}
.pdp-reviews-link__btn:hover {
  background-color: #F8FAFC;
}

.pdp-share {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: var(--color-link);
  min-height: 24px;
}
.pdp-share:hover .pdp-share__list {
  opacity: 1;
  visibility: visible;
}
.pdp-share > img {
  margin-right: 8px;
}
.pdp-share__list {
  position: absolute;
  z-index: 2;
  left: -8px;
  top: 25px;
  transition: opacity 0.2s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.pdp-share__list:hover {
  opacity: 1;
  visibility: visible;
}
.pdp-share__list::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: -30px;
  left: -10px;
  right: -10px;
  bottom: -10px;
}
.pdp-share__list-link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  background: var(--color-white);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.pdp-share__list-link:hover {
  background-color: #F8FAFC;
}

.pdp-size {
  cursor: pointer;
  color: var(--color-primary);
  margin-top: 20px;
  transition: color 0.2s ease 0s, border-color 0.2s ease 0s !important;
}
.pdp-size:hover, .pdp-size:focus {
  color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.pdp-certificates {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
@media (max-width: 1300px) {
  .pdp-certificates {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1150px) {
  .pdp-certificates {
    grid-template-columns: repeat(2, 1fr);
  }
  .pdp-certificates .clm-certificat {
    min-height: 200px;
  }
}
.pdp-certificates .clm-certificat {
  min-height: 280px;
}

.ppl {
  font-family: "Rubik", sans-serif;
}
.ppl * {
  box-sizing: border-box;
}
.ppl img {
  vertical-align: top;
}
.ppl__body {
  background: var(--color-white);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
}

.ppl-img {
  width: 100%;
  height: 270px;
  margin-bottom: 10px;
  position: relative;
}
.ppl-img img:not(.no-style-img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ppl-img__close {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
}

.ppl-container {
  padding: 0 25px 50px;
}
@media (max-width: 1024px) {
  .ppl-container {
    padding: 0 20px 20px;
  }
}

.ppl-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .ppl-head {
    padding-top: 20px;
  }
  .ppl-head .clm-only-mobile {
    display: flex;
  }
}
@media (min-width: 1025px) {
  .ppl-head {
    padding-bottom: 20px;
    border-bottom: 2px solid #F8FAFC;
    margin-bottom: 20px;
  }
}
.ppl-head__back {
  display: flex;
  align-items: center;
  flex: 0 0 70px;
  cursor: pointer;
}
.ppl-head__img {
  margin-right: 10px;
  margin-bottom: 2px;
}
.ppl-head__text {
  font-size: 15px;
  line-height: 1;
  color: var(--color-gray);
}
.ppl-head__title {
  padding: 0 5px;
  font-size: 23px;
  font-weight: 500;
  color: var(--color-black);
}
@media (max-width: 1024px) {
  .ppl-head__title {
    font-size: 17px;
  }
}
@media (min-width: 1025px) {
  .ppl-head__title {
    padding-left: 0;
  }
}
.ppl-head__forward {
  flex: 0 0 70px;
}
.ppl-head__desc-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 100px;
  background-color: var(--color-gray-bg);
  cursor: pointer;
}
@media (any-hover: hover) {
  .ppl-head__desc-close:hover {
    background-color: var(--color-gray-blue);
  }
}
@media (min-width: 1025px) {
  .ppl-head__desc-close.clm-only-desctop {
    display: flex;
  }
}

.ppl-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  color: var(--color-black);
}
@media (min-width: 1025px) {
  .ppl-title {
    font-size: 19px;
  }
}

.ppl-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray);
}
@media (min-width: 1025px) {
  .ppl-text {
    font-size: 15px;
  }
}
.ppl-text p {
  font-family: inherit;
  font-size: inherit;
  margin: 10px 0;
}
.ppl-text ul {
  margin: 10px 0;
}
.ppl-text ul li {
  display: flex;
  align-items: center;
}
.ppl-text ul li::before {
  content: "";
  display: inline-block;
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--color-primary-dark);
}
.ppl-text ul li:not(:last-child) {
  margin-bottom: 10px;
}

.ppl-price {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  font-family: "Rubik", sans-serif;
}
.ppl-price__current {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-black);
}
.ppl-price__old {
  margin-left: 8px;
  font-size: 15px;
  color: var(--color-gray);
  text-decoration: line-through;
}

.ppl-bottom {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.ppl-bottom__back {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  border-radius: 50%;
  height: 50px;
  background-color: var(--color-gray-bg);
  margin-right: 15px;
}
@media (min-width: 1025px) {
  .ppl-bottom__back {
    border: 1px solid var(--color-gray-blue);
  }
  .ppl-bottom__back:hover {
    cursor: pointer;
    background-color: var(--color-gray-blue);
  }
}
.ppl-bottom__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  min-height: 50px;
  border-radius: 100px;
  background-color: var(--color-primary);
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
  cursor: pointer;
}

.ppl-content--cloth .ppl-title {
  display: none;
}
.ppl-content--description {
  font-family: "Rubik", sans-serif;
  color: var(--color-gray);
}
.ppl-content--description h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.ppl-content--description h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.ppl-content--description h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.ppl-content--description h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.ppl-content--description h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.ppl-content--description h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.ppl-content--description p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: inherit;
  color: inherit;
}
.ppl-content--description ul, .ppl-content--description ol {
  margin: 1em 0;
  padding: 0;
  list-style-position: inside;
}
.ppl-content--description ul li, .ppl-content--description ol li {
  margin: 0.5em 0;
  color: var(--color-gray);
  list-style-type: none;
}
.ppl-content--description ul li {
  position: relative;
  padding-left: 20px;
}
.ppl-content--description ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
  position: absolute;
  top: 4px;
  left: 0;
}
.ppl-content--description ul li:last-child {
  margin-bottom: 0;
}
.ppl-content--description ol {
  counter-reset: num;
}
.ppl-content--description ol li {
  position: relative;
  padding-left: 20px;
  counter-increment: num;
}
.ppl-content--description ol li::before {
  position: absolute;
  content: counter(num);
  top: 0;
  left: 0;
  color: var(--color-primary-dark);
}
.ppl-content--description table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  border-color: #d3d8dc;
}
.ppl-content--description tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.ppl-content--description th, .ppl-content--description td {
  width: auto;
  padding: 0.8em 0.6em;
  border: 1px solid var(--color-gray-blue);
  background-color: #F8FAFC;
  font-size: 1em;
  color: var(--color-gray);
}
.ppl-content--description td {
  vertical-align: top;
  text-align: left;
}
.ppl-content--description img {
  max-width: 100%;
  width: auto;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ppl-content--description a {
  text-decoration: none;
  color: inherit;
  position: relative;
  border-bottom: 1px solid transparent;
  color: var(--color-primary);
  transition: color 0.2s ease 0s;
}
.ppl-content--description a:focus {
  color: var(--color-primary-dark);
}
@media (any-hover: hover) {
  .ppl-content--description a:hover {
    color: var(--color-primary-dark);
  }
}
.ppl-content--description :last-child {
  margin-bottom: 0;
}
.ppl-content--description :first-child {
  margin-top: 0;
}

.ppl-bottom-exit {
  margin-top: 20px;
}

.ppl-spec {
  margin-top: 20px;
  margin-bottom: 10px;
}
.ppl-spec__item {
  display: flex;
  align-items: flex-end;
}
.ppl-spec__item:not(:last-child) {
  margin-bottom: 15px;
}
.ppl-spec__parameter {
  position: relative;
  flex: 1 1 auto;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
}
.ppl-spec__parameter::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.2em;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-bottom: 1px dashed var(--color-light-gray);
}
.ppl-spec__parameter span {
  position: relative;
  padding-right: 3px;
  background-color: var(--color-white);
}
.ppl-spec__value {
  padding-left: 3px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: var(--color-main);
  text-align: right;
  overflow-wrap: anywhere;
}
.ppl-spec__value.is-link {
  text-decoration: underline;
  color: var(--color-primary);
}
.ppl-spec__link {
  color: inherit;
}

.ppl-cert {
  margin-top: 20px;
}
.ppl-cert .swiper {
  padding-bottom: 20px;
}
.ppl-cert .swiper-wrapper {
  min-height: 200px;
}
.ppl-cert .swiper-slide {
  height: auto;
}
.ppl-cert .swiper-pagination {
  bottom: 0px;
}
.ppl-cert .swiper-pagination .swiper-pagination-bullet {
  flex: 0 1 30px;
  height: 5px;
}
.ppl-cert__title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
}
.ppl-cert__description {
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray);
}
.ppl-cert__img {
  width: 100%;
  height: 460px;
}
.ppl-cert__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ppl-doc {
  margin-top: 20px;
  margin-bottom: 10px;
}
.ppl-doc__item {
  display: flex;
  align-items: center;
}
.ppl-doc__item:not(:last-child) {
  margin-bottom: 40px;
}
.ppl-doc__img {
  margin-right: 10px;
}
.ppl-doc__text {
  font-size: 15px;
}
.ppl-doc__title {
  margin-bottom: 3px;
  font-weight: 500;
  color: var(--color-black);
}
.ppl-doc__link {
  text-decoration: underline;
  color: var(--color-primary-dark);
}

.ppl-review {
  margin-top: 20px;
}
.ppl-review__title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
}
.ppl-review__mark {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--color-gray);
}
.ppl-review__subtitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--color-black);
}
.ppl-review__description {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  color: var(--color-gray);
}
.ppl-review__form {
  margin-top: 15px;
  margin-bottom: 10px;
}
.ppl-review__line:not(:last-child) {
  margin-bottom: 15px;
}
.ppl-review__input {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: var(--color-gray-bg);
  font-size: 14px;
  color: var(--color-gray);
}
.ppl-review__txtarea {
  width: 100%;
  height: 100px;
  resize: vertical;
  padding: 15px;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: var(--color-gray-bg);
  font-size: 14px;
  color: var(--color-gray);
}
.ppl-review__btn {
  margin-top: 20px;
}

.ppl-rating {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.ppl-rating.active .empty-icons {
  opacity: 0;
}
.ppl-rating.active .empty-icons-active {
  opacity: 1;
}
.ppl-rating.active .fill-icons {
  opacity: 1;
}
.ppl-rating__icons {
  display: inline-block;
  position: relative;
}
.ppl-rating__row {
  display: flex;
}
.ppl-rating__row.empty-icons-active {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ppl-rating__row.fill-icons {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ppl-rating__img {
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
}
.ppl-rating__img:not(:last-child) {
  margin-right: 10px;
}
.ppl-rating__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ppl-rating__review {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: var(--color-gray);
}

.ppl-captcha {
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
}

.ppl-options {
  margin-top: 20px;
  margin-bottom: 10px;
}
.ppl-options .pmp-spoiler-head__icon {
  transform: rotate(-90deg);
}
.ppl-options__item:not(:last-child) {
  margin-bottom: 20px;
}
.ppl-options__title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
}
.ppl-options__title .font-rubik {
  font-weight: 500;
  color: var(--color-black);
}

.ppl-more__item {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid var(--color-gray-bg);
}
.ppl-more__head {
  display: flex;
}
.ppl-more__img {
  flex: 0 1 35%;
  height: 90px;
}
.ppl-more__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ppl-more__info {
  flex: 0 1 65%;
  padding: 10px 0 10px 10px;
}
.ppl-more__title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
}
.ppl-more__description {
  margin-top: 5px;
  color: var(--color-black);
}
.ppl-more__subtitle {
  font-size: 12px;
  line-height: 1;
  color: var(--color-gray);
  margin-top: 5px;
}
.ppl-more__spoiler .pmp-spoiler {
  margin-bottom: 10px;
}

.ppl-more-price {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
.ppl-more-price__current {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-black);
}
.ppl-more-price__old {
  margin-left: 8px;
  font-size: 15px;
  text-decoration: line-through;
  color: var(--color-gray);
}

.ppl-more-buy {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}
.ppl-more-buy.active .ppl-more-buy__btn {
  background-color: var(--color-primary);
  border: none;
  color: var(--color-white);
}
.ppl-more-buy.active .ppl-more-buy__text-1 {
  display: none;
}
.ppl-more-buy.active .ppl-more-buy__text-2 {
  display: block;
}
.ppl-more-buy__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  min-height: 50px;
  padding: 10px 15px;
  border: 1px solid var(--color-primary);
  border-radius: 100px;
  background-color: var(--color-white);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: var(--color-primary);
}
.ppl-more-buy__text-2 {
  display: none;
}
.ppl-more-buy__ctrls {
  display: flex;
  align-items: center;
  flex: 0 0 150px;
  margin-left: 15px;
}
.ppl-more-buy__cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-gray-bg);
}
.ppl-more-buy__cnt svg rect {
  fill: var(--color-gray);
}
.ppl-more-buy__cnt.active svg rect {
  fill: var(--color-primary);
}
.ppl-more-buy__count {
  flex: 0 0 50px;
  text-align: center;
  font-size: 23px;
  color: var(--color-black);
}

.ppl-color {
  margin-top: 20px;
  line-height: 1;
}
.ppl-color__ctrl {
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.15);
  border-radius: 100px;
  background: var(--color-gray-bg);
  height: 44px;
  padding: 0 5px;
  font-weight: 500;
  font-size: 15px;
}
@media (min-width: 1025px) {
  .ppl-color__ctrl {
    height: 50px;
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border: 1px solid var(--color-gray-blue);
  }
}
.ppl-color__ctrl.active .ppl-color__filter.one {
  background-color: transparent;
  color: var(--color-black);
}
.ppl-color__ctrl.active .ppl-color__filter.two {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.ppl-color__filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 50%;
  height: 34px;
  border-radius: 100px;
  text-align: center;
}
@media (min-width: 1025px) {
  .ppl-color__filter {
    height: 40px;
  }
  .ppl-color__filter:hover {
    cursor: pointer;
  }
}
.ppl-color__filter.one {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.ppl-color__filter.two {
  color: var(--color-black);
}

.ppl-color-spoiler {
  margin-top: 20px;
}
@media (min-width: 1025px) {
  .ppl-color-spoiler {
    margin-top: 30px;
  }
  .ppl-color-spoiler:first-child {
    margin-top: 15px;
  }
}
.ppl-color-spoiler.active .ppl-clr-splr__icon img {
  transform: scale(1, -1);
}

.ppl-clr-splr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 10px 15px;
  border-radius: 4px;
  background: var(--color-gray-bg);
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2);
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
}
@media (min-width: 1025px) {
  .ppl-clr-splr {
    box-shadow: 0 5px 10px rgba(49, 49, 49, 0.08);
    border: 1px solid var(--color-gray-blue);
    padding: 9px 19px;
    border-radius: 6px;
  }
}
.ppl-clr-splr__title {
  margin-right: 10px;
}
.ppl-clr-splr__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
}
.ppl-clr-splr__icon img {
  transition: transform 0.3s ease 0s;
}

.green {
  color: var(--color-primary);
}

.ppl-color-select {
  padding-top: 15px;
}
@media (min-width: 1025px) {
  .ppl-color-select {
    padding-top: 20px;
  }
  .ppl-color-select:first-child {
    padding-top: 15px;
  }
}
.ppl-color-select__list:not(:last-child) {
  margin-bottom: 20px;
}
.ppl-color-select__item:not(:last-child) {
  margin-bottom: 20px;
}
.ppl-color-select__title {
  margin-bottom: 15px;
  font-size: 15px;
}
.ppl-color-select__sublist {
  display: grid;
  grid-template-columns: repeat(auto-fit, 54px);
  grid-auto-rows: 54px;
  grid-gap: 12px;
}
.ppl-color-select__subitem.active .ppl-color-select__img {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}
.ppl-color-select__subitem.active .ppl-color-select__color {
  border-color: var(--color-primary);
}
.ppl-color-select__color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ppl-color-select__img {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .ppl-color-select__img {
    transition: box-shadow 0.2s;
    box-shadow: 0 5px 15px rgba(49, 49, 49, 0.1);
  }
  .ppl-color-select__img:hover {
    cursor: pointer;
    box-shadow: 0 5px 20px rgba(49, 49, 49, 0.2);
  }
}
.ppl-color-select__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ppl-color-price {
  margin-top: 20px;
}
.ppl-color-price__item:not(:last-child) {
  margin-bottom: 40px;
}
.ppl-color-price__head {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 500;
  color: var(--color-black);
}
.ppl-color-price__subitem:not(:last-child) {
  margin-bottom: 15px;
}
@media (min-width: 1025px) {
  .ppl-color-price__subitem:not(:last-child) {
    margin-bottom: 20px;
  }
}
.ppl-color-price__title {
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--color-black);
}

.ppl-clr-prs__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 72px);
  grid-gap: 20px;
}
@media (min-width: 1025px) {
  .ppl-clr-prs__item {
    cursor: pointer;
  }
  .ppl-clr-prs__item:hover .ppl-clr-prs__img {
    box-shadow: 0 5px 20px rgba(49, 49, 49, 0.2);
  }
}
.ppl-clr-prs__item.active .ppl-clr-prs__img {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
}
.ppl-clr-prs__item.active .ppl-clr-prs__color {
  border-color: var(--color-primary);
}
.ppl-clr-prs__color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ppl-clr-prs__img {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .ppl-clr-prs__img {
    transition: box-shadow 0.2s;
  }
}
.ppl-clr-prs__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ppl-clr-prs__title {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-black);
}

.ppl-town__section {
  border-top: 1px solid #F8FAFC;
  padding: 20px 0;
}
.ppl-town__section:first-child {
  border-top: none;
}
@media (min-width: 1025px) {
  .ppl-town__section:first-child {
    padding-top: 0;
  }
}
.ppl-town__section-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.ppl-town__section-list {
  font-size: 14px;
}
.ppl-town__section-list > * {
  margin-bottom: 10px;
}
.ppl-town__section-list > *:last-child {
  margin-bottom: 0;
}
.ppl-town__link {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: var(--color-link);
  cursor: pointer;
  border-bottom: 1px solid transparent;
}
.ppl-town__link::after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  height: 1px;
  bottom: 0;
  background-color: var(--color-link);
  opacity: 0;
  transition: opacity 0.2s ease 0s;
}
.ppl-town__link:hover::after, .ppl-town__link:focus::after {
  opacity: 1;
}
.ppl-town__capital {
  font-size: 19px;
  margin-bottom: 10px;
}
.ppl-town__capital:last-child {
  margin-bottom: 0;
}
.ppl-town__search-err {
  font-size: 14px;
  color: var(--color-gray);
}
.ppl-town__input {
  position: relative;
}
.ppl-town__input-input {
  height: 50px;
  border: 1px solid var(--color-lightest-gray);
  color: var(--color-main);
  font-size: 14px;
  border-radius: 6px;
  padding: 1px 50px 0 20px;
  width: 100%;
  transition: border-color 0.2s ease 0s;
}
.ppl-town__input-input:hover, .ppl-town__input-input:focus, .ppl-town__input-input:not(:placeholder-shown) {
  border-color: var(--color-main);
}
.ppl-town__input-icon {
  position: absolute;
  right: 20px;
  top: 16px;
}
.ppl-town__input-hint {
  margin-top: 10px;
  font-size: 13px;
  color: var(--color-gray);
}

.products-indicator {
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .products-indicator {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.products-indicator * {
  box-sizing: border-box;
}
.products-indicator__item:not(:last-child) {
  margin-bottom: 15px;
}
.products-indicator__item .mf-mark {
  position: relative;
  margin-left: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .products-indicator__item .mf-mark:hover {
    background-color: var(--color-primary-lightest);
  }
  .products-indicator__item .mf-mark:hover .float-description {
    opacity: 1;
    transform: translate(0px, 0px);
    pointer-events: all;
  }
}
.products-indicator__title {
  font-size: 0;
}
.products-indicator__title:not(:last-child) {
  margin-bottom: 10px;
}
.products-indicator__text {
  vertical-align: middle;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #909090;
}
.products-indicator__line {
  display: flex;
}
.products-indicator__elem {
  flex: 1 1 auto;
  height: 4px;
  border: 1px solid #DDDDDD;
  border-radius: 2px;
  background-color: #fff;
}
.products-indicator__elem.active {
  border: 1px solid transparent;
}
.products-indicator__elem:not(:last-child) {
  margin-right: 5px;
}

.float-description {
  position: absolute;
  z-index: 25;
  bottom: calc(100% + 2px);
  left: 50%;
  width: 300px;
  overflow-y: auto;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  cursor: default;
  opacity: 0;
  transform: translate(0px, -20px);
  pointer-events: none;
  transition: all 0.2s ease 0s;
  background-color: #F8FAFC;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #333333;
}
.breadcrumbs {
  margin-top: 26px;
}
@media (max-width: 1024px) {
  .breadcrumbs {
    margin-top: 15px;
  }
}
.breadcrumbs__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumbs__item {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
}
.breadcrumbs__item a {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: var(--color-light-gray);
  transition: color 0.2s ease 0s;
}
.breadcrumbs__item a:hover {
  color: var(--color-primary);
}
@media (max-width: 1024px) {
  .breadcrumbs__item a {
    font-weight: 500;
    font-size: 12px;
  }
}
.breadcrumbs__item:not(:last-child)::after {
  content: "/";
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 700;
  font-size: 17px;
  color: var(--color-light-gray);
  transition: color 0.2s ease 0s;
}
@media (max-width: 1024px) {
  .breadcrumbs__item:not(:last-child)::after {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
  }
}
.breadcrumbs__item:last-child a {
  color: var(--color-primary);
}

.clm-description {
  padding: 20px;
  background-color: var(--color-white);
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  line-height: normal;
  color: var(--color-black);
}
@media (max-width: 1024px) {
  .clm-description {
    padding: 0;
  }
}
.clm-description * {
  box-sizing: border-box;
}
.clm-description__chess:not(:last-child) {
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .clm-description__chess:not(:last-child):not(:last-child) {
    margin-bottom: 30px;
  }
}
.clm-description__recommended {
  margin-bottom: 50px;
}
.clm-description__questions {
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .clm-description__questions {
    margin-bottom: 30px;
  }
}
.clm-description .flex-revers {
  flex-direction: row-reverse;
}
.clm-description__list p {
  font-size: 14px;
}
.clm-description__line {
  display: flex;
  align-items: center;
  margin: 0 -20px;
}
.clm-description__line:not(:last-child) {
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .clm-description__line {
    display: block;
    margin: 0;
  }
  .clm-description__line:not(:last-child) {
    margin-bottom: 30px;
  }
}
body .clm-description .clm-description__chess .clm-description__line p {
  margin-top: 0;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  color: var(--color-black);
}
.clm-description .clm-description__line p ~ p {
  margin-top: 0;
}
.clm-description .clm-description__line p:not(:last-child) {
  margin: 0 0 10px 0;
}
.clm-description__item-line {
  flex: 0 0 50%;
  padding: 0 20px;
}
.clm-description__item-line:first-child {
  align-self: flex-start;
}
@media (max-width: 1024px) {
  .clm-description__item-line {
    padding: 0;
  }
  .clm-description__item-line:not(:last-child) {
    margin-bottom: 20px;
  }
}
.clm-description__video {
  position: relative;
  padding-bottom: 56.25%;
}
.clm-description__video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.clm-description__title {
  font-weight: 800;
  font-size: 35px;
  color: var(--color-primary-dark);
}
.clm-description__title:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .clm-description__title {
    font-size: 23px;
  }
  .clm-description__title:not(:last-child) {
    margin-bottom: 10px;
  }
}
.clm-description__img {
  position: relative;
  padding-bottom: 56.25%;
}
@media (max-width: 1024px) {
  .clm-description__img {
    height: 220px;
  }
}
.clm-description__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.clm-description__list {
  list-style: inside;
  counter-reset: num;
}
.clm-description__item {
  display: flex;
  counter-increment: num;
}
.clm-description__item:not(:last-child) {
  margin-bottom: 20px;
}
.clm-description__counter-side {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px 25px 0 0;
}
.clm-description__item:not(:last-child) .clm-description__counter-side::after {
  content: "";
  flex: 1 1 auto;
  width: 1px;
  height: 100%;
  border-left: 1px dashed var(--color-primary-light);
  transform: translateY(10px);
}
@media (max-width: 1024px) {
  .clm-description__counter-side {
    margin: 0 20px 0 0;
  }
}
.clm-description__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
}
.clm-description__counter:before {
  content: counter(num);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--color-white);
}
.clm-description__main {
  margin-top: 40px;
  font-weight: 700;
  font-size: 23px;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .clm-description__main {
    margin-top: 20px;
    font-size: 17px;
  }
}
.clm-description__statistic {
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .clm-description__statistic {
    margin-bottom: 30px;
  }
}

.statistic-desc {
  position: relative;
  padding: 50px;
}
@media (max-width: 1024px) {
  .statistic-desc {
    padding: 0;
  }
}
.statistic-desc__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .statistic-desc__img {
    position: static;
    height: 220px;
  }
}
.statistic-desc__img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.statistic-desc__body {
  position: relative;
  z-index: 10;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .statistic-desc__body {
    position: static;
    min-height: 0;
  }
}
.statistic-desc__item {
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 1024px) {
  .statistic-desc__item {
    min-width: 0;
    padding: 20px 0;
  }
  .statistic-desc__item.big {
    min-width: 0;
  }
}
.statistic-desc__item.big {
  min-width: 580px;
}
.statistic-desc__text {
  font-weight: 700;
  font-size: 35px;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .statistic-desc__text {
    font-size: 23px;
  }
  .statistic-desc__text.small {
    font-size: 17px;
  }
  .statistic-desc__item.big .statistic-desc__text.small {
    margin-top: 10px;
  }
  .statistic-desc__text.big {
    font-size: 39px;
  }
}
.statistic-desc__item.big .statistic-desc__text:first-child {
  margin-bottom: 10px;
}
.statistic-desc__item.big .statistic-desc__text.small {
  margin-top: 30px;
}
.statistic-desc__item .statistic-desc__text.small:not(:last-child) {
  margin-bottom: 10px;
}
.statistic-desc__text.big {
  font-size: 47px;
}
.statistic-desc__text.rubik {
  font-family: "Rubik", sans-serif;
}
.statistic-desc__text.small {
  font-weight: 400;
  font-size: 27px;
}
.statistic-desc__text.bold {
  font-weight: 600;
}
.statistic-desc__icons {
  margin-bottom: 20px;
  display: flex;
}
.statistic-desc__icon {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
}
.statistic-desc__icon svg path {
  fill: var(--color-primary-dark);
}
.statistic-desc__icon:not(:last-child) {
  margin-right: 20px;
}
.statistic-desc__icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1024px) {
  .statistic-desc__icon {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
  }
  .statistic-desc__icon:not(:last-child) {
    margin-right: 10px;
  }
}

.recommended-desc__title {
  margin-bottom: 30px;
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  line-height: 42px;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .recommended-desc__title {
    margin-bottom: 20px;
    font-size: 23px;
  }
}
.recommended-desc__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
@media (max-width: 1024px) {
  .recommended-desc__list {
    display: block;
  }
}
@media (max-width: 1024px) {
  .recommended-desc__item:not(:last-child) {
    margin-bottom: 15px;
  }
}
body .recommended-desc__list .recommended-desc__item p {
  margin-top: 3px;
  text-align: center;
  font-size: 14px;
  line-height: normal;
  color: var(--color-black);
}
.recommended-desc__img {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 15px;
}
.recommended-desc__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recommended-desc__name {
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
}
@media (max-width: 1024px) {
  .recommended-desc__name {
    font-size: 17px;
  }
}

.questions-desc__title {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .questions-desc__title {
    margin-bottom: 20px;
    font-size: 23px;
  }
}
.questions-desc__item {
  border-bottom: 1px solid rgba(49, 49, 49, 0.1);
  padding-bottom: 30px;
}
.questions-desc__item:not(:last-child) {
  margin-bottom: 30px;
}
.questions-desc__item.active .questions-desc__header .questions-desc__img img:first-child {
  opacity: 1;
}
.questions-desc__item.active .questions-desc__header .questions-desc__img img:last-child {
  opacity: 0;
}
.questions-desc__item.active .questions-desc__header .questions-desc__img svg:first-child {
  opacity: 1;
}
.questions-desc__item.active .questions-desc__header .questions-desc__img svg:last-child {
  opacity: 0;
}
@media (any-hover: hover) {
  .questions-desc__item.active .questions-desc__header:hover .questions-desc__hover-img img:first-child {
    opacity: 1;
  }
  .questions-desc__item.active .questions-desc__header:hover .questions-desc__hover-img img:last-child {
    opacity: 0;
  }
  .questions-desc__item.active .questions-desc__header:hover .questions-desc__hover-img svg:first-child {
    opacity: 1;
  }
  .questions-desc__item.active .questions-desc__header:hover .questions-desc__hover-img svg:last-child {
    opacity: 0;
  }
}
.questions-desc__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (any-hover: hover) {
  .questions-desc__header:hover .questions-desc__img img:first-child {
    opacity: 0;
  }
  .questions-desc__header:hover .questions-desc__hover-img img:last-child {
    opacity: 1;
  }
  .questions-desc__header:hover .questions-desc__img svg:first-child {
    opacity: 0;
  }
  .questions-desc__header:hover .questions-desc__hover-img svg:last-child {
    opacity: 1;
  }
}
.questions-desc__text-header {
  margin-right: 20px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .questions-desc__text-header {
    font-size: 17px;
  }
}
.questions-desc__button {
  position: relative;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-left: auto;
}
.questions-desc__button img {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.questions-desc__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.2s ease 0s;
  opacity: 0;
}
.questions-desc__img img:last-child {
  opacity: 1;
}
.questions-desc__img svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.2s ease 0s;
  opacity: 0;
}
.questions-desc__img svg circle {
  stroke: var(--color-primary-dark);
}
.questions-desc__img svg rect {
  fill: var(--color-primary-dark);
}
.questions-desc__img svg:last-child {
  opacity: 1;
}
.questions-desc__hover-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease 0s;
}
.questions-desc__hover-img svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease 0s;
}
.questions-desc__hover-img svg circle {
  fill: var(--color-primary-dark);
  stroke: var(--color-primary-dark);
}
.questions-desc__body {
  font-family: "Raleway", sans-serif;
  max-width: 700px;
  padding: 10px 0 0px 0;
  font-weight: 500;
  margin-right: 50px;
  font-size: 15px;
  line-height: 130%;
}
@media (max-width: 1024px) {
  .questions-desc__body {
    padding: 5px 0 0 0;
  }
  .questions-desc .questions-desc__body {
    font-size: 14px;
  }
  .questions-desc .questions-desc__body p {
    font-size: 14px;
  }
}
body .clm-description .questions-desc .questions-desc__body p {
  margin-top: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  color: var(--color-black);
}
.questions-desc .questions-desc__body p:not(:last-child) {
  margin-bottom: 10px;
}

.help-desc__title {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .help-desc__title {
    margin-bottom: 20px;
    font-size: 23px;
    line-height: 28px;
  }
}
.help-desc__color {
  display: inline-block;
  margin-bottom: 5px;
  color: var(--color-black);
}
.help-desc__line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
@media (max-width: 1024px) {
  .help-desc__line {
    display: block;
  }
}
.help-desc__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.help-desc__item::after {
  display: none;
}
@media (max-width: 1024px) {
  .help-desc__item:not(:last-child) {
    margin-bottom: 30px;
  }
}
.help-desc__img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
.help-desc__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.help-desc__text {
  font-weight: 700;
  font-size: 15px;
  line-height: 130%;
  text-align: center;
}
.help-desc__text:not(:last-child) {
  margin-bottom: 10px;
}
.help-desc__phone {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 130%;
  text-align: center;
  color: var(--color-primary-dark);
}

/*========================================================================================================================================================*/
.clm-container {
  max-width: 1430px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0px auto;
}

/*========================================================================================================================================================*/
.clm-only-mobile {
  display: none;
}
@media (max-width: 1024px) {
  .clm-only-mobile {
    display: block;
  }
}

.clm-only-desctop {
  display: block;
}
@media (max-width: 1024px) {
  .clm-only-desctop {
    display: none;
  }
}

/*========================================================================================================================================================*/
.clm-input {
  width: 100%;
  height: 50px;
  padding: 10px 45px 10px 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  background-color: var(--color-gray-bg);
  font-size: 15px;
  color: var(--color-main);
}
.clm-input:focus {
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2);
}
.clm-input:focus::placeholder {
  opacity: 0;
}
.clm-input::placeholder {
  color: var(--color-gray);
}
.clm-input.error {
  border: 1px solid var(--color-error);
}

/*========================================================================================================================================================*/
.link-underline {
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease 0s;
}
@media (any-hover: hover) {
  .link-underline:hover {
    border-bottom: 1px solid var(--color-link);
  }
}

/*========================================================================================================================================================*/
.clm-captcha {
  margin-top: 8px;
  font-size: 14px;
  color: var(--color-error);
}

/*========================================================================================================================================================*/
.global-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding: 5px 15px;
  background-color: var(--color-white);
  border-radius: 100px;
  border: 1px solid var(--color-primary);
  transition: all 0.2s ease 0s;
}
@media (any-hover: hover) {
  .global-btn:hover {
    border-color: var(--color-primary-dark);
  }
  .global-btn:hover .global-btn__text {
    color: var(--color-primary-dark);
  }
}
.global-btn.color {
  border: none;
  background-color: var(--color-primary);
}
.global-btn.color .global-btn__text {
  color: var(--color-white);
}
@media (any-hover: hover) {
  .global-btn.color:hover {
    background-color: var(--color-primary-dark);
  }
  .global-btn.color:hover .global-btn__text {
    color: var(--color-white);
  }
}
.global-btn__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.global-btn__text {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--color-primary);
  transition: color 0.2s ease 0s;
}

/*========================================================================================================================================================*/
.clm-interactive-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
  transition: background-color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .clm-interactive-btn:hover {
    background-color: var(--color-primary);
  }
}
.clm-interactive-btn__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: top;
}

/*========================================================================================================================================================*/
.clm-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.clm-toggle.active .clm-toggle__icon {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}
.clm-toggle.active .clm-toggle__icon::before {
  background-color: var(--color-white);
  transform: translate(23px, 0px);
}
.clm-toggle__text {
  font-size: 15px;
  margin-right: 20px;
}
.clm-toggle__icon {
  position: relative;
  width: 50px;
  height: 26px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 100px;
  margin-left: auto;
  background: #F8FAFC;
  background-color: #fff;
  transition: all 0.3s ease 0s;
}
.clm-toggle__icon::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 3px;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 50%;
  transform: translate(0px, 0px);
  background-color: var(--color-primary-dark);
  transition: all 0.3s ease 0s;
}

/*========================================================================================================================================================*/
.clm-achieve {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: var(--color-gray-bg);
  pointer-events: none;
}
.clm-achieve--main {
  left: 10px;
  top: 10px;
  max-width: calc(100% - 10px);
  font-family: "Rubik", sans-serif;
}
.clm-achieve--main-mobile {
  top: 10px;
  max-width: calc(100% - 105px);
}
.clm-achieve * {
  box-sizing: border-box;
}
.clm-achieve__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
}
.clm-achieve__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 10px;
}
.clm-achieve__item.text {
  padding: 4px 8px;
  border-radius: 6px;
}
.clm-achieve__item.text.large-height {
  min-height: 32px;
}
.clm-achieve__item.text.large-radius {
  border-radius: 100px;
}
.clm-achieve__item.text-with-icon .clm-achieve__text {
  color: var(--color-main);
}
.clm-achieve__item--hidden {
  display: none;
}
.clm-achieve__badges {
  display: flex;
  flex-wrap: wrap;
}
.clm-achieve__text:not(:last-child) {
  margin-right: 4px;
}
.clm-achieve__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 50%;
}
.clm-achieve__icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/*========================================================================================================================================================*/
body .swiper-pagination {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}
body .swiper-pagination .swiper-pagination-bullet {
  flex: 0 1 10px;
  min-width: 3px;
  height: 3px;
  border-radius: 100px;
  background-color: var(--color-lightest-gray);
  transition: background-color 0.2s ease 0s;
}
body .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary-dark);
}

body .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}

/*========================================================================================================================================================*/
.clm-loader {
  position: relative;
  min-height: 300px;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
}
.ppz .clm-loader {
  position: static;
}
.clm-loader::after {
  content: "";
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border: 5px solid var(--color-primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: load-rotation 1s linear infinite;
}
@keyframes load-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*========================================================================================================================================================*/
/*========================================================================================================================================================*/
.clm-content-block {
  column-gap: 40px;
  column-count: 2;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  line-height: normal;
  color: var(--color-gray);
}
@media (max-width: 1024px) {
  .clm-content-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.clm-content-block h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-content-block h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-content-block h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-content-block h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-content-block h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-content-block h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-content-block p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: inherit;
  color: inherit;
}
.clm-content-block ul, .clm-content-block ol {
  margin: 1em 0;
  padding: 0;
  list-style-position: inside;
}
.clm-content-block ul li, .clm-content-block ol li {
  margin: 0.5em 0;
  color: var(--color-gray);
  list-style-type: none;
}
.clm-content-block ul li {
  position: relative;
  padding-left: 20px;
}
.clm-content-block ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-primary-dark);
  position: absolute;
  top: 4px;
  left: 0;
}
.clm-content-block ul li:last-child {
  margin-bottom: 0;
}
.clm-content-block ol {
  counter-reset: num;
}
.clm-content-block ol li {
  position: relative;
  padding-left: 20px;
  counter-increment: num;
}
.clm-content-block ol li::before {
  position: absolute;
  content: counter(num);
  top: 0;
  left: 0;
  color: var(--color-primary-dark);
}
.clm-content-block table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  border-color: #d3d8dc;
}
.clm-content-block tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.clm-content-block th, .clm-content-block td {
  width: auto;
  padding: 0.8em 0.6em;
  border: 1px solid var(--color-gray-blue);
  background-color: #F8FAFC;
  font-size: 1em;
  color: var(--color-gray);
}
.clm-content-block td {
  vertical-align: top;
  text-align: left;
}
.clm-content-block img {
  max-width: 100%;
  width: auto;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.clm-content-block a {
  text-decoration: none;
  color: inherit;
  position: relative;
  border-bottom: 1px solid transparent;
  color: var(--color-primary);
  transition: color 0.2s ease 0s;
}
.clm-content-block a:focus {
  color: var(--color-primary-dark);
}
@media (any-hover: hover) {
  .clm-content-block a:hover {
    color: var(--color-primary-dark);
  }
}
.clm-content-block :last-child {
  margin-bottom: 0;
}
.clm-content-block :first-child {
  margin-top: 0;
}

/*========================================================================================================================================================*/
.tippy-box[data-theme=error-link] {
  box-sizing: border-box;
  background-color: transparent;
}

.tippy-box[data-theme=fft-2] {
  box-sizing: border-box;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-height: 50px;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--color-gray);
  overflow-y: auto;
  transition: all 0.2s ease 0s;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-white);
  pointer-events: none;
}
.tippy-box[data-theme=fft-2][data-placement^=top] {
  transform: translate(0px, -20px);
}
.tippy-box[data-theme=fft-2][data-placement^=right] {
  transform: translate(20px, 0px);
}
.tippy-box[data-theme=fft-2][data-placement^=bottom] {
  transform: translate(0px, 20px);
}
.tippy-box[data-theme=fft-2][data-placement^=left] {
  transform: translate(-20px, 0px);
}
.tippy-box[data-theme=fft-2].hintBig {
  width: 250px;
}
.tippy-box[data-theme=fft-2].animated {
  opacity: 1;
  transform: translate(0px, 0px);
}

/*========================================================================================================================================================*/
.see-more {
  margin-top: 50px;
  font-family: "Rubik", sans-serif;
}
@media (any-hover: hover) {
  .see-more .clm-card__specifications {
    display: block;
  }
  .see-more .clm-card__bottom {
    display: flex;
  }
  .see-more .clm-card:hover .clm-card__wrap {
    position: static;
    width: auto;
    box-shadow: none;
  }
}
.see-more .clm-card {
  height: 100%;
}
.see-more .clm-card__wrap {
  height: 100%;
}
.see-more__container {
  position: relative;
}
@media (max-width: 1430px) {
  .see-more__container {
    padding-bottom: 80px;
  }
}
@media (max-width: 1024px) {
  .see-more__container {
    padding-bottom: 40px;
  }
}
.see-more__container * {
  box-sizing: border-box;
}
.see-more__top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
}
@media (max-width: 1024px) {
  .see-more__top {
    display: block;
    margin-top: 0;
  }
}
.see-more__title {
  margin-right: 20px;
  margin-top: 20px;
  font-size: 35px;
  color: var(--color-main);
}
@media (max-width: 1024px) {
  .see-more__title {
    font-weight: 500;
    font-size: 25px;
    margin-top: 0;
    margin-right: 0;
  }
}
.see-more__filter {
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .see-more__filter {
    margin-top: 10px;
  }
}
.see-more__slider {
  margin-top: 30px;
}
@media (max-width: 1024px) {
  .see-more__slider {
    margin-top: 20px;
  }
}
.see-more__slide {
  display: flex;
  flex-direction: column;
  height: auto;
}
.see-more__pagination {
  display: none;
  position: absolute;
  bottom: 0 !important;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}
@media (max-width: 1024px) {
  .see-more__pagination {
    display: flex;
  }
}
.see-more__pagination .swiper-pagination-bullet {
  flex: 0 1 30px;
  min-width: 3px;
  height: 3px;
  border-radius: 100px;
  margin: 0 5px !important;
  background-color: var(--color-lightest-gray);
  transition: background-color 0.2s ease 0s;
}
.see-more__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary-dark);
}
.see-more__slider-btn {
  position: absolute;
  display: none;
  z-index: 10;
  top: 50%;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--color-gray-bg);
  color: var(--color-primary-dark);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s ease-in-out;
  transform: translate(0px, 50%);
  cursor: pointer;
}
.see-more__slider-btn.swiper-button-disabled {
  display: none;
}
.see-more__slider-btn[aria-disabled=false] {
  display: flex;
}
@media (any-hover: hover) {
  .see-more__slider-btn:hover {
    background-color: var(--color-primary-dark);
    color: var(--color-white);
  }
}
@media (max-width: 1430px) {
  .see-more__slider-btn {
    top: auto;
    bottom: 0;
  }
}
@media (max-width: 1024px) {
  .see-more__slider-btn {
    display: none;
  }
  .see-more__slider-btn[aria-disabled=false] {
    display: none;
  }
}
.see-more__slider-btn--prev {
  left: -40px;
  transform: rotate(-180deg);
}
@media (max-width: 1430px) {
  .see-more__slider-btn--prev {
    left: 50%;
    transform: rotate(-180deg) translate(65px, 0px);
  }
}
.see-more__slider-btn--next {
  right: -40px;
}
@media (max-width: 1430px) {
  .see-more__slider-btn--next {
    right: 50%;
    transform: translate(65px, 0px);
  }
}

.filter-see-more {
  padding-bottom: 10px;
  margin-right: -15px;
  margin-bottom: -10px;
  margin-left: -20px;
  overflow-x: auto;
}
.filter-see-more__wrapper {
  display: flex;
  margin: 0 15px;
}
.filter-see-more__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 30px;
  padding: 5px 15px;
  border-radius: 6px;
  margin: 0 5px;
  font-size: 14px;
  color: var(--color-gray);
  background-color: var(--color-white);
  transition: all 0.2s ease 0s;
  cursor: pointer;
}
@media (any-hover: hover) {
  .filter-see-more__item:hover {
    color: var(--color-white);
    background-color: var(--color-primary);
  }
}
.filter-see-more__item:focus {
  color: var(--color-white);
  background-color: var(--color-primary);
}
.filter-see-more__item.active {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.dsf {
  width: 335px;
  font-family: "Rubik", sans-serif;
  color: var(--color-black);
}
.dsf * {
  box-sizing: border-box;
}
@media (max-width: 1180px) {
  .dsf {
    width: 275px;
  }
}
.dsf__loader {
  display: flex;
  position: relative;
  height: 600px;
}
.dsf__loader::after {
  content: "";
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border: 5px solid var(--color-primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: f-rotation 1s linear infinite;
}
.dsf__inner {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 10px;
}
.dsf__bottom {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 15px;
  z-index: 10;
}
.dsf__btns .dsf__btn {
  margin-bottom: 20px;
}
.dsf__btns .dsf__btn:last-child {
  margin-bottom: 0;
}
.dsf__btns .dsf__btn svg {
  margin-left: 8px;
}
.dsf__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 100%;
  height: 50px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  border-radius: 100px;
  transition: all 0.2s;
  padding-top: 2px;
}
.dsf__btn.disabled {
  cursor: default;
}
.dsf__btn.is-primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.dsf__btn.is-grey {
  background-color: #F8FAFC;
  color: var(--color-gray);
  border: 1px solid var(--color-gray-blue);
}
.dsf__btn.loaded {
  position: relative;
  color: transparent !important;
  cursor: default;
}
.dsf__btn.loaded::after {
  content: "";
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  width: 26px;
  height: 26px;
  border: 2px solid var(--color-white);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: f-rotation 1s linear infinite;
}
.dsf__btn:not(.disabled):not(.loaded):hover, .dsf__btn:not(.disabled):not(.loaded):focus {
  outline: none;
  cursor: pointer;
}
.dsf__btn:not(.disabled):not(.loaded):hover.is-primary, .dsf__btn:not(.disabled):not(.loaded):focus.is-primary {
  background-color: var(--color-primary-dark);
}
.dsf__btn:not(.disabled):not(.loaded):hover.is-grey, .dsf__btn:not(.disabled):not(.loaded):focus.is-grey {
  background-color: var(--color-gray-blue);
  border-color: var(--color-gray-blue);
  color: var(--color-black);
}

.dsf-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
@media (max-width: 1024px) {
  .dsf-popup {
    display: none;
  }
}
.dsf-popup__grid {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 200px) / 3));
  column-gap: 100px;
  width: 100%;
}
.dsf-popup__grid .tbc-section__citem {
  padding-left: 0;
  padding-right: 0;
}
.dsf-popup__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.3);
  transition: opacity 0.2s ease-in-out;
}
.dsf-popup__close {
  cursor: pointer;
  color: var(--color-gray);
  transition: color 0.2s;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 20px;
  flex-shrink: 0;
  background: #F8FAFC;
  border: 1px solid var(--color-gray-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dsf-popup__close:hover, .dsf-popup__close:focus {
  color: var(--color-black);
}
.dsf-popup__title {
  font-weight: 500;
  font-size: 35px;
  color: var(--color-black);
}
.dsf-popup__top {
  flex-shrink: 0;
  padding: 50px 15px 40px 0;
}
.dsf-popup__top-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dsf-popup__content {
  overflow-y: scroll;
  scrollbar-color: #C1C1C1 #F8FAFC;
  scrollbar-width: thin;
}
.dsf-popup__content::-webkit-scrollbar {
  box-sizing: border-box;
  width: 15px;
  border: 1px solid #E2E7F3;
  border-right: none;
  border-radius: 6px;
}
.dsf-popup__content::-webkit-scrollbar-thumb {
  box-sizing: border-box;
  background: #C1C1C1;
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: content-box;
}
.dsf-popup__bottom {
  flex-shrink: 0;
  padding: 40px 15px 40px 0;
}
.dsf-popup__inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
  will-change: transform;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
}
.dsf-popup__act-filters {
  padding-bottom: 40px;
}
.dsf-popup__act-filters-title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 20px;
}

.v-popup-enter-from .dsf-popup__bg,
.v-popup-leave-to .dsf-popup__bg {
  opacity: 0;
}
.v-popup-enter-from .dsf-popup__inner,
.v-popup-leave-to .dsf-popup__inner {
  transform: translateY(100%);
}

.dsf-check-list {
  margin-bottom: -10px;
  display: flex;
  flex-wrap: wrap;
}
.dsf-check-list .dsf-check {
  margin-right: 10px;
  margin-bottom: 10px;
}

.dsf-check {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  border-radius: 100px;
  height: 30px;
  padding: 0 12px;
  color: var(--color-white);
  background-color: var(--color-primary);
  transition: all 0.2s;
}
.dsf-check:hover, .dsf-check:focus {
  background-color: var(--color-primary-dark);
}
.dsf-check > svg {
  flex-shrink: 0;
  margin-left: 8px;
}
.dsf-check.is-grey {
  color: var(--color-gray);
  background-color: #F8FAFC;
  border: 1px solid var(--color-gray-blue);
}
.dsf-check.is-grey:hover, .dsf-check.is-grey:focus {
  background-color: #F8FAFC;
  color: var(--color-black);
}

.open-desk-menu-btn {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 40px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-radius: 6px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-white);
  background-color: var(--color-primary);
}
@media (max-width: 1280px) {
  .open-desk-menu-btn {
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.open-desk-menu-btn__icon {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open-desk-menu-btn__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mcd {
  position: fixed;
  z-index: 2400;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  visibility: hidden;
  font-family: "Rubik", sans-serif;
  scrollbar-color: var(--color-primary-dark) transparent;
  scrollbar-width: thin;
}
.mcd ::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.mcd ::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-dark);
  border-radius: 6px;
}
.mcd * {
  box-sizing: border-box;
}
.mcd.active {
  visibility: visible;
}
.mcd__body {
  position: relative;
  z-index: 2;
  display: flex;
  max-width: calc(100% - 60px);
  transform: translate(-105%, 0px);
  background-color: var(--color-white);
  transition: all 0.3s ease 0s;
}
.mcd.active .mcd__body {
  transform: translate(0px, 0px);
}
.mcd__bg {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.mcd.active .mcd__bg {
  opacity: 1;
}
.mcd__first {
  width: 390px;
  padding: 30px 10px;
  overflow-y: auto;
}
@media (max-width: 1430px) {
  .mcd__first {
    width: 300px;
  }
}
.mcd__second {
  display: none;
  width: 390px;
  padding: 40px 15px 40px 20px;
  border-left: 1px solid var(--color-lightest-gray);
  overflow: auto;
}
@media (max-width: 1430px) {
  .mcd__second {
    width: 300px;
  }
}
.mcd__second.active {
  display: block;
}
.mcd__second-inner {
  display: none;
}
.mcd__second-inner.active {
  display: block;
}
.mcd__spoiler:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-lightest-gray);
  margin-bottom: 20px;
}
.mcd__list:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-lightest-gray);
  margin-bottom: 20px;
}
.mcd__spoiler .mcd__list:not(:last-child) {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.mcd__list--hide {
  display: none;
}
.mcd__item {
  font-weight: 400;
  font-size: 17px;
  color: var(--color-gray);
}
.mcd__item.active svg {
  fill: var(--color-primary-dark);
}
.mcd__item.active svg path {
  fill: var(--color-primary-dark);
}
.mcd__item.active .mcd__link {
  color: var(--color-primary-dark);
}
.mcd__item.active .mcd__text {
  color: var(--color-primary-dark);
}
.mcd__item.active .mcd__arrow {
  opacity: 1;
}
.mcd__first .mcd__item.active .mcd__link {
  background-color: #F8FAFC;
}
.mcd__item svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  fill: var(--color-gray);
}
.mcd__item--title {
  display: flex;
  align-items: center;
  min-height: 40px;
  font-weight: 500;
  font-size: 19px;
  color: var(--color-main);
  cursor: default;
}
.mcd__item--button {
  color: var(--color-primary-dark);
}
.mcd__item--button .mcd__text {
  flex-grow: 0;
}
.mcd__item--button .mcd__arrow {
  opacity: 1;
  transform: rotate(90deg);
}
.mcd__item--more {
  color: var(--color-primary-dark);
}
.mcd__item--more .mcd__arrow {
  opacity: 1;
}
.mcd__item--close {
  color: var(--color-primary-dark);
}
.mcd__item--close .mcd__arrow {
  opacity: 1;
  transform: rotate(180deg);
  margin-left: -10px;
  margin-right: 10px;
}
.mcd__link {
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
}
.mcd__first .mcd__link {
  min-height: 50px;
  padding: 10px;
  border-radius: 6px;
}
.mcd__second .mcd__link {
  min-height: 40px;
  padding: 5px 0;
}
@media (any-hover: hover) {
  .mcd__second .mcd__link:hover svg {
    fill: var(--color-primary-dark);
  }
  .mcd__second .mcd__link:hover .mcd__text {
    color: var(--color-primary-dark);
  }
  .mcd__second .mcd__link:hover .mcd__arrow {
    opacity: 1;
  }
}
.mcd__icon {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.mcd__text {
  flex: 1 1 auto;
}
.mcd__txt {
  min-height: 40px;
  display: flex;
  align-items: center;
}
.mcd__arrow {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  opacity: 0;
  transition: transform 0.2s ease 0s;
}
.mcd__arrow svg {
  fill: var(--color-primary-dark);
}
.mcd__spoiler.active .mcd__arrow {
  transform: rotate(90deg) scale(-1, 1);
}
.mcd__close {
  position: absolute;
  top: 10px;
  right: -40px;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.mcd__close svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: fill 0.3s ease 0s;
  fill: var(--color-white);
}

.mcm {
  font-family: "Rubik", sans-serif;
}
.mcm__body {
  padding: 10px 0px;
}
.mcm__list {
  background-color: var(--color-white);
}
.mcm__list--no-first {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(100%, 0px);
  transition: transform 0.3s ease 0s;
}
.mcm__list--no-first.active {
  transform: translate(0px, 0px);
}
.mcm__item svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mcm__item--title {
  margin-bottom: 10px;
}
.mcm__item--section-title .mcm__link {
  color: var(--color-main);
}
.mcm__btn, .mcm__back-btn, .mcm__link {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  min-height: 40px;
  padding: 5px 0;
  font-size: 15px;
  color: var(--color-gray);
}
.mcm__back-btn {
  font-weight: 500;
  font-size: 17px;
  color: var(--color-black);
}
.mcm__back-btn .mcm__arrow {
  margin-right: 15px;
  margin-left: 0;
}
.mcm__icon {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.mcm__text {
  flex: 1 1 auto;
}
.mcm__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 8px;
  width: 8px;
  height: 14px;
  margin-left: 10px;
}
.mcm__scroll-section {
  height: 100%;
  padding: 10px 15px;
  overflow-y: auto;
}

.feedback {
  font-family: "Rubik", sans-serif;
  color: var(--color-main);
}
.feedback__open-btn {
  width: auto;
  min-width: 170px;
  min-height: 40px;
}
.feedback__open-btn .global-btn__text {
  font-weight: 500;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .feedback__open-btn .global-btn__text {
    font-size: 15px;
  }
}
.feedback__open-btn .ppz-exit__inner {
  display: block;
}
.feedback__open-btn.clm-loader {
  min-height: 40px;
  cursor: default;
}
.feedback__open-btn.clm-loader .global-btn__text {
  color: transparent;
}
.feedback__open-btn.clm-loader::after {
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  border-width: 3px;
  transition: border-color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .feedback__open-btn.clm-loader:hover {
    border-color: var(--color-primary);
  }
  .feedback__open-btn.clm-loader:hover .global-btn__text {
    color: transparent;
  }
}
@media (max-width: 1024px) {
  .feedback__open-btn.ppz-exit__wrapper.high-content {
    min-height: 210px;
    height: auto;
  }
}
.feedback__main {
  margin-top: 20px;
  font-weight: 500;
  font-size: 19px;
}
@media (max-width: 1024px) {
  .feedback__main {
    font-weight: 500;
    font-size: 17px;
  }
}
.feedback__item {
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-gray-blue);
}
.feedback__item:not(:first-child) {
  margin-top: 30px;
}
@media (min-width: 1025px) {
  .feedback__item:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}
.feedback__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .feedback__line {
    flex-wrap: wrap;
  }
}
.feedback__name {
  font-weight: 500;
  font-size: 17px;
}
.feedback__name:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 1024px) {
  .feedback__name {
    flex: 1 1 100%;
    width: 100%;
    order: 2;
    margin-top: 10px;
    font-size: 15px;
  }
  .feedback__name:not(:last-child) {
    margin-right: 0px;
  }
}
.feedback__date-rating {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .feedback__date-rating {
    order: 1;
  }
}
.feedback__date {
  font-size: 13px;
  color: var(--color-gray);
}
.feedback__date:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 1024px) {
  .feedback__date:not(:last-child) {
    margin-right: 10px;
  }
}
.feedback__rating {
  flex-shrink: 0;
}
.feedback__sub-item {
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .feedback__sub-item {
    margin-top: 10px;
  }
}
.feedback__title {
  font-weight: 600;
  font-size: 14px;
}
.feedback__text {
  margin-top: 10px;
  font-size: 14px;
  color: var(--color-gray);
}
.feedback__text p {
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .feedback__text {
    margin-top: 7px;
  }
  .feedback__text p {
    margin-top: 7px;
  }
}
.feedback__slider {
  margin-top: 10px;
}
body .feedback__slider .swiper-pagination .swiper-pagination-bullet {
  flex: 0 1 30px;
  background-color: var(--color-lightest-gray);
}
.feedback__photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .feedback__photos {
    margin-top: 10px;
  }
}
.feedback__photo {
  position: relative;
  padding-bottom: 56.25%;
  cursor: pointer;
}
.feedback__photo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.feedback__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  margin-top: 20px;
  font-size: 15px;
  color: var(--color-gray);
}
@media (max-width: 1024px) {
  .feedback__empty {
    display: block;
    min-height: 0;
  }
}
.feedback__empty .feedback__open-btn {
  min-width: 250px;
  margin-top: 30px;
}
.feedback__empty-text {
  font-size: 39px;
  color: var(--color-less-light-gray);
}
.feedback__load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.feedback__load-more .feedback__open-btn {
  min-width: 250px;
}

.rating-feedback {
  position: relative;
  margin-top: -2px;
}
.rating-feedback__empty {
  display: flex;
  align-items: center;
}
.rating-feedback__fill {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.rating-feedback__img {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 0 3px;
}
.rating-feedback__img svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feedback-form {
  color: var(--color-gray);
}
.feedback-form__text-top {
  font-size: 15px;
}
@media (max-width: 1024px) {
  .feedback-form__text-top {
    font-size: 14px;
  }
}
.feedback-form__stars {
  margin-top: 20px;
}
.feedback-form__line {
  margin-top: 20px;
}
.feedback-form__label {
  display: flex;
  flex-direction: column;
}
.feedback-form__title {
  cursor: pointer;
  font-size: 14px;
}
.feedback-form__input {
  margin-top: 6px;
}
.feedback-form__wrap-toggles {
  margin-top: 20px;
}
.feedback-form__toggle {
  padding: 15px 0;
  border-top: 1px solid var(--color-gray-blue);
}
.feedback-form__toggle:last-child {
  border-bottom: 1px solid var(--color-gray-blue);
}
.feedback-form__body-toggle {
  display: none;
}
.feedback-form__upload-file {
  margin-top: 20px;
}
.feedback-form__captcha {
  margin-top: 20px;
}
.feedback-form__submit .ppz-exit__inner {
  display: block;
}
.feedback-form__btn:not(:last-child) {
  margin-bottom: 20px;
}
.feedback-form__btn .clm-loader {
  position: relative;
  min-height: 50px;
  cursor: default;
}
.feedback-form__btn .clm-loader .global-btn__text {
  color: transparent;
}
.feedback-form__btn .clm-loader::after {
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  border-width: 3px;
  transition: border-color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .feedback-form__btn .clm-loader:hover {
    border-color: var(--color-primary);
  }
  .feedback-form__btn .clm-loader:hover .global-btn__text {
    color: transparent;
  }
}
.feedback-form__rights {
  margin-top: 15px;
  font-size: 13px;
}
.feedback-form__color-rights {
  color: var(--color-link);
}
.feedback-form__success {
  display: none;
  margin-top: 50px;
}
.feedback-form__success-icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #F8FAFC;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
}
.feedback-form__success-title {
  text-align: center;
  font-weight: 500;
  font-size: 23px;
  color: var(--color-primary);
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .feedback-form__success-title {
    font-size: 17px;
  }
}
.feedback-form__success-text {
  font-size: 15px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--color-light-gray);
}
@media (max-width: 1024px) {
  .feedback-form__success-text {
    font-size: 14px;
  }
}
.feedback-form__error {
  display: none;
  margin-top: 50px;
}
.feedback-form__error-title {
  text-align: center;
  font-weight: 500;
  font-size: 23px;
  color: var(--color-error);
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .feedback-form__error-title {
    font-size: 17px;
  }
}
.feedback-form__error-text {
  font-size: 15px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--color-light-gray);
}
@media (max-width: 1024px) {
  .feedback-form__error-text {
    font-size: 14px;
  }
}
.feedback-form__exit {
  display: none;
}

.feedback-stars.error .feedback-stars__error {
  display: block;
}
.feedback-stars__title {
  font-size: 17px;
  color: var(--color-main);
}
.feedback-stars__title sup {
  color: var(--color-error);
}
.feedback-stars__list {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 10px;
}
.feedback-stars__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.feedback-stars__input:checked ~ .feedback-stars__label .feedback-stars__fill {
  opacity: 1;
}
.feedback-stars__label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.feedback-stars__label:not(:first-child) {
  padding-right: 10px;
}
.feedback-stars__label svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (any-hover: hover) {
  .feedback-stars__label:hover .feedback-stars__fill {
    opacity: 1;
  }
  .feedback-stars__label:hover ~ .feedback-stars__label .feedback-stars__fill {
    opacity: 1;
  }
}
.feedback-stars__fill {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: var(--color-primary);
}
.feedback-stars__empty {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  color: var(--color-lightest-gray);
}
.feedback-stars__error {
  display: none;
  margin-top: 8px;
  font-size: 14px;
  color: var(--color-error);
}

.file-feedback {
  position: relative;
}
.file-feedback.drag-over .file-feedback__label {
  display: none;
}
.file-feedback.drag-over .file-feedback__drag-and-drop {
  display: flex;
}
.file-feedback.has-files .file-feedback__label {
  display: none;
}
.file-feedback.has-files .file-feedback__drag-and-drop {
  display: none;
}
.file-feedback.error .file-feedback__error {
  position: relative;
  z-index: 2;
  display: flex;
  cursor: pointer;
}
.file-feedback.error .image-uploader, .file-feedback.error .image-uploader.has-files {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.file-feedback.error .file-feedback__drag-and-drop {
  display: none;
}
.file-feedback__label {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  cursor: pointer;
}
.file-feedback__drag-and-drop {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 122px;
  padding: 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  color: var(--color-primary-dark);
}
.file-feedback__drag-and-drop .file-feedback__img {
  flex: 0 0 25px;
  width: 25px;
  height: 25px;
  padding: 0;
  border: none;
  margin-right: 0;
  margin-bottom: 10px;
}
.file-feedback__error {
  display: none;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  cursor: pointer;
}
.file-feedback__error .file-feedback__text {
  color: var(--color-error);
}
.file-feedback__img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  padding: 15px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  margin-right: 10px;
}
.file-feedback__text {
  font-size: 15px;
}
@media (max-width: 1024px) {
  .file-feedback__text {
    font-size: 14px;
  }
}
.file-feedback .image-uploader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.file-feedback .image-uploader.has-files {
  position: static;
  width: auto;
  height: auto;
  cursor: default;
}
.file-feedback .image-uploader.has-files .upload-text {
  display: none;
}
.file-feedback .image-uploader .uploaded {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -10px -5px 0;
}
.file-feedback .image-uploader .uploaded-image {
  position: relative;
  flex: 0 0 80px;
  height: 80px;
  width: 80px;
  border-radius: 6px;
  margin: 10px 5px 0;
  overflow: hidden;
}
.file-feedback .image-uploader .uploaded-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.file-feedback .image-uploader .delete-image {
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid var(--color-gray-blue);
  background-color: #F8FAFC;
  background-image: url(/img/base/icons/x.svg);
  background-size: 8px 8px;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

/*========================================================================================================================================================*/
.section-title {
  font-family: "Raleway", sans-serif;
  color: var(--color-black);
}
@media (max-width: 1024px) {
  .section-title {
    font-size: 17px;
    font-weight: 700;
    line-height: 130%;
  }
}

.font-rubik {
  font-family: "Rubik", sans-serif;
}

/*========================================================================================================================================================*/
.rds-title {
  margin-top: 20px;
  font-family: "Rubik", sans-serif;
  font-size: 19px;
  font-weight: 500;
  color: var(--color-black);
}

.rds-btn {
  margin-top: 10px;
}

/*========================================================================================================================================================*/
.new-mobile-menu__white-content .new-mobile-menu__white-content-link {
  padding: 10px 0;
}
.old-menu .new-mobile-menu__white-content .new-mobile-menu__white-content-link {
  padding: 0;
}

.old-menu .new-mobile-menu__white:first-child {
  border-top: none;
}
.old-menu .new-mobile-menu__white {
  border-top: 1px solid #F8F7F7;
}
.old-menu .new-mobile-menu__white-title {
  padding-top: 18px;
  padding-bottom: 16px;
}
.old-menu .new-mobile-menu__white-title-text,
.old-menu .new-mobile-menu__white-content-link,
.old-menu .new-mobile-menu__gray-text {
  color: var(--color-black);
}
.old-menu .new-mobile-menu__white-title-text {
  text-transform: uppercase;
  font-size: 15px;
}

.js-ppzStatic[data-name=product-description] .ppl-content {
  font-size: 15px;
  line-height: 1.2;
}

.new-header {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-gray);
}
.new-header * {
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .new-header {
    display: none;
  }
}

.new-top-header {
  background-color: var(--color-gray-bg);
}
.new-top-header__container {
  display: flex;
  align-items: center;
}
.new-top-header__links {
  display: flex;
  flex-wrap: wrap;
  margin-right: 40px;
  margin-left: -30px;
}
.new-top-header__link {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-left: 30px;
  color: var(--color-gray);
  transition: color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .new-top-header__link:hover {
    color: var(--color-primary-dark);
  }
}
.new-top-header__geo {
  margin-left: auto;
  flex-shrink: 0;
}

.new-header-links {
  position: relative;
}
@media (any-hover: hover) {
  .new-header-links:hover .new-header-links__icon svg path {
    fill: var(--color-primary-dark);
  }
}
.new-header-links.active {
  color: var(--color-primary-dark);
}
.new-header-links.active .new-header-links__icon svg {
  transform: scale(1, -1);
}
.new-header-links.active .new-header-links__icon svg path {
  fill: var(--color-primary-dark);
}
.new-header-links__title {
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.new-header-links__icon {
  margin-left: 8px;
}
.new-header-links__icon svg {
  transition: transform 0.2s ease 0s;
}
.new-header-links__icon svg path {
  transition: fill 0.2s ease 0s;
}
.new-header-links__body {
  display: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: -20px;
  width: 200px;
  padding: 20px;
  border-radius: 0 0 6px 6px;
  background-color: var(--color-white);
  box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.2);
}
.new-header-links__item:not(:last-child) {
  margin-bottom: 10px;
}
.new-header-links__link {
  color: var(--color-gray);
  transition: color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .new-header-links__link:hover {
    color: var(--color-primary-dark);
  }
}

.geo-header {
  display: flex;
}
.geo-header button {
  transition: color 0.2s ease 0s;
}
.geo-header__current {
  display: flex;
  align-items: center;
  color: var(--color-gray);
}
@media (any-hover: hover) {
  .geo-header__current:hover {
    color: var(--color-primary-dark);
  }
  .geo-header__current:hover .geo-header__icon svg path {
    fill: var(--color-primary-dark);
  }
}
.geo-header__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.geo-header__icon svg path {
  transition: fill 0.2s ease 0s;
}
.geo-header__ctrl {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.geo-header__ctrl.cookie {
  display: none;
}
.geo-header__button {
  padding: 4px 12px;
  border-radius: 6px;
  background-color: var(--color-primary);
  font-weight: 500;
  font-size: 13px;
  color: var(--color-white);
  transition: background-color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .geo-header__button:hover {
    background-color: var(--color-primary-dark);
  }
}
.geo-header__button:not(:last-child) {
  margin-right: 10px;
}
.geo-header__switch {
  color: var(--color-link);
}
@media (any-hover: hover) {
  .geo-header__switch:hover {
    color: var(--color-link);
  }
}

.new-middle-header__container {
  display: flex;
  align-items: center;
  min-height: 100px;
}
.new-middle-header__left {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
.new-middle-header__left:not(:last-child) {
  margin-right: 30px;
}
.new-middle-header__logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 55px;
}
.new-middle-header__logo a img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.new-middle-header__search {
  display: none;
  flex: 1 1 auto;
  margin-left: 50px;
}
.new-middle-header__phone {
  flex-shrink: 0;
  margin-left: auto;
}
.new-middle-header__phone:not(:last-child) {
  margin-right: 30px;
}
.new-search-header {
  position: relative;
  height: 44px;
}
.new-search-header.active .new-search-header__form {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--color-white);
  box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2);
}
.new-search-header.active .new-search-header__input {
  border-color: var(--color-gray-blue);
  background-color: var(--color-gray-bg);
}
.new-search-header__form {
  display: block;
  border: 1px solid var(--color-lightest-gray);
  border-radius: 6px;
  transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .new-search-header__form:hover {
    box-shadow: 0px 5px 10px rgba(49, 49, 49, 0.2);
    border-color: var(--color-gray-blue);
  }
  .new-search-header__form:hover .new-search-header__input {
    background-color: var(--color-gray-bg);
  }
}
.new-search-header__results {
  max-height: 500px;
  overflow-y: auto;
  scrollbar-color: #DDDDDD #F8FAFC;
  scrollbar-width: thin;
}
.new-search-header__results::-webkit-scrollbar {
  box-sizing: border-box;
  width: 15px;
  border: 1px solid var(--color-gray-blue);
  border-right: none;
  border-radius: 6px;
}
.new-search-header__results::-webkit-scrollbar-thumb {
  box-sizing: border-box;
  background: #DDDDDD;
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: content-box;
}
.new-search-header__results-item {
  display: block;
  padding: 12px 20px;
  color: var(--color-main);
  font-size: 14px;
  line-height: 1.3;
  transition: background-color 0.2s ease 0s;
  position: relative;
}
.new-search-header__results-item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  height: 1px;
  bottom: 0;
  background-color: var(--color-gray-blue);
}
.new-search-header__results-item.is-span {
  color: var(--color-less-light-gray);
}
.new-search-header__results-item:not(.is-span):hover, .new-search-header__results-item:not(.is-span):focus {
  cursor: pointer;
  background-color: #EFF0F4;
}
.new-search-header__content {
  display: none;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}
.new-search-header__content.active {
  display: block;
}
.new-search-header__content-top {
  border-top: 1px solid var(--color-gray-blue);
  background-color: #F8FAFC;
}
.new-search-header__content-bottom {
  background-color: var(--color-white);
  border-top: 1px solid var(--color-gray-blue);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-search-header__line {
  position: relative;
}
.new-search-header__input {
  width: 100%;
  height: 44px;
  padding: 5px 60px 5px 20px;
  background-color: var(--color-white);
  border-radius: 6px;
  color: var(--color-main);
  border: none;
}
.new-search-header__input::placeholder {
  color: var(--color-gray);
}
.new-search-header__button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 100%;
  cursor: default;
}
.new-search-header__button svg {
  vertical-align: top;
}

.new-phone-header {
  text-align: left;
  cursor: pointer;
}
.new-phone-header.active .new-phone-header__icon svg {
  transform: scale(-1, 1);
}
.new-phone-header.active .new-phone-header__text {
  color: var(--color-primary-dark);
}
.new-phone-header.active .new-phone-header__icon svg path {
  fill: var(--color-primary-dark);
}
.new-phone-header.active .new-phone-header__bottom {
  color: var(--color-main);
}
@media (any-hover: hover) {
  .new-phone-header:hover .new-phone-header__text {
    color: var(--color-primary-dark);
  }
  .new-phone-header:hover .new-phone-header__icon svg path {
    fill: var(--color-primary-dark);
  }
  .new-phone-header:hover .new-phone-header__bottom {
    color: var(--color-main);
  }
}
.new-phone-header__top {
  display: flex;
  align-items: center;
}
.new-phone-header__top:not(:last-child) {
  margin-bottom: 3px;
}
.new-phone-header__text {
  font-weight: 500;
  font-size: 19px;
  color: var(--color-main);
  transition: color 0.2s ease 0s;
}
.new-phone-header__icon {
  margin-left: 10px;
}
.new-phone-header__icon svg {
  vertical-align: top;
  transition: transform 0.2s ease 0s;
}
.new-phone-header__icon svg path {
  transition: fill 0.2s ease 0s;
}
.new-phone-header__bottom {
  color: var(--color-gray);
  transition: color 0.2s ease 0s;
}

.new-header-icons {
  display: flex;
  margin: 0 -15px;
}
.new-header-icons__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-gray);
  transition: color 0.2s ease 0s;
  margin: 0 15px;
}
@media (any-hover: hover) {
  .new-header-icons__item:hover {
    color: var(--color-primary-dark);
  }
  .new-header-icons__item:hover .new-header-icons__icon svg path {
    fill: var(--color-primary-dark);
  }
}
.new-header-icons__wrap-icon {
  position: relative;
}
.new-header-icons__icon {
  width: 30px;
  height: 30px;
  margin-bottom: 4px;
}
.new-header-icons__icon svg {
  vertical-align: top;
}
.new-header-icons__icon svg path {
  transition: fill 0.2s ease 0s;
}
.new-header-icons__count {
  position: absolute;
  top: -6px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  background-color: var(--color-primary-dark);
  font-weight: 500;
  font-size: 11px;
  color: var(--color-white);
}

.clm-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: var(--color-white);
}
.clm-card h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-card h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-card h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-card h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-card h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-card h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 700;
  line-height: normal;
  color: var(--color-main);
  text-transform: unset;
}
.clm-card p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}
.clm-card * {
  box-sizing: border-box;
}
@media (any-hover: hover) {
  .clm-card:hover .clm-card__wrap {
    position: absolute;
    z-index: 15;
    width: 100%;
    min-height: 100%;
    box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.1);
  }
  .clm-card:hover .clm-card__bottom {
    display: flex;
  }
  .clm-card:hover .clm-card__info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .clm-card:hover .clm-card__specifications {
    display: block;
  }
  .clm-card:hover .clm-card__remove-favorite {
    z-index: 16;
  }
  .clm-card:hover .clm-card__remove-compare {
    z-index: 16;
  }
}
.clm-card .swiper-pagination {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}
.clm-card .swiper-pagination-bullet {
  flex: 0 1 10px;
  min-width: 3px;
  height: 3px;
  border-radius: 100px;
  background-color: var(--color-lightest-gray);
  transition: background-color 0.2s ease 0s;
}
.clm-card .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary-dark);
}
.clm-card .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .clm-card .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
.clm-card__remove-favorite {
  display: none;
}
.clm-favorites .clm-card__remove-favorite {
  display: flex;
}
.clm-card__remove-compare {
  display: none;
}
.clm-card__remove-compare .product-comparsion {
  display: flex;
}
.clm-card .clm-achieve {
  top: 20px;
  left: 20px;
}
.clm-card__wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-radius: 6px;
  background-color: var(--color-white);
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-main);
}
@media (any-hover: none) {
  .clm-card__wrap {
    border: 1px solid var(--color-gray-blue);
    border-radius: 6px;
  }
}
.clm-card__wrap-slider {
  position: relative;
  border-radius: 6px;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: var(--color-white);
}
.clm-card__img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.clm-card__img.active {
  display: block;
}
.clm-card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: top;
}
@media (min-width: 1025px) {
  .clm-card__img {
    display: none;
  }
}
.clm-card__grid-slider {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
@media (max-width: 1024px) {
  .clm-card__grid-slider {
    display: none;
  }
}
.clm-card__cell-slider {
  flex: 1 1 auto;
}
.clm-card__slider-dots {
  position: absolute;
  z-index: 6;
  bottom: 10px;
  left: 50%;
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  transform: translate(-50%, 0px);
  pointer-events: none;
}
@media (max-width: 1024px) {
  .clm-card__slider-dots {
    display: none;
  }
}
.clm-card__slider-dot {
  flex: 0 1 10px;
  min-width: 3px;
  height: 3px;
  border-radius: 100px;
  background-color: var(--color-lightest-gray);
}
.clm-card__slider-dot.active {
  background-color: var(--color-primary-dark);
}
.clm-card__content-block {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 20px 20px 20px;
}
.clm-card__breadcrumbs.clm-breadcrumbs-card {
  margin: -10px 0 10px -10px;
}
.clm-card__title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 15px;
}
.clm-card__title:not(:last-child) {
  margin-bottom: 10px;
}
.clm-card__specifications {
  display: none;
  margin-top: 10px;
}
@media (any-hover: none) {
  .clm-card__specifications {
    display: block;
  }
}
.clm-card__ctrl-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
}
.clm-card__price {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}
.clm-card__current-price {
  flex-shrink: 0;
  font-size: 25px;
  color: var(--color-main);
}
.clm-card__current-price.double-price {
  color: var(--color-error);
}
.clm-card__old-price {
  flex-shrink: 0;
  font-size: 15px;
  text-decoration: line-through;
  color: var(--color-less-light-gray);
}
.clm-card__bottom {
  display: none;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}
@media (any-hover: none) {
  .clm-card__bottom {
    display: flex;
  }
}
.clm-card__btn {
  width: auto;
  flex: 1 1 auto;
  min-height: 40px;
}
.clm-card__ctrl {
  flex-shrink: 0;
}

.clm-breadcrumbs-card {
  display: flex;
  margin: -10px 0 0 -10px;
}
.clm-breadcrumbs-card__item {
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
  color: var(--color-less-light-gray);
}
.clm-breadcrumbs-card__item:not(:last-child)::after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 10px;
  background-color: var(--color-less-light-gray);
}
.clm-breadcrumbs-card__item:last-child {
  color: var(--color-primary-dark);
}

.clm-param-card__line {
  display: flex;
}
.clm-param-card__line:not(:last-child) {
  margin-bottom: 10px;
}
.clm-param-card__title {
  flex-shrink: 0;
  max-width: 130px;
  color: var(--color-less-light-gray);
}
.clm-param-card__title:not(:last-child) {
  margin-right: 8px;
}
.clm-param-card__description {
  display: flex;
  align-items: flex-start;
}
.clm-param-card__icon {
  display: inline-block;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
.clm-param-card__icon:not(:last-child) {
  margin-top: -3px;
  margin-right: 5px;
}
.clm-param-card__icon img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.clm-info-card {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-radius: 6px;
  background-color: var(--color-gray-bg);
  box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.2);
  overflow: hidden;
}
.clm-info-card__item {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-left: -1px;
}
.clm-info-card__item::before {
  content: "";
  flex: 0 0 1px;
  width: 1px;
  height: calc(100% - 10px);
  border-radius: 100px;
  background: rgba(144, 144, 144, 0.2);
}
.clm-info-card__wrap-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  padding: 6px;
}
.clm-info-card__title {
  font-size: 12px;
  color: var(--color-gray);
}
.clm-info-card__title:not(:last-child) {
  margin-bottom: 3px;
}
.clm-ctrl-card {
  display: flex;
  align-items: center;
  gap: 15px;
}
.clm-ctrl-card__item {
  vertical-align: top;
  flex: 0 0 30px;
  height: 30px;
  width: 30px;
}
.clm-ctrl-card__item svg path {
  transition: fill 0.2s ease 0s;
}
@media (any-hover: hover) {
  .clm-ctrl-card__item:hover svg path {
    fill: var(--color-primary-dark);
  }
}
.clm-ctrl-card__item.active svg path {
  fill: var(--color-primary-dark);
}

.clm-indicator {
  margin-top: 10px;
  margin-bottom: 15px;
}
.clm-indicator * {
  box-sizing: border-box;
}
.clm-indicator__item:not(:last-child) {
  margin-bottom: 8px;
}
.clm-indicator__item .mf-mark {
  position: relative;
  margin-left: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease 0s;
  border-color: var(--color-gray-blue);
  background-color: var(--color-gray-bg);
}
@media (any-hover: hover) and (min-width: 1025px) {
  .clm-indicator__item .mf-mark:hover .float-description {
    opacity: 1;
    transform: translate(0px, 0px);
    pointer-events: all;
  }
}
@media (max-width: 1024px) {
  .clm-indicator__item .float-description {
    display: none;
  }
}
.clm-indicator__mark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.clm-indicator__title {
  font-size: 0;
}
.clm-indicator__title:not(:last-child) {
  margin-bottom: 4px;
}
.clm-indicator__text {
  vertical-align: middle;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: var(--color-less-light-gray);
}
.clm-indicator__line {
  display: flex;
}
.clm-indicator__elem {
  flex: 1 1 auto;
  height: 10px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  background-color: #fff;
  padding: 1px;
  overflow: hidden;
}
.clm-indicator__elem-i {
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.clm-indicator__elem-i.active {
  display: block;
}
.clm-indicator__elem:not(:last-child) {
  margin-right: 5px;
}

.product-card-slick .clm-indicator {
  display: none;
}

.clm-callback {
  font-size: 14px;
  line-height: 120%;
  color: var(--color-gray);
}
.clm-callback .warning {
  color: var(--color-error);
}
.ppl-content .clm-callback {
  margin-top: 20px;
}
.clm-callback__outer-wrap {
  margin-bottom: 25px;
}
.clm-callback__row {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 2px solid var(--color-gray-bg);
}
.clm-callback__row:first-child {
  border-top: 2px solid var(--color-gray-bg);
}
@media (min-width: 1025px) {
  .clm-callback__row:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.clm-callback__text {
  font-size: 15px;
  line-height: 140%;
  color: var(--color-main);
}
.clm-callback__text:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .clm-callback__text {
    font-size: 14px;
  }
}
.clm-callback__main {
  font-size: 19px;
  color: var(--color-main);
}
.clm-callback__main:not(:last-child) {
  margin-bottom: 5px;
}
.clm-callback__link {
  font-size: 19px;
  line-height: normal;
  color: var(--color-link);
}
.clm-callback__link:not(:last-child) {
  margin-bottom: 5px;
}
.clm-callback__wrap-form .ppz-exit__inner {
  display: block;
}
.clm-callback__item:not(:last-child) {
  margin-bottom: 25px;
}
.clm-callback__item.active .clm-callback__icon {
  opacity: 1;
}
.clm-callback__item.error .clm-callback__warning {
  opacity: 1;
}
.clm-callback__label .clm-callback__title {
  cursor: pointer;
}
.clm-callback__title {
  display: block;
  margin-bottom: 6px;
}
.clm-callback__input-block {
  position: relative;
}
.clm-callback__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  transform: translate(0px, -50%);
  opacity: 0;
}
.clm-callback__icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.clm-callback__warning {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  font-size: 12px;
  color: var(--color-error);
  opacity: 0;
}
.clm-callback__checkboxes {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  background-color: var(--color-gray-blue);
  overflow: hidden;
}
.clm-callback__check {
  flex: 1 0 33%;
  margin: -1px;
  cursor: pointer;
}
.clm-callback__check input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.clm-callback__check input:checked + .clm-callback__block {
  background-color: var(--color-primary);
  color: var(--color-white);
}
@media (any-hover: hover) {
  .clm-callback__check:hover .clm-callback__block {
    background-color: var(--color-gray-blue);
  }
}
.clm-callback__block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  border: 1px solid var(--color-gray-blue);
  background-color: var(--color-white);
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
}
.clm-callback__captcha {
  margin-bottom: 25px;
}
.clm-callback__success {
  display: none;
}
.clm-callback__error {
  display: none;
}
.clm-callback__rights {
  margin-top: 15px;
  font-size: 13px;
  line-height: 130%;
}
.clm-callback__color-rights {
  color: var(--color-link);
  transition: border-bottom 0.2s ease 0s;
}
@media (any-hover: hover) {
  .clm-callback__color-rights:hover {
    border-bottom: 1px solid var(--color-link);
  }
}
.clm-callback__social {
  display: flex;
  margin-top: 10px;
}
.clm-callback__social .socil-links__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.clm-callback__social .socil-links__item:not(:last-child) {
  margin-right: 15px;
}
.clm-callback__social .socil-links__item .socil-links__icon {
  transition: opacity 0.2s ease 0s;
}
.clm-callback__social .socil-links__item .socil-links__icon.hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.clm-callback__social .socil-links__item svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  vertical-align: top;
}
@media (any-hover: hover) {
  .clm-callback__social .socil-links__item:hover .socil-links__icon {
    opacity: 0;
  }
  .clm-callback__social .socil-links__item:hover .socil-links__icon.hover {
    opacity: 1;
  }
}
.callback-query-btn {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 5px 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 100px;
  background-color: #F8FAFC;
}
@media (any-hover: hover) {
  .callback-query-btn:hover {
    background-color: var(--color-gray-blue);
  }
}
.callback-query-btn__text {
  font-weight: 500;
  font-size: 15px;
  color: var(--color-main);
  margin-right: 20px;
}
.callback-query-btn__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-left: auto;
  background-color: var(--color-primary-dark);
}
.callback-query-btn__icon img {
  width: 8px;
  height: 12px;
  object-fit: contain;
}

.clm-size {
  font-size: 14px;
  line-height: 120%;
  color: var(--color-gray);
}
.clm-size .warning {
  color: var(--color-error);
}
.ppl-content .clm-size {
  margin-top: 20px;
}
.clm-size__row {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 2px solid var(--color-gray-bg);
}
.clm-size__row:first-child {
  border-top: 2px solid var(--color-gray-bg);
}
@media (min-width: 1025px) {
  .clm-size__row:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.clm-size__text {
  font-size: 15px;
  line-height: 140%;
  color: var(--color-main);
}
.clm-size__text:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .clm-size__text {
    font-size: 14px;
  }
}
.clm-size__link {
  font-size: 19px;
  color: var(--color-link);
}
.clm-size__link:not(:last-child) {
  margin-bottom: 5px;
}
.clm-size__captcha {
  margin-bottom: 25px;
}
.clm-size__rights {
  margin-top: 15px;
  font-size: 13px;
  line-height: 130%;
}
.clm-size__color-rights {
  color: var(--color-link);
  transition: border-bottom 0.2s ease 0s;
}
@media (any-hover: hover) {
  .clm-size__color-rights:hover {
    border-bottom: 1px solid var(--color-link);
  }
}
.clm-size__form {
  padding: 25px 0 0;
}
.clm-size__user {
  padding-bottom: 25px;
  border-bottom: 2px solid var(--color-gray-bg);
}
.clm-size__mattress {
  padding: 25px 0;
}
@media (max-width: 1024px) {
  .clm-size__mattress {
    padding: 20px 0;
  }
}
.clm-size__mattress-top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.clm-size__mattress-img {
  display: block;
  width: 196px;
  height: auto;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 6px;
  padding-top: 4px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .clm-size__mattress-img {
    width: 160px;
  }
}
.clm-size__mattress-title {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;
  color: var(--color-black);
}
.clm-size__mattress-char {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}
.clm-size__mattress-char:last-child {
  margin-bottom: 0;
}
.clm-size__mattress-char-title {
  margin-bottom: 2px;
  color: var(--color-light-gray);
}
.clm-size__wrap-form .ppz-exit__inner {
  display: block;
}
.clm-size__items-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}
@media (max-width: 1024px) {
  .clm-size__items-list {
    row-gap: 10px;
    column-gap: 10px;
  }
  .clm-size__items-list.clm-size__items-list--mob100 {
    display: block;
    row-gap: unset;
    column-gap: unset;
  }
  .clm-size__items-list.clm-size__items-list--mob100 .clm-size__item {
    margin-bottom: 20px;
  }
  .clm-size__items-list.clm-size__items-list--mob100 .clm-size__item:last-child {
    margin-bottom: 0;
  }
}
.clm-size__item.active .clm-size__icon {
  opacity: 1;
}
.clm-size__item.error .clm-size__warning {
  opacity: 1;
}
.clm-size__label .clm-size__title {
  cursor: pointer;
}
.clm-size__title {
  display: block;
  margin-bottom: 6px;
}
.clm-size__input-block {
  position: relative;
}
.clm-size__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  transform: translate(0px, -50%);
  opacity: 0;
}
.clm-size__icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.clm-size__warning {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  font-size: 12px;
  color: var(--color-error);
  opacity: 0;
}
.clm-size__success {
  display: none;
}
.clm-size__success-icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #F8FAFC;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
}
.clm-size__success-title {
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  color: var(--color-black);
  margin-bottom: 10px;
}
.clm-size__success-text {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--color-light-gray);
}
.clm-size__error {
  display: none;
}
.clm-size__error-title {
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  color: var(--color-error);
  margin-bottom: 10px;
}
.clm-size__error-text {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--color-light-gray);
}
.clm-size__exit {
  display: none;
}

.service-window {
  visibility: hidden;
  position: fixed;
  z-index: 1000002;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.service-window * {
  box-sizing: border-box;
}
.service-window.active {
  visibility: visible;
}
.service-window.active .service-window__bg {
  opacity: 1;
}
.service-window.active .service-window__main {
  transform: translate(0px, 0px);
  transition: all 0.5s ease 0s;
}
.service-window__bg {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.2s ease 0s;
}
.service-window__scroll {
  height: 100%;
  overflow-y: auto;
}
.service-window__main {
  position: relative;
  z-index: 20;
  padding: 70px 70px;
  width: 700px;
  border-radius: 6px;
  margin: 50px auto;
  font-family: "Rubik", sans-serif;
  text-align: center;
  background-color: var(--color-white);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  transform: translate(0px, calc(-100% - 50px));
}
@media (max-width: 1024px) {
  .service-window__main {
    width: calc(100% - 30px);
    padding: 50px 50px;
    margin-top: 160px;
  }
}
.service-window__title {
  font-weight: 500;
  font-size: 35px;
  line-height: 120%;
  color: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .service-window__title {
    font-size: 23px;
  }
}
.service-window__body {
  margin-top: 10px;
  font-size: 21px;
  line-height: 120%;
  color: var(--color-primary);
}
@media (max-width: 1024px) {
  .service-window__body {
    font-size: 14px;
  }
}
.service-window__body p:not(:last-child) {
  margin-bottom: 10px;
}
.service-window__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
@media (max-width: 1024px) {
  .service-window__close-btn {
    width: 32px;
    height: 32px;
  }
}

.clm-certificat {
  font-family: "Rubik", sans-serif;
  line-height: 1.2;
  box-sizing: border-box;
  word-break: break-word;
}
.clm-certificat * {
  box-sizing: border-box;
}
.clm-certificat__link {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 20px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 20px;
  background-color: var(--color-white);
  transition: transform 0.2s ease 0s;
}
@media (any-hover: hover) {
  .clm-certificat__link:hover {
    transform: scale(1.045);
  }
  .clm-certificat__link:hover .clm-certificat__ctrl-text {
    color: var(--color-primary);
  }
  .clm-certificat__link:hover .clm-certificat__ctrl-img svg path {
    fill: var(--color-primary);
  }
}
.clm-certificat__title {
  font-weight: 500;
  font-size: 15px;
  color: var(--color-main);
}
.clm-certificat__title:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .clm-certificat__title {
    font-size: 17px;
  }
}
.clm-certificat__text {
  font-size: 14px;
  color: var(--color-gray);
}
.clm-certificat__text:not(:last-child) {
  margin-bottom: 10px;
}
.clm-certificat__ctrl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}
.clm-certificat__ctrl:not(:last-child) {
  margin-bottom: 10px;
}
.clm-certificat__ctrl-text {
  font-size: 14px;
  line-height: 140%;
  color: var(--color-primary-dark);
  transition: color 0.2s ease 0s;
}
.clm-certificat__ctrl-text:not(:last-child) {
  margin-right: 10px;
}
.clm-certificat__ctrl-img {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
}
.clm-certificat__ctrl-img svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.clm-certificat__ctrl-img svg path {
  transition: fill 0.2s ease 0s;
  fill: var(--color-primary-dark);
}
.clm-certificat__count {
  font-size: 14px;
  line-height: 120%;
  color: var(--color-gray);
}
.clm-certificat__images-block {
  display: none;
}

.clm-footer, .clm-footer p {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-less-light-gray);
}
.clm-footer * {
  box-sizing: border-box;
}
.clm-footer img {
  vertical-align: top;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.clm-footer__wrapper {
  border-top: 2px solid var(--color-gray-bg);
  margin-top: 50px;
}
.clm-footer__top {
  padding-top: 20px;
  padding-bottom: 20px;
}
.clm-footer__top-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .clm-footer__top-line {
    margin-bottom: 20px;
  }
}
.clm-footer__logo:not(:last-child) {
  margin-right: 20px;
}
.clm-footer__logo .logo {
  display: block;
  width: 200px;
  height: 55px;
}
@media (max-width: 1024px) {
  .clm-footer__logo .logo {
    width: 150px;
    height: 40px;
  }
}
.clm-footer__logo .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.clm-footer__social {
  display: flex;
}
.clm-footer__social .socil-links__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.clm-footer__social .socil-links__item:not(:last-child) {
  margin-right: 15px;
}
.clm-footer__social .socil-links__item .socil-links__icon {
  transition: opacity 0.2s ease 0s;
}
.clm-footer__social .socil-links__item .socil-links__icon.hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.clm-footer__social .socil-links__item svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  vertical-align: top;
}
@media (any-hover: hover) {
  .clm-footer__social .socil-links__item:hover .socil-links__icon {
    opacity: 0;
  }
  .clm-footer__social .socil-links__item:hover .socil-links__icon.hover {
    opacity: 1;
  }
}
.clm-footer__bottom {
  padding: 40px 0;
  background-color: var(--color-gray-bg);
}
@media (max-width: 1024px) {
  .clm-footer__bottom {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .clm-footer__bottom-container {
    display: flex;
  }
}
@media (min-width: 1025px) {
  .clm-footer__left {
    flex: 0 0 74.6428571429%;
  }
  .clm-footer__left:not(:last-child) {
    margin-right: 20px;
  }
}
@media (max-width: 1430px) {
  .clm-footer__left {
    flex: 0 0 calc(70% - 10px);
  }
}
@media (min-width: 1025px) {
  .clm-footer__rigth {
    display: flex;
    flex-direction: column;
    flex: 0 0 23.9285714286%;
    min-width: 0;
    overflow-wrap: break-word;
  }
}
@media (max-width: 1430px) {
  .clm-footer__rigth {
    flex: 0 0 calc(30% - 10px);
  }
}

.clm-left-footer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  border-radius: 6px;
  background-color: var(--color-white);
}
@media (max-width: 1430px) {
  .clm-left-footer {
    padding: 20px;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer {
    display: block;
    height: auto;
    padding: 20px 0;
    border-radius: 0;
    background-color: transparent;
  }
}
.clm-left-footer__top {
  flex-grow: 1;
}
.clm-left-footer__top:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 2px solid var(--color-gray-bg);
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .clm-left-footer__top:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-blue);
    margin-bottom: 20px;
  }
}
@media (min-width: 1025px) {
  .clm-left-footer__top-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-right: -10px;
    margin-left: -10px;
  }
}
@media (min-width: 1025px) {
  .clm-left-footer__column {
    flex: 0 0 25%;
    padding: 0 10px;
    margin-top: 20px;
  }
}
@media (max-width: 1160px) {
  .clm-left-footer__column {
    flex: 0 0 50%;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer__column:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-blue);
    margin-bottom: 20px;
  }
}
.clm-left-footer__column.active .clm-left-footer__title-btn svg {
  transform: scale(1, -1);
}
.clm-left-footer__column.active .clm-left-footer__title-btn svg path {
  fill: var(--color-primary-dark);
}
.clm-left-footer__title-block:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .clm-left-footer__title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .clm-left-footer__title-block:not(:last-child) {
    margin-bottom: 0;
  }
}
.clm-left-footer__title {
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: var(--color-main);
  transition: color 0.2s ease 0s;
}
@media (any-hover: hover) {
  a.clm-left-footer__title:hover {
    color: var(--color-primary-dark);
  }
}

@media (max-width: 1024px) {
  .clm-left-footer__title:not(:last-child) {
    margin-right: 10px;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer__title {
    font-size: 19px;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer__title-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 24px;
    height: 24px;
  }
  .clm-left-footer__title-btn svg {
    transition: transform 0.2s ease 0s;
  }
  .clm-left-footer__title-btn svg path {
    transition: fill 0.2s ease 0s;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer__title-icon {
    position: relative;
    display: flex;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer__list {
    display: none;
    padding-top: 20px;
  }
}
.clm-left-footer__item {
  display: block;
  color: var(--color-less-light-gray);
  transition: color 0.2s ease 0s;
}
@media (any-hover: hover) {
  .clm-left-footer__item:hover {
    color: var(--color-primary-dark);
  }
}
.clm-left-footer__item:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .clm-left-footer__item {
    font-size: 15px;
  }
  .clm-left-footer__item:not(:last-child) {
    margin-bottom: 15px;
  }
}
.clm-left-footer__middle:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 2px solid var(--color-gray-bg);
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .clm-left-footer__middle:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-blue);
    margin-bottom: 20px;
  }
}
.clm-left-footer__middle p {
  line-height: 140%;
}
.clm-left-footer__middle p:not(:last-child) {
  margin-bottom: 10px;
}
.clm-left-footer__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .clm-left-footer__bottom {
    display: block;
  }
}
@media (min-width: 1025px) {
  .clm-left-footer__rights:not(:last-child) {
    margin-right: 20px;
  }
}
@media (max-width: 1024px) {
  .clm-left-footer__rights:not(:last-child) {
    margin-bottom: 20px;
  }
}
.clm-pay {
  display: flex;
  align-items: center;
}
.clm-pay:not(:last-child) {
  margin-right: 20px;
}
.clm-pay__item:not(:last-child) {
  margin-right: 20px;
}
.clm-pay__item img {
  vertical-align: top;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.clm-right-footer {
  color: var(--color-white);
}
.clm-right-footer__bg {
  flex: 1 1 auto;
  padding: 30px 20px;
  border-radius: 6px;
  background-color: var(--color-primary-dark);
}
@media (min-width: 1025px) {
  .clm-right-footer__bg:not(:last-child) {
    margin-bottom: 25px;
  }
}
@media (max-width: 1024px) {
  .clm-right-footer__bg {
    padding: 20px 15px;
    border-radius: 0;
    margin: 0 -15px;
  }
}
.clm-right-footer__item {
  display: flex;
  flex-direction: column;
}
.clm-right-footer__item:not(:last-child) {
  margin-bottom: 20px;
}
.clm-right-footer__main {
  font-weight: 500;
  line-height: 130%;
  color: var(--color-white);
}
.clm-right-footer__main.big {
  font-size: 23px;
  line-height: 120%;
}
.clm-right-footer__main:not(:last-child) {
  margin-bottom: 5px;
}
.clm-right-footer__submain {
  line-height: 120%;
  color: var(--color-lightest-gray);
}
.popular-categories {
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.2;
  color: var(--color-main);
  margin: 100px 0;
}
@media (max-width: 1024px) {
  .popular-categories {
    margin: 50px 0 20px;
  }
  .popular-categories .container {
    padding: 0;
  }
}
.popular-categories__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .popular-categories__top {
    margin-bottom: 10px;
    display: block;
    padding: 0 15px;
  }
}
.popular-categories__title {
  font-weight: 700;
  font-size: 35px;
  color: var(--color-main);
}
@media (max-width: 1024px) {
  .popular-categories__title {
    font-size: 25px;
  }
}
.popular-categories__link {
  display: inline-flex;
  margin-left: 10px;
  flex-shrink: 0;
  white-space: nowrap;
  text-decoration: none;
  height: 30px;
  align-items: center;
  padding: 0 16px;
  border-radius: 6px;
  color: var(--color-primary);
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  margin-bottom: 2px;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .popular-categories__link {
    display: none;
  }
}
.popular-categories__link:hover, .popular-categories__link:focus {
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-white);
}
@media (max-width: 1024px) {
  .popular-categories__wrap {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 1025px) {
  .popular-categories__pag {
    display: none !important;
  }
}
.popular-categories__pag.swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.popular-categories__pag.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 35px;
  height: 4px;
  margin: 0 7px !important;
  background: var(--color-lightest-gray);
  border-radius: 10px;
  opacity: 1;
}
.popular-categories__pag.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--color-primary-dark);
}
@media (max-width: 1024px) {
  .popular-categories__grid {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
}
@media (min-width: 1025px) {
  .popular-categories__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 190px;
    column-gap: 20px;
    row-gap: 20px;
  }
  .popular-categories__grid > *:nth-child(1) {
    grid-area: 1/1/3/3;
  }
  .popular-categories__grid > *:nth-child(4) {
    grid-area: 2/3/4/5;
  }
  .popular-categories__grid > *:nth-child(7) {
    grid-area: 4/1/6/3;
  }
  .popular-categories__grid > *:nth-child(10) {
    grid-area: 5/3/7/5;
  }
}

.popular-categories-card {
  background: rgb(248, 248, 248);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: var(--color-main);
}
@media (max-width: 1024px) {
  .popular-categories-card {
    width: 335px;
    height: 200px;
    margin-left: 15px;
    flex-shrink: 0;
  }
  .popular-categories-card:last-child {
    margin-right: 15px;
  }
}
.popular-categories-card:hover .popular-categories-card__check, .popular-categories-card:focus .popular-categories-card__check {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.popular-categories-card__img {
  height: 100%;
  width: auto;
  object-fit: contain;
}
.popular-categories-card__inner {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 30px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}
.popular-categories-card__title {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 5px;
}
.popular-categories-card__subtitle {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray);
  margin-bottom: auto;
}
.popular-categories-card__check {
  display: inline-flex;
  background-color: var(--color-white);
  border-radius: 100px 6px 6px 100px;
  padding: 0 15px 0 35px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  align-items: center;
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
}
.popular-categories-card__check::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #F6F6F6;
  border-radius: 50%;
  left: 10px;
  top: 15px;
}

.home-cards {
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-main);
  margin: 50px 0;
}
@media (max-width: 1024px) {
  .home-cards {
    margin: 20px 0;
  }
  .home-cards .container {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .home-cards__wrap {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 1025px) {
  .home-cards__pag {
    display: none !important;
  }
}
.home-cards__pag.swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.home-cards__pag.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 35px;
  height: 4px;
  margin: 0 7px !important;
  background: var(--color-lightest-gray);
  border-radius: 10px;
  opacity: 1;
}
.home-cards__pag.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--color-primary-dark);
}
.home-cards__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  grid-auto-rows: 450px;
}
@media (max-width: 1300px) {
  .home-cards__grid {
    grid-auto-rows: 400px;
  }
}
@media (max-width: 1250px) {
  .home-cards__grid {
    grid-auto-rows: 350px;
  }
}
@media (max-width: 1150px) {
  .home-cards__grid {
    grid-auto-rows: 300px;
  }
}
@media (max-width: 1024px) {
  .home-cards__grid {
    display: flex;
    row-gap: unset;
    column-gap: unset;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition-property: transform;
    box-sizing: content-box;
  }
}
.home-cards__card {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  color: var(--color-white);
}
@media (max-width: 1024px) {
  .home-cards__card {
    width: 200px;
    height: 268px;
    margin-left: 15px;
    flex-shrink: 0;
  }
  .home-cards__card:last-child {
    margin-right: 15px;
  }
}
.home-cards__card:hover .home-cards__card-img, .home-cards__card:focus .home-cards__card-img {
  transform: scale(1.05);
}
.home-cards__card-achivs {
  display: flex;
  flex-wrap: wrap;
}
.home-cards__card-achivs-i {
  font-weight: 500;
  font-size: 14px;
  padding: 8px 16px 7px;
  border-radius: 6px;
  background-color: var(--color-primary);
  white-space: nowrap;
  margin-right: 5px;
  margin-bottom: 5px;
}
@media (max-width: 1024px) {
  .home-cards__card-achivs-i {
    font-size: 10px;
    padding: 5px 10px 4px;
  }
}
.home-cards__card-gb {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(360deg, #333333 0%, rgba(51, 51, 51, 0) 64%);
}
.home-cards__card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: transform 0.3s ease 0s;
}
.home-cards__card-inner {
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .home-cards__card-inner {
    left: 10px;
    top: 10px;
    bottom: 10px;
    right: 10px;
  }
}
.home-cards__card-title {
  font-size: 19px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: 10px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .home-cards__card-title {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.home-cards__card-text {
  font-size: 14px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .home-cards__card-text {
    font-size: 12px;
  }
}

.social-links-next {
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-main);
  margin: 50px 0;
}
.social-links-next__top {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .social-links-next__top {
    margin-bottom: 15px;
  }
}
.social-links-next__title {
  font-weight: 700;
  font-size: 35px;
  color: var(--color-main);
}
@media (max-width: 1024px) {
  .social-links-next__title {
    font-size: 25px;
  }
}
.social-links-next__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 330px;
  column-gap: 20px;
  row-gap: 20px;
}
@media (max-width: 1400px) {
  .social-links-next__grid {
    grid-auto-rows: 300px;
  }
}
@media (max-width: 1300px) {
  .social-links-next__grid {
    grid-auto-rows: 250px;
  }
}
@media (max-width: 1150px) {
  .social-links-next__grid {
    grid-auto-rows: 200px;
  }
}
@media (min-width: 1025px) {
  .social-links-next__grid > *:nth-child(1) {
    grid-area: 1/1/3/3;
  }
  .social-links-next__grid > *:nth-child(4) {
    grid-area: 2/3/3/5;
  }
  .social-links-next__grid > *:nth-child(7) {
    grid-area: 4/1/5/3;
  }
  .social-links-next__grid > *:nth-child(8) {
    grid-area: 4/3/6/5;
  }
}
@media (max-width: 1024px) {
  .social-links-next__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 162px;
    column-gap: 15px;
    row-gap: 15px;
  }
  .social-links-next__grid > *:nth-child(1) {
    grid-area: 1/1/3/3;
  }
  .social-links-next__grid > *:nth-child(4) {
    grid-area: 4/1/5/3;
  }
}
.social-links-next__card {
  text-decoration: none;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.social-links-next__card:hover .social-links-next__card-img, .social-links-next__card:focus .social-links-next__card-img {
  transform: scale(1.05);
}
.social-links-next__card-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center center;
  transition: transform 0.5s ease 0s;
}

.hero-filter-section {
  margin: 20px 0 50px;
}
@media (max-width: 1024px) {
  .hero-filter-section {
    margin: 20px 0;
  }
}
.hero-filter-section__ov {
  position: relative;
}
.hero-filter-section__wrap {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  height: 600px;
}
@media (max-width: 1024px) {
  .hero-filter-section__wrap {
    height: 400px;
  }
}
.hero-filter-section__container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
@media (max-width: 1024px) {
  .hero-filter-section__pag {
    position: absolute;
    bottom: 15px;
    z-index: 3;
  }
}
.hero-filter-section__pag.swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.hero-filter-section__pag.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 35px;
  height: 4px;
  margin: 0 7px !important;
  background: var(--color-lightest-gray);
  border-radius: 10px;
  opacity: 1;
}
.hero-filter-section__pag.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--color-primary-dark);
}
.hero-filter-section__arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid var(--color-gray-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 280px;
  background: #F8FAFC;
  color: var(--color-primary-dark);
  box-sizing: border-box;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
}
@media (max-width: 1024px) {
  .hero-filter-section__arrow {
    display: none;
  }
}
.hero-filter-section__arrow.swiper-button-disabled {
  display: none;
}
.hero-filter-section__arrow--left {
  left: -20px;
  padding-right: 2px;
}
.hero-filter-section__arrow--right {
  padding-left: 2px;
  right: -20px;
}
.hero-filter-section__slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  border-radius: 6px;
  overflow: hidden;
}
.hero-filter-section__slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.hero-filter {
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-main);
  padding: 50px;
  border: 1px solid var(--color-gray-blue);
  border-radius: 6px;
  background: #F8FAFC;
}
@media (max-width: 1024px) {
  .hero-filter {
    display: none;
  }
}
.hero-filter__title {
  font-weight: 600;
  font-size: 39px;
  margin-bottom: 30px;
}
.hero-filter__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  grid-auto-rows: 200px;
}
.hero-filter__card {
  background: var(--color-white);
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transition: background-color 0.2s ease 0s;
  padding: 10px;
}
.hero-filter__card:hover {
  background-color: var(--color-primary);
}
.hero-filter__card:hover .hero-filter__card-title, .hero-filter__card:hover .hero-filter__card-subtitle {
  color: var(--color-white);
}
.hero-filter__card:hover .hero-filter__card-check {
  opacity: 1;
}
.hero-filter__card-img {
  height: 150px;
  width: auto;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
}
.hero-filter__card-inner {
  position: absolute;
  top: 15px;
  right: 20px;
  bottom: 15px;
  left: 20px;
  display: flex;
  flex-direction: column;
}
.hero-filter__card-title {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 5px;
  transition: color 0.2s ease 0s;
}
.hero-filter__card-subtitle {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-gray);
  margin-bottom: auto;
  transition: color 0.2s ease 0s;
}
.hero-filter__card-check {
  display: inline-flex;
  color: var(--color-primary);
  background-color: var(--color-white);
  border-radius: 100px 6px 6px 100px;
  padding: 0 15px 0 35px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  align-items: center;
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}
.hero-filter__card-check::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;
  left: 10px;
  top: 15px;
}