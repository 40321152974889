@import '../modules/_variables'
	
.feedback
	font-family: "Rubik", sans-serif
	color: $color-main
	// .feedback__wrap
	&__wrap

	// .feedback__open-btn
	&__open-btn
		width: auto
		min-width: 170px
		min-height: 40px
		.global-btn__text
			font-weight: 500
			font-size: 14px
			@media (max-width: $media-mobile-max)
				font-size: 15px
		.ppz-exit__inner
			display: block
		&.clm-loader
			min-height: 40px
			cursor: default
			& .global-btn__text
				color: transparent
			&::after
				left: calc(50% - 14px)
				top: calc(50% - 14px)
				width: 28px
				height: 28px
				border-width: 3px
				transition: border-color 0.2s ease 0s
			@media (any-hover: hover)
				&:hover
					border-color: $color-primary
					& .global-btn__text
						color: transparent
			
		
		@media (max-width: $media-mobile-max)
			&.ppz-exit__wrapper.high-content
				min-height: 210px
				height: auto


	// .feedback__main
	&__main
		margin-top: 20px
		font-weight: 500
		font-size: 19px
		@media (max-width: $media-mobile-max)
			font-weight: 500
			font-size: 17px

	// .feedback__list
	&__list

	// .feedback__item
	&__item
		margin-top: 20px
		padding-bottom: 30px
		border-bottom: 1px solid $color-gray-blue
		&:not(:first-child) 
			margin-top: 30px
		@media (min-width: $media-mobile-min)
			&:last-child
				padding-bottom: 0
				border-bottom: none
			

	// .feedback__line
	&__line
		display: flex
		align-items: center
		justify-content: space-between
		@media (max-width: $media-mobile-max)
			flex-wrap: wrap

	// .feedback__name
	&__name
		font-weight: 500
		font-size: 17px
		&:not(:last-child) 
			margin-right: 20px
		@media (max-width: $media-mobile-max)
			flex: 1 1 100%
			width: 100%
			order: 2
			margin-top: 10px
			font-size: 15px
			&:not(:last-child) 
				margin-right: 0px

	// .feedback__date-rating
	&__date-rating
		flex-shrink: 0
		display: flex
		align-items: center
		@media (max-width: $media-mobile-max)
			order: 1

	// .feedback__date
	&__date
		font-size: 13px
		color: $color-gray
		&:not(:last-child) 
			margin-right: 20px
		@media (max-width: $media-mobile-max)
			&:not(:last-child) 
				margin-right: 10px


	// .feedback__rating
	&__rating
		flex-shrink: 0

	// .feedback__sub-list
	&__sub-list

	// .feedback__sub-item
	&__sub-item
		margin-top: 20px
		@media (max-width: $media-mobile-max)
			margin-top: 10px

	// .feedback__title
	&__title
		font-weight: 600
		font-size: 14px

	// .feedback__text
	&__text
		margin-top: 10px
		font-size: 14px
		color: $color-gray
		p
			margin-top: 10px
		@media (max-width: $media-mobile-max)
			margin-top: 7px
			p
				margin-top: 7px

	// .feedback__slider
	&__slider
		margin-top: 10px
		body & .swiper-pagination .swiper-pagination-bullet
			flex: 0 1 30px
			background-color: $color-lightest-gray

	// .feedback__photos
	&__photos
		display: grid
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr))
		gap: 20px
		margin-top: 20px
		@media (max-width: $media-mobile-max)
			margin-top: 10px
	
	// .feedback__photo
	&__photo
		position: relative
		padding-bottom: 56.25%
		cursor: pointer
		img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			object-fit: cover
			
			
		

	// .feedback__empty
	&__empty
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		min-height: 400px
		margin-top: 20px
		font-size: 15px
		color: $color-gray
		@media (max-width: $media-mobile-max)
			display: block
			min-height: 0

		.feedback__open-btn
			min-width: 250px
			margin-top: 30px

	// .feedback__empty-text
	&__empty-text
		font-size: 39px
		color: $color-less-light-gray

	// .feedback__load-more
	&__load-more
		display: flex
		align-items: center
		justify-content: center
		margin-top: 20px
		.feedback__open-btn
			min-width: 250px

.rating-feedback
	position: relative
	margin-top: -2px
	// .rating-feedback__empty
	&__empty
		display: flex
		align-items: center

	// .rating-feedback__fill
	&__fill
		position: absolute
		top: 0
		left: 0
		display: flex
		align-items: center
		width: 100%
		height: 100%
		overflow: hidden

	// .rating-feedback__img
	&__img
		flex-shrink: 0
		width: 20px
		height: 20px
		margin: 0 3px
		svg
			width: 100%
			height: 100%
			object-fit: contain
		

//========================================================================================================================================================
.feedback-form
	color: $color-gray
	// .feedback-form__text-top
	&__text-top
		font-size: 15px
		@media (max-width: $media-mobile-max)
			font-size: 14px
	// .feedback-form__stars
	&__stars
		margin-top: 20px

	// .feedback-form__line
	&__line
		margin-top: 20px

	// .feedback-form__label
	&__label
		display: flex
		flex-direction: column
		
	// .feedback-form__title
	&__title
		cursor: pointer
		font-size: 14px

	// .feedback-form__input
	&__input
		margin-top: 6px

	// .feedback-form__wrap-toggles
	&__wrap-toggles
		margin-top: 20px

	// .feedback-form__toggle
	&__toggle
		padding: 15px 0
		border-top: 1px solid $color-gray-blue
		&:last-child
			border-bottom: 1px solid $color-gray-blue

	// .feedback-form__body-toggle
	&__body-toggle
		display: none

	// .feedback-form__upload-file
	&__upload-file
		margin-top: 20px

	// .feedback-form__captcha
	&__captcha
		margin-top: 20px

	// .feedback-form__submit
	&__submit
		.ppz-exit__inner
			display: block

	// .feedback-form__btn
	&__btn
		&:not(:last-child) 
			margin-bottom: 20px
		& .clm-loader
			position: relative
			min-height: 50px
			cursor: default
			& .global-btn__text
				color: transparent
			&::after
				left: calc(50% - 14px)
				top: calc(50% - 14px)
				width: 28px
				height: 28px
				border-width: 3px
				transition: border-color 0.2s ease 0s
			@media (any-hover: hover)
				&:hover
					border-color: $color-primary
					& .global-btn__text
						color: transparent
			

	// .feedback-form__rights
	&__rights
		margin-top: 15px
		font-size: 13px

	// .feedback-form__color-rights
	&__color-rights
		color: $color-link

	// .feedback-form__success
	&__success
		display: none
		margin-top: 50px

	// .feedback-form__success-icon
	&__success-icon
		width: 200px
		height: 200px
		border-radius: 50%
		background-color: #F8FAFC
		margin: 0 auto 20px
		display: flex
		align-items: center
		justify-content: center
		color: $color-primary

	// .feedback-form__success-title
	&__success-title
		text-align: center
		font-weight: 500
		font-size: 23px
		color: $color-primary
		margin-bottom: 10px
		@media (max-width: $media-mobile-max)
			font-size: 17px

	// .feedback-form__success-text
	&__success-text
		font-size: 15px
		margin-bottom: 20px
		text-align: center
		color: $color-light-gray
		@media (max-width: $media-mobile-max)
			font-size: 14px

	// .feedback-form__error
	&__error
		display: none
		margin-top: 50px

	// .feedback-form__error-title
	&__error-title
		text-align: center
		font-weight: 500
		font-size: 23px
		color: $color-error
		margin-bottom: 10px
		@media (max-width: $media-mobile-max)
			font-size: 17px
			

	// .feedback-form__error-text
	&__error-text
		font-size: 15px
		margin-bottom: 20px
		text-align: center
		color: $color-light-gray
		@media (max-width: $media-mobile-max)
			font-size: 14px

	// .feedback-form__exit
	&__exit
		display: none

.feedback-stars
	&.error
		.feedback-stars__error
			display: block
	
	// .feedback-stars__title
	&__title
		font-size: 17px
		color: $color-main
		sup
			color: $color-error

	// .feedback-stars__list
	&__list
		display: flex
		justify-content: flex-end
		flex-direction: row-reverse
		align-items: center
		margin-top: 10px

	// .feedback-stars__input
	&__input
		position: absolute
		width: 0
		height: 0
		opacity: 0
		&:checked ~.feedback-stars__label .feedback-stars__fill
			opacity: 1

	// .feedback-stars__label
	&__label
		position: relative
		display: flex
		align-items: center
		justify-content: center
		width: 60px
		height: 50px
		cursor: pointer
		&:not(:first-child) 
			padding-right: 10px
		svg
			width: 100%
			height: 100%
			object-fit: cover

		@media (any-hover: hover)
			&:hover
				.feedback-stars__fill
					opacity: 1
				& ~.feedback-stars__label .feedback-stars__fill
					opacity: 1
		
	// .feedback-stars__fill
	&__fill
		position: relative
		z-index: 2
		width: 100%
		height: 100%
		opacity: 0
		color: $color-primary

	// .feedback-stars__empty
	&__empty
		position: absolute
		top: 0
		left: 0
		width: 50px
		height: 50px
		color: $color-lightest-gray
		
	// .feedback-stars__error
	&__error
		display: none
		margin-top: 8px
		font-size: 14px
		color: $color-error


.file-feedback
	position: relative
	&.drag-over
		.file-feedback__label
			display: none

		.file-feedback__drag-and-drop
			display: flex

	&.has-files
		.file-feedback__label
			display: none

		.file-feedback__drag-and-drop
			display: none

	&.error
		.file-feedback__error
			position: relative
			z-index: 2
			display: flex
			cursor: pointer

			
		.image-uploader, .image-uploader.has-files
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			opacity: 0

		.file-feedback__drag-and-drop
			display: none


	// .file-feedback__label
	&__label
		display: flex
		align-items: center
		padding: 20px
		border: 1px solid $color-gray-blue
		border-radius: $border-radius
		cursor: pointer

	// .file-feedback__drag-and-drop
	&__drag-and-drop
		display: none
		flex-direction: column
		align-items: center
		justify-content: center
		min-height: 122px
		padding: 20px
		border: 1px solid $color-gray-blue
		border-radius: $border-radius
		color: $color-primary-dark
		.file-feedback__img
			flex: 0 0 25px
			width: 25px
			height: 25px
			padding: 0
			border: none
			margin-right: 0
			margin-bottom: 10px

	// .file-feedback__error
	&__error
		display: none
		align-items: center
		padding: 20px
		border: 1px solid $color-gray-blue
		border-radius: $border-radius
		cursor: pointer

		.file-feedback__text
			color: $color-error

	// .file-feedback__img
	&__img
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 80px
		width: 80px
		height: 80px
		padding: 15px
		border: 1px solid $color-gray-blue
		border-radius: $border-radius
		margin-right: 10px

	// .file-feedback__text
	&__text
		font-size: 15px
		@media (max-width: $media-mobile-max)
			font-size: 14px


	//========================================================================================================================================================
	// * Кастомизация стилей плагина image-uploader

	.image-uploader
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		cursor: pointer

		&.has-files
			position: static
			width: auto
			height: auto
			cursor: default
			.upload-text
				display: none

		.uploaded
			display: flex
			flex-wrap: wrap
			align-items: center
			margin: -10px -5px 0
		.uploaded-image
			position: relative
			flex: 0 0 80px
			height: 80px
			width: 80px
			border-radius: $border-radius
			margin: 10px 5px 0
			overflow: hidden
			img
				width: 100%
				height: 100%
				object-fit: cover

		.delete-image
			position: absolute
			z-index: 1
			top: 4px
			right: 4px
			width: 18px
			height: 18px
			border-radius: 50%
			border: 1px solid $color-gray-blue
			background-color: $color-gray-blue-light
			background-image: url(/img/base/icons/x.svg)
			background-size: 8px 8px
			background-position: center center
			background-repeat: no-repeat
			overflow: hidden

			
		