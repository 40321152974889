@import './modules/_variables'

.service-window
	visibility: hidden
	position: fixed
	z-index: 1000002
	top: 0
	left: 0
	width: 100%	
	height: 100%
	overflow: hidden
	*
		box-sizing: border-box
	&.active
		visibility: visible
		.service-window__bg
			opacity: 1
		.service-window__main
			transform: translate(0px,0px)
			transition: all 0.5s ease 0s

	// .service-window__bg
	&__bg
		position: absolute
		z-index: 10
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.3)
		opacity: 0
		transition: all 0.2s ease 0s

	// .service-window__scroll
	&__scroll
		height: 100%
		overflow-y: auto


	// .service-window__main
	&__main
		position: relative
		z-index: 20
		padding: 70px 70px
		width: 700px
		border-radius: $border-radius
		margin: 50px auto
		font-family: $font-rubik
		text-align: center
		background-color: $color-white
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2)
		transform: translate(0px,calc(-100% - 50px))
		@media (max-width: $media-mobile-max)
			// width: auto
			width: calc(100% - 30px)
			padding: 50px 50px
			margin-top: 160px
			

	// .service-window__title
	&__title
		font-weight: 500
		font-size: 35px
		line-height: 120%
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			font-size: 23px


	// .service-window__body
	&__body
		margin-top: 10px
		font-size: 21px
		line-height: 120%
		color: $color-primary
		@media (max-width: $media-mobile-max)
			font-size: 14px
		p
			&:not(:last-child)
				margin-bottom: 10px

	// .service-window__close-btn
	&__close-btn
		position: absolute
		top: 20px
		right: 20px
		@media (max-width: $media-mobile-max)
			width: 32px
			height: 32px
