.hm
	height: 60px
	*
		box-sizing: border-box
	@media (min-width: $media-mobile-min)
		display: none
	@media (max-width: $media-mobile-max)
		margin-bottom: 15px

	// .hm__wrapper
	&__wrapper
		position: fixed
		top: 0
		left: 0
		width: 100%
		z-index: 1000001
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2)

	// .hm__body
	&__body
		display: flex
		align-items: center
		justify-content: space-between
		padding: 10px 15px
		background-color: $color-gray-bg

	// .hm__logo
	&__logo
		width: 147px
		height: 40px
		margin-right: 10px
		img
			width: 100%
			height: 100%
			object-fit: contain

	// .hm__icons
	&__icons
		display: flex
		align-items: center

	// .hm__icon
	&__icon
		display: flex
		justify-content: center
		align-items: center
		background-color: $color-white
		width: 34px
		height: 34px
		background: $color-gray-bg
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
		border-radius: 60px
		&:not(:last-child)
			margin-right: 15px
